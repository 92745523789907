/* Form helpers */

%input{
	width: 100%;
	height: 55px;
	padding: 0.2rem 1.5rem;
	font-family: $font-primary;
	font-weight: 400;
	// border: 2px solid #d1d7db;
	border: 2px solid $color-green-normal;
	outline: none!important;
	background-color: #ffffff;
	color: $color-black-normal;

	@include font-size(12px);
	@include border-radius(17px);
	-webkit-appearance: none;
	// text-transform: uppercase;
	letter-spacing: 0.95px;
	@include bp(medium){
		@include font-size(12px);
		letter-spacing: 0.95px;
		// letter-spacing: 2.5px;
	}
	&:placeholder-shown{
		// border-color: darken($color-green-normal, 5%);
	}
	&:focus{
		// border-color: darken($color-green-normal, 5%);
	}
}

.form-input{
	@extend %input;
}

.form-input-small{
	height: 35px;
}

.form-input-center{
	text-align: center;
}

.form-input-uppercase{
	text-transform: uppercase;
}

.form-input-ext{
	border: 0;
	background-color: #6ab14f;
	padding: 0.5rem 0.5rem 0.5rem 3.2rem;
	color: #ffffff;
	letter-spacing: 1.5px;
	border-radius: 0;
	text-transform: uppercase;
	@include bp(medium){
		border-radius: 10px;
		letter-spacing: 2.5px;
	}
	@include bp(rev-medium){
		padding: 0.5rem 0.5rem 0.5rem 3.5rem;
		box-shadow: inset 2px 3px 3px rgba(0, 0, 0, 0.1);
	}
}

.form-icon{

}

.form-line-row{
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	.form-label{
		width: 80px;
		min-width: 80px;
		padding-right: 1rem;
	}
	.form-input{
		width: calc(100% - 80px); 
	}
}

.form-textarea{
	height: 120px;
	padding: 1rem 1.5rem;
	line-height: 1.5rem;
	resize: none;
	// text-transform: uppercase;

	@extend %input;
}

.form-textarea-limit{
	// @include bp(xsmall){
	    -webkit-box-sizing: border-box;
	    box-sizing: border-box;
	    resize: none;
	    min-height: 120px;
	    max-height: 250px;
	// }
	// @include bp(rev-xsmall){
	// 	height: 150px!important;
	// }
}

// .form-select{
// 	width: 100%;
// 	height: 60px;
// 	padding: 0.2rem 1.5rem;
// 	font-family: $font-primary;
// 	font-weight: 700;
// 	line-height: 1.2;
// 	color: $color-black-normal;
// 	border: 2px solid $color-black-normal;
// 	outline: none;
// 	background-color: #ffffff;

// 	@include font-size(16px);
// 	-webkit-appearance: value;
// 	-moz-appearance: value;
// 	-ms-appearance: value;
// 	-webkit-appearance: none;
// 	-moz-appearance: none;
// 	-ms-appearance: none;
// 	appearance: none;
// }

.form-icon{
	position: absolute;
	top: 0;
	right: 0;
	display: flex;
	width: 40px;
	height: 35px;
	@include font-size(12px);
	color: $color-black-normal;
	align-items: center;
	justify-content: center;
	pointer-events: none;
}

.form-icon-search{
	position: absolute;
	top: 0;
	left: 0;
	display: flex;
	width: 52px;
	height: 55px;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	@include bp(medium){
		width: 40px;
	}
	&:hover{
		background-color: $color-green-dark;
	}
	svg{
		fill: #ffffff;
		@include font-size(18px);
		@include bp(medium){
			@include font-size(14px);
		}
	}
}

// .form-icon-block{
// 	.form-input{
// 		padding-right: 60px;
// 	}
// }

.form-label{
	display: block;
	font-family: $font-primary;
	font-weight: 300;
	color: $color-gray-normal;
	@include font-size(15px);
}


.form-line-small{
	position: relative;
	margin-bottom: 0.5rem;
}

.form-line-normal{
	position: relative;
	margin-bottom: 1rem;
}

// .form-line-big{
// 	position: relative;
// 	margin-bottom: 2rem;
// }

// .form-placeholder{
//     position: relative;
//     z-index: 1;
//     &.is-active{
//         .form-label{
//         	// z-index: 9;
//             width: calc(100% - 20px);
//             transform: translateX(1px) translateY(1px);
//             @include font-size(12px);
//             padding: 10px 25px 5px;
//         }
//     }
//     .form-input,
//     .form-textarea{
//         padding: 25px 25px 13px;
//     }
//     .select2-container .select2-selection--single{
//     	padding: 7px 40px 0 25px;
//     }
//     .form-label{
//         position: absolute;
//         z-index: 10;
//         top: 0;
//         left: 0;
//         margin: 0;
//         // padding: 8px 0 2px;
//         cursor: text;
//         transition: all .3s ease;
//         transform: translateX(1px) translateY(8px);
//         text-transform: none;
//         pointer-events: none;
//         padding: 15px 25px 10px;
//     }
// }

// .form-placeholder-textarea{
// 	.form-label{
// 		top: 1px;
// 		left: 2px;
//         background: white;
// 	}
// }

// .is-field-error{
// 	.field-message{
// 		display: block;		
// 	}
// 	.select2-container .select2-selection--single,
// 	.select-classic .select2-container .select2-selection--multiple,
// 	.form-textarea,
// 	.form-input{
// 		border-width: 2px!important;
// 		border-color: #870000!important;
// 	}
// }

// .field-message{
// 	display: none;
// 	margin-top: 5px;
// 	text-align: right;
// 	color: #870000;
// 	text-align: right;
// 	@include font-size(14px);
// }

.parsley-error{
	&.select2-container .select2-selection--single,
	&.select-classic .select2-container .select2-selection--multiple,
	&.form-textarea,
	&.form-input{
		border-color: $color-red-normal!important;
	}
	& + .select2-container .select2-selection--single,
	& + .select-classic .select2-container .select2-selection--multiple{
		border-color: $color-red-normal!important;
	}
}

.parsley-success{
	&.select2-container .select2-selection--single,
	&.select-classic .select2-container .select2-selection--multiple,
	&.form-textarea,
	&.form-input{
		border-color: $color-green-normal!important;
	}
	& + .select2-container .select2-selection--single,
	& + .select-classic .select2-container .select2-selection--multiple{
		border-color: $color-green-normal!important;
	}
}

.parsley-errors-list{
	width: 100%;
	max-width: 100%;
	flex: 0 0 100%;
	color: $color-red-normal;
	text-align: right;
	line-height: 1.4;
	@include font-size(12px);
	li{
		display: block;
		margin-top: 5px;
	}
}