/* Form helpers */

// .quantity-group.js-quantity
//     input(type="number", id="confirm_trip_number_days" class="form-input quantity-input js-quantity-input", min="1", step="1" value="1")
//     .quantity-up.js-quantity-up
//     .quantity-down.js-quantity-down

.quantity-group{
	position: relative;
	display: flex;
	align-items: center;
}

.quantity-input-block{
	position: relative;
	margin: 0 10px;
}

.quantity-input{
	display: inline-block;
	text-align: center;
	outline: none;
	// -webkit-user-select: none;
	// -moz-user-select: none;
	// -ms-user-select: none;
	// -o-user-select: none;
	// user-select: none;
	text-align: center;
	width: 55px;
	height: 35px;
	border-radius: 17px;
	border: 2px solid $color-green-normal;
	@include font-size(12px);
	font-weight: 400;
	letter-spacing: 0.63px;
	text-transform: uppercase;
	padding: 5px 28px 5px 3px;
	text-align: right;
}

.quantity-up,
.quantity-down{
	display: inline-flex;
	align-items: center;
	justify-content: center;
	width: 35px;
	height: 35px;
	background-color: $color-gray-light;
	text-align: center;
	cursor: pointer;
	border-radius: 100%;
	svg{
		fill: $color-green-normal;
		@include font-size(14px);
	}
	&:hover{
		background-color: $color-green-normal;
		svg{
			fill: #ffffff;
		}
	}
}


input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

input[type=number] {
	-moz-appearance: textfield;
}