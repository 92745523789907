.section-login{
	display: flex;
	align-items: center;
	justify-content: center;
	overflow: hidden;
	height: 100%;
	// @include bp(xsmall){
	// }
}

.login-header{
	background-color: $color-green-normal;
	// padding: 0 0 1.2rem 0;
	padding-bottom: 1.5rem;
	@include bp(xsmall){
		padding: 1.7rem 3rem 1.7rem 0;
	}
}

.login-content{
	background-color: #ffffff;
	padding: 3rem 1.2rem 2rem;
	@include bp(xsmall){
		padding: 4rem 2rem 3rem;
	}
}