.box{
	position: relative;
	background-color: #ffffff;
}

.box-mh-85{
	@include bp(medium){
		height: 85px;
		height: 100%;
	}
}

.box-shadow{
	box-shadow: 0 3px 14px rgba(0, 24, 12, 0.07);
}

.box-gray{
	background-color: $color-gray-light;
}

.box-border-green{
	border-left: 6px solid $color-green-normal;
}

.box-radius{
	border-radius: 10px;
}

.box-radius-left{
	border-radius: 10px;
	@include bp(medium){
		border-radius: 10px 0 0 10px;
	}
}

.box-radius-right{
	border-radius: 10px;
	@include bp(medium){
		border-radius: 0 10px 10px 0;
	}
}

.box-xs-radius-right-full{
	// border-radius: 10px;
	@include bp(xsmall){
		border-radius: 0 10px 10px 0;
	}
}

.box-2-3{
	padding: 1.5rem 1.5rem 1.5rem;
	@include bp(medium){
		padding: 2rem 3rem 2rem;
	}
	@include bp(excl-large){
		padding: 2rem 1.5rem 2rem;
	}
}

.box-2-5-3{
	padding: 1.5rem 1.5rem 1.5rem;
	@include bp(medium){
		padding: 2.5rem 3rem 3.2rem;
	}
	@include bp(excl-large){
		padding: 2.5rem 1.5rem 3.2rem;
	}
}

.box-1-1{
	padding: 1rem 1rem;
}

.box-1-2{
	padding: 0.5rem 1.5rem;
}

.box-1-2-ext{
	padding: 1.2rem 1.2rem;
	@include bp(small){
		padding: 1.2rem 1.4rem;
	}
}