/* Header */

$main-bg: #1BBC9B;
$nav-color: #000000;
$nav-bg-active: #ffffff;

.navigation{
	.navigation-trigger{
		display: none;
	}
	@include bp(rev-medium){
		.container-normal, .container-large{
			width: 100%;
		}

		.nav-collapse{
			position: fixed;
			z-index: 98;
			top: 60px;
			left: 0;
			overflow: auto;
			width: 100%;
			height: 100%;
			padding: 0 0 60px;
			transform: translateX(-100%);
			background-color: $nav-bg-active;

			@include transition(none);
		}

		.navigation-trigger{
			position: fixed;
			z-index: 200;
			top: 0;
			right: 0;
			display: block;
			width: 60px;
			height: 60px;
		}

		.navigation-trigger-icon{
			position: absolute;
			top: 50%;
			left: 50%;
			display: inline-block;
			width: 30px;
			height: 3px;
			transition-duration: 250ms;
			transition-property: background-color, transform;
			transform: translate(-50%, -50%);
			background-color: $nav-color;
			font-weight: 300;

			&:before, &:after{
				position: absolute;
				display: block;
				width: 30px;
				height: 3px;
				content: '';
				transition-duration: 250ms;
				transition-property: margin, transform;
				background: $nav-color;
			}

			&:before{
				margin-top: -10px;
			}

			&:after{
				margin-top: 10px;
			}

		}

		&.is-collapse .nav-collapse{
			transform: translateX(0);

			@include transition(transform, 250ms, ease);
		}

		&.is-collapse .navigation-trigger-icon{
			transition: none;
			background: rgba(0, 0, 0, 0.0);

			&:before{
				margin-top: 0;
				transform: rotate(45deg);
			}

			&:after{
				margin-top: 0;
				transform: rotate(-45deg);
			}

		}

	}

}

.header{
}

.navigation{
	position: relative;
	z-index: 999;
	top: 0;
	left: 0;
	width: 100%;
	padding: 25px 0;
	// padding: 0 30px;

	@include transition(box-shadow, 350ms, ease);
	@include bp(medium){
		overflow: hidden;
	}
	@include bp(rev-medium){
		position: fixed;
		background-color: #ffffff;
		box-shadow: 0 5px 10px rgba(1, 1, 1, 0.1);
		padding: 0 10px;
	}

}

.navigation-scroll{
	// box-shadow: 0 5px 10px rgba(1, 1, 1, 0.1);
}

.header-flex{
	@include bp(medium){
		display: flex;
		// align-items: center;
		justify-content: space-between;
	}

}

.header-logo{
	// padding: 10px 0 10px;
	text-align: center;
	height: 100%;
	display: flex;
	align-items: center;
	padding-left: 20px;

	@include bp(rev-medium){
		float: left;
		position: relative;
		padding: 15px 0 16px;
		z-index: 99;
	}

}

.header-logo-link{
	display: inline-block;
	vertical-align: middle;
}

.header-image{
	margin: 0 auto;

	@include bp(rev-medium){
		width: 110px;
		height: auto;
	}

}

.nav-collapse{
	// @include bp(medium){
	// 	display: flex;
	// 	// align-items: center;
	// }

}

.menu-list{
	text-align: center;

	@include bp(rev-medium){
		display: flex;
		flex-wrap: wrap;
		// display: flex;
		// align-items: center;
	}

}

.menu-list-item{
	@include bp(medium){
		// display: flex;
		// align-items: center;
	}
	@include bp(rev-medium){
		display: inline-block;
		text-align: left;
		width: 33.33%;
		padding-right: 1.5rem;
	}
	@include bp(rev-small){
		width: 50%;
	}
	@media (max-width: 460px){
		padding-right: 0;
		width: 100%;
	}

	&:hover, &.is-active{
		.menu-list-link-text{
			color: $color-green-dark;
			@include bp(medium){
				color: $color-gray-light;
			}
		}
		.menu-list-link-icon svg{
			fill: $color-green-dark;
			@include bp(medium){
				fill: $color-gray-light;
			}
		}

	}

}

.menu-list-link{
	position: relative;
	display: flex;
	padding: 0.5rem 0;
	text-decoration: none;
	margin-bottom: 0;
	@include bp(medium){
		margin-bottom: 0.1rem;
	}
}

.menu-list-link-icon{
	display: inline-block;
	vertical-align: middle;
	padding-right: 1rem;
	svg{
		@include font-size(18px);
		fill: $color-green-dark;
		@include bp(medium){
			@include font-size(20px);
		}
	}
}

.menu-list-link-text{
	display: inline-block;
	vertical-align: middle;
	color: $color-green-normal;
	@include font-size(14px);
	font-weight: 500;
	line-height: 1.4;
	@include bp(medium){
		@include font-size(15px);
		color: #ffffff;
	}
}

.menu-title{
	display: block;
	margin-bottom: 0.8rem;
	color: $color-black-normal;
	@include font-size(11px);
	font-weight: 600;
	line-height: 1.2;
	text-transform: uppercase;
	letter-spacing: 1.82px;
	@include bp(medium){
		margin-bottom: 1.2rem;
	}
}

.header-panel-left{
	@include bp(medium){
		width: 320px;
		max-width: 320px;
		flex: 0 0 320px;
	}
	@include bp(rev-medium){
		.box-1-1{
			padding: 0;
		}
	}
}

.header-panel-right{
	@include bp(medium){
		// display: flex;
		width: calc(100% - 320px);
		max-width: calc(100% - 320px);
		flex: 0 0 calc(100% - 320px);
	}
}

.header-contact-group{
	width: 100%;
	max-width: 100%;
	flex: 0 0 100%;
	@include bp(medium){
		margin-bottom: 12px;
	}
	@include bp(xlarge){
		margin-bottom: 0;
		width: 55%;
		max-width: 55%;
		flex: 0 0 55%;
	}
	@media (min-width: 1800px){
		width: 60%;
		max-width: 60%;
		flex: 0 0 60%;
	}
}

.header-search-group{
	@include bp(rev-medium){
		order: -2;
	}
}

.header-promo-group{
	width: 100%;
	max-width: 100%;
	flex: 0 0 100%;
	@include bp(rev-medium){
		order: -1;
	}
	@include bp(medium){
		width: 40%;
		max-width: 40%;
		flex: 0 0 40%;
	}
	@include bp(xlarge){
		width: 20%;
		max-width: 20%;
		flex: 0 0 20%;
	}
	@media (min-width: 1800px){
		width: 16%;
		max-width: 16%;
		flex: 0 0 16%;
	}
}

.header-action-group{
	width: 60%;
	max-width: 60%;
	flex: 0 0 60%;
	@include bp(xlarge){
		width: 25%;
		max-width: 25%;
		flex: 0 0 25%;
	}
	@media (min-width: 1800px){
		width: 24%;
		max-width: 24%;
		flex: 0 0 24%;
	}
}

.header-contact-box{
	@include bp(xsmall){
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		justify-content: space-between;
	}
	@include bp(rev-medium){
		padding: 0.5rem 0.6rem;
		border-radius: 0;
	}
	@include bp(rev-xsmall){
		padding: 1rem 0.6rem;
	}
	@include bp(excl-large){
		border-radius: 10px 0 0 10px;
	}
	@include bp(excl-xlarge){
		border-radius: 10px 0 0 10px;
	}
}

.header-action-box{
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.header-promo-box{
	@include bp(rev-medium){
		border-radius: 0;
	}
}

.box-contact-left-panel{
	@include bp(rev-xsmall){
		max-width: 240px;
		margin: 0 auto;	
	}
	@include bp(xsmall){
		width: 280px;
		max-width: 280px;
		flex: 0 0 280px;
	}
	@include bp(xlarge){
		width: 240px;
		max-width: 240px;
		flex: 0 0 240px;
	}
	@include bp(xxlarge){
		width: 280px;
		max-width: 280px;
		flex: 0 0 280px;
	}
}

.box-contact-right-panel{
	display: flex;
	align-items: center;
	justify-content: flex-end;
	flex-wrap: wrap;
	@include bp(rev-xsmall){
		justify-content: center;
		text-align: center;
		margin-top: 20px;
	}
	@include bp(xsmall){
		padding-left: 0.5rem;
		width: calc(100% - 280px);
		max-width: calc(100% - 280px);
		flex: 0 0 calc(100% - 280px);
	}
	@include bp(xlarge){
		width: calc(100% - 240px);
		max-width: calc(100% - 240px);
		flex: 0 0 calc(100% - 240px);
	}
	@include bp(xxlarge){
		width: calc(100% - 280px);
		max-width: calc(100% - 280px);
		flex: 0 0 calc(100% - 280px);
	}
}

.box-action-left-panel{
	display: flex;
	align-items: center;
	width: calc(100% - 40px);
	max-width: calc(100% - 40px);
	flex: 0 0 calc(100% - 40px);
}

.box-action-right-panel{
	width: 40px;
	max-width: 40px;
	flex: 0 0 40px;
	padding-left: 0.5rem;
}

.header-link{
	display: inline-flex;
	align-items: center;
	justify-content: flex-end;
	color: $color-black-normal;
	padding: 0.5rem 0;
	@include font-size(15px);
	font-weight: 500;
	line-height: 1.2;
	text-transform: uppercase;
	letter-spacing: 1.07px;
	width: 100%;
	@include bp(rev-xsmall){
		justify-content: center;
	}
	@media (min-width: 1800px){
		width: auto;
	}
	&:hover{
		color: $color-green-normal;
		svg{
			fill: $color-green-normal;
		}
	}
	svg{
		position: relative;
		top: 1px;
	}
}

.header-btn{

}

.doctor-block{
	display: flex;
	align-items: center;
	&:hover{
		.p-medium{
			p{
				color: $color-green-normal;
			}
		}
	}
}

.doctor-image-block{
	width: 50px;
	height: 50px;
	max-width: 50px;
	border-radius: 100%;
	overflow: hidden;
	background-color: $color-black-normal;
	@include bp(medium){
		width: 65px;
		height: 65px;
		max-width: 65px;
	}
}

.doctor-text-block{
	padding-left: 1rem;
	width: calc(100% - 50px);
	height: calc(100% - 50px);
	max-width: calc(100% - 50px);
	@include bp(medium){
		width: calc(100% - 65px);
		height: calc(100% - 65px);
		max-width: calc(100% - 65px);
	}
	@include bp(xxlarge){
		padding-left: 2rem;
	}
}

.header-promo-code{
	position: relative;
	height: 100%;
	&:hover{
		.header-promo-icon{
			svg{
				fill: $color-green-dark;
			}
		}
	}
}

.form-input-promo-code{
	height: 100%;
	border: 0;
	text-transform: uppercase;
	background-color: transparent;
	font-weight: 500;
	padding: 2.2rem 3rem 1rem 4.5rem;
	color: $color-black-normal;
	@include font-size(15px);
	letter-spacing: 0;
	@include bp(rev-medium){
		@include font-size(14px);
		height: 60px;
		padding: 1.5rem 3rem 0.5rem 3.5rem;

	}
	// cursor: pointer;
}

.header-promo-icon{
	position: absolute;
	top: 50%;
	left: 10px;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 40px;
	height: 40px;
	transform: translateY(-50%);
	pointer-events: none;
	@include bp(medium){
		left: 20px;
	}
	svg{
		fill: $color-green-normal;
		@include font-size(28px);
		@include bp(medium){
			@include font-size(32px);
		}
	}
}

.header-promo-label{
	position: absolute;
	top: 50%;
	left: 3.5rem;
	font-weight: 300;
	color: $color-black-normal;
	pointer-events: none;
	text-transform: uppercase;
	@include font-size(14px);
	transform: translateY(-18px);
	@include bp(medium){
		@include font-size(15px);
		left: 4.5rem;
	}
}

.header-promo-btn{
	position: absolute;
	right: 10px;
	top: 50%;
	transform: translateY(-50%);
	display: flex;
	align-items: center;
	justify-content: center;
	width: 40px;
	height: 40px;
	background-color: $color-green-normal;
	cursor: pointer;
	z-index: 4;
	padding: 0;
	border-radius: 0;
	@include bp(medium){
		right: 5px;
		border-radius: 10px;
		background-color: transparent;
	}
	&:hover{
		background-color: $color-green-normal;
		svg{
			fill: #ffffff;
		}
	}
	svg{
		fill: #ffffff;
		@include font-size(10px);
		@include bp(medium){
			fill: $color-green-normal;
		}
	}
}

.basket-icon{
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 40px;
	height: 40px;
	cursor: pointer;
	@include bp(rev-medium){
		position: absolute;
		top: 0;
		right: 150px;
		height: 60px;
		width: 45px;
		z-index: 3;
	}
	&:hover{
		svg{
			fill: $color-green-dark;
		}
	}
	svg{
		fill: $color-green-normal;
		@include font-size(26px);
	}
}

.basket-count{
	position: absolute;
	top: -5px;
	right: -5px;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	width: 20px;
	height: 20px;
	color: #ffffff;
	border: 2px solid #ffffff;
	background-color: #c6666f;
	@include font-size(10px);
	font-weight: 500;
	border-radius: 100%;
	@include bp(rev-medium){
		top: 10px;
		right: 0;
	}
}

.user-block{
	display: flex;
	align-items: center;
	@include bp(rev-medium){
		justify-content: center;
		position: absolute;
		top: 0;
		right: 60px;
		height: 60px;
		z-index: 3;
		width: 45px;
	}
	&:hover{
		.p-medium{
			p{
				color: $color-green-normal;
			}
		}
	}
}

.user-image-block{
	border-radius: 100%;
	background-color: $color-black-normal;
	overflow: hidden;
	padding-top: 5px;
	width: 35px;
	height: 35px;
	max-width: 35px;
	@include bp(medium){
		padding-top: 8px;
		width: 45px;
		height: 45px;
		max-width: 45px;
	}
	img{
		position: relative;
		bottom: -2px;
	}
}

.user-text-block{
	padding-left: 1.2rem;
	width: calc(100% - 45px);
	height: calc(100% - 45px);
	max-width: calc(100% - 45px);
}


.logout-icon{
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 40px;
	height: 40px;
	cursor: pointer;
	@include bp(rev-medium){
		position: absolute;
		top: 0;
		right: 105px;
		height: 60px;
		width: 45px;
		z-index: 3;
	}
	&:hover{
		svg{
			fill: $color-green-dark;
		}
	}
	svg{
		fill: $color-green-normal;
		@include font-size(26px);
	}
}