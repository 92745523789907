/* product block */
.product-item{
	margin-bottom: 1.25rem;
	&:last-child{
		margin-bottom: 0;
	}
}

.product-item-row{
	margin-bottom: 0;
	@include bp(rev-xsmall){
		margin: 0 -23px;
	}
	&.product-item-last{
		@include bp(rev-xsmall){
			.product-content{
				border-bottom: 0;
			}
		}
	}
	.product-content{
		display: flex;
		border: 0;
		border-radius: 0;
		padding: 1.2rem 1rem;
		border-bottom: 2px solid #ddebe4;
		@include bp(xsmall){
			padding: 1.2rem 0;
		}
	}
	.product-btn{
		width: 35px;
		height: 35px;
		margin-left: 0.6rem;
		padding: 0;
		flex: 0 0 35px;
		display: flex;
		align-items: center;
		justify-content: center;
		padding-right: 1px;
		.btn-icon{
			margin-right: 0;
		}
	}
	.product-image-block{
		width: 50px;
		max-width: 50px;
		flex: 0 0 50px;
		float: none;
		@include bp(xsmall){
			width: 75px;
			max-width: 75px;
			flex: 0 0 75px;
		}
	}
	.product-text-block{
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		padding-left: 1rem;
		width: calc(100% - 50px);
		max-width: calc(100% - 50px);
		flex: 0 0 calc(100% - 50px);
		@include bp(xsmall){
			width: calc(100% - 75px);
			max-width: calc(100% - 75px);
			flex: 0 0 calc(100% - 75px);
		}
	}
	.product-left-panel{
		min-height: auto;
		margin-bottom: 0;
		@include bp(xsmall){
			width: 40%;
			max-width: 40%;
			flex: 0 0 40%;
			margin-top: 5px;
		}
		@include bp(xlarge){
			width: 40%;
			max-width: 40%;
			flex: 0 0 40%;
			margin-top: 5px;
		}
	}
	.product-right-panel{
		width: 100%;
		max-width: 100%;
		flex: 0 0 100%;
		@include bp(xsmall){
			width: 60%;
			max-width: 60%;
			flex: 0 0 60%;
		}
		@include bp(xlarge){
			width: 60%;
			max-width: 60%;
			flex: 0 0 60%;
		}
		@include bp(excl-xlarge){
			margin-top: 15px;
		}
	}
	.product-right-panel,
	.product-left-panel{
		@include bp(excl-xlarge){
			width: 100%;
			max-width: 100%;
			flex: 0 0 100%;
		}
	}
	.product-btn-block {
		flex-wrap: wrap;
		@include bp(rev-medium){
			width: 100%;
			max-width: 100%;
			flex: 0 0 100%;
			justify-content: flex-end;
		}
		@include bp(rev-xsmall){
			margin-top: 0;
		}
		.quantity-group{
			width: auto;
			margin-bottom: 0;
		}
		.product-btn{
			margin-left: 0.6rem;
		}
	}
}

.product-item-row-ext{
	margin-bottom: 0;
	&:first-child{
		@include bp(rev-medium){
			margin-bottom: 2rem;
		}
		@include bp(medium){
			&:after{
				content: '';
				position: absolute;
				top: 50%;
				right: 0;
				transform: translateY(-50%);
				width: 2px;
				height: 55px;
				display: block;
				background-color: #ddebe4;
			}
		}
	}
	.product-content{
		display: flex;
		border: 0;
		border-radius: 0;
		padding: 0;
		background-color: transparent;
	}
	.product-btn{
		width: 35px;
		height: 35px;
		margin-left: 0.6rem;
		padding: 0;
		flex: 0 0 35px;
		display: flex;
		align-items: center;
		justify-content: center;
		padding-right: 1px;
		.btn-icon{
			margin-right: 0;
		}
	}
	.product-image-block{
		border-radius: 10px;
		background-color: #ffffff;
		float: none;
		padding: 10px 5px;
		width: 60px;
		max-width: 60px;
		flex: 0 0 60px;
		height: 120px;
		@include bp(xsmall){
			padding: 10px;
			width: 120px;
			max-width: 120px;
			flex: 0 0 120px;
			height: 120px;
		}
	}
	.product-text-block{
		display: block;
		padding-left: 0.8rem;
		width: calc(100% - 60px);
		max-width: calc(100% - 60px);
		flex: 0 0 calc(100% - 60px);
		@include bp(xsmall){
			padding-left: 1.2rem;
			width: calc(100% - 120px);
			max-width: calc(100% - 120px);
			flex: 0 0 calc(100% - 120px);
		}
	}
	.product-title-block{
		height: 45px;
		margin-bottom: 0.2rem;
	}
	.product-price-block{
		justify-content: flex-start;
		margin-bottom: 0.8rem;
	}
	.product-price-new{
		@include bp(rev-xsmall){
			@include font-size(18px);
		}
	}
	.product-title{
		@include bp(rev-xsmall){
			@include font-size(14px);
		}
	}
	.product-btn-block {
		flex-wrap: wrap;
		justify-content: flex-start;
		@include bp(rev-medium){
			width: 100%;
			max-width: 100%;
			flex: 0 0 100%;
			justify-content: flex-end;
		}
		@include bp(rev-xsmall){
			margin-top: 0;
		}
		.quantity-group{
			width: auto;
			margin-bottom: 0;
		}
		.product-btn{
			margin-left: 0.6rem;
		}
	}
	.quantity-up,
	.quantity-down{
		background-color: #d8ebe1;
	}
	.quantity-input{
		background-color: transparent;
	}
}

.product-content{
	display: block;
	overflow: hidden;
	border-radius: 10px;
	border: 2px solid #eff6f2;
	background-color: #ffffff;
	padding: 2rem 0.8rem 1.5rem 0.8rem;
	@include bp(small){
		display: flex;
		padding: 1rem 1.5rem 1rem 1rem;
	}
	@include bp(large){
		padding: 1rem 2rem 1rem 1rem;
	}
	&:hover{
		.product-title{
			color: $color-green-normal;
		}

		.product-btn{
			background-color: darken($color-green-normal, 10%);
		}

		img{
			transform: scale(1.05, 1.05);
		}

	}

}

.product-image-block{
	// display: block;
	overflow: hidden;
	position: relative;
	width: 80px;
	max-width: 80px;
	flex: 0 0 80px;
	float: left;
	@include bp(xsmall){
		width: 100px;
		max-width: 100px;
		flex: 0 0 100px;
	}
	@include bp(small){
		float: none;
		width: 100px;
		max-width: 100px;
		flex: 0 0 100px;
	}
	@include bp(large){
		width: 130px;
		max-width: 130px;
		flex: 0 0 130px;
	}
	img{
		transition: transform 400ms ease-out;
	}

}

.product-text-block{
	// width: calc(100% - 80px);
	// max-width: calc(100% - 80px);
	// flex: 0 0 calc(100% - 80px);
	@include bp(small){
		display: flex;
		align-items: center;
		padding-left: 1rem;
		width: calc(100% - 100px);
		max-width: calc(100% - 100px);
		flex: 0 0 calc(100% - 100px);
	}
	@include bp(large){
		width: calc(100% - 130px);
		max-width: calc(100% - 130px);
		flex: 0 0 calc(100% - 130px);
	}
	@include bp(xlarge){
		padding-left: 3rem;
	}
}

.product-left-panel{
	// @include bp(xsmall){
	// 	width: 50%;
	// 	max-width: 50%;
	// 	flex: 0 0 50%;
	// 	padding-right: 1rem;
	// }
	@include bp(rev-small){
		min-height: 130px;
		margin-bottom: 2rem;
	}
	@include bp(small){
		width: 40%;
		max-width: 40%;
		flex: 0 0 40%;
	}
	@include bp(medium){
		width: 50%;
		max-width: 50%;
		flex: 0 0 50%;
	}
	@include bp(large){
		width: 40%;
		max-width: 40%;
		flex: 0 0 40%;
	}
	@include bp(xlarge){
		width: 50%;
		max-width: 50%;
		flex: 0 0 50%;
	}
}

.product-right-panel{
	// @include bp(xsmall){
		
	// 	width: 50%;
	// 	max-width: 50%;
	// 	flex: 0 0 50%;
	// }
	
	display: flex;
	align-items: flex-start;
	flex-wrap: wrap;
	@include bp(small){
		display: block;
		width: 60%;
		max-width: 60%;
		flex: 0 0 60%;
	}
	@include bp(medium){
		width: 50%;
		max-width: 50%;
		flex: 0 0 50%;
	}
	@include bp(large){
		width: 60%;
		max-width: 60%;
		flex: 0 0 60%;
	}
	@include bp(xlarge){
		width: 50%;
		max-width: 50%;
		flex: 0 0 50%;
	}
}

.product-title-block{
	@include bp(rev-xsmall){
		margin-bottom: 1.2rem;
	}
	@include bp(xsmall){
		display: flex;
		align-items: center;
	}
}

.product-title{
	margin-bottom: 0;
	@include bp(xsmall){
		margin-bottom: 0.3rem;
	}
}

.product-category{
	color: $color-gray-normal;
	font-family: Poppins;
	@include font-size(15px);
	font-weight: 300;
	line-height: 1.4;
	text-transform: uppercase;
	letter-spacing: 1.07px;
	@include bp(rev-xsmall){
		@include font-size(14px);
		// position: relative;
		// border-top: 2px solid #eff6f2;
		margin-top: 10px;
		// padding-top: 15px;
		// &:after{
		// 	content: '';
		// 	position: absolute;
		// 	top: 0;
		// 	left: 0;
		// 	display: block;
		// 	width: 40px;
		// 	height: 2px;
		// 	background-color: #eff6f2;
		// }
	}
	@include bp(xsmall){
		border-left: 2px solid #eff6f2;
		margin-left: 20px;
		padding-left: 20px;
	}
}

.product-price-block{
	display: flex;
	align-items: center;
	@include bp(small){
		justify-content: flex-end;
		margin-bottom: 1.5rem;
	}
	@include bp(rev-small){
		width: calc(100% - 180px);
		max-width: calc(100% - 180px);
		flex: 0 0 calc(100% - 180px);
		margin-top: 0.5rem;
	}
	@include bp(rev-xsmall){
		width: 100%;
		max-width: 100%;
		flex: 0 0 100%;
		margin-top: 0.5rem;
		justify-content: center;
	}
}

.product-btn-block{
	display: flex;
	align-items: center;
	justify-content: flex-end;
	@include bp(excl-large){
		flex-wrap: wrap;
		.quantity-group{
			width: 100%;
			justify-content: flex-end;
			margin-bottom: 0.8rem;
		}
		.product-btn{
			margin-left: 0;
		}
	}
	@include bp(rev-small){
		width: 180px;
		max-width: 180px;
		flex: 0 0 180px;
		flex-wrap: wrap;
		.quantity-group{
			width: 100%;
			justify-content: flex-end;
			margin-bottom: 1rem;
		}
		.product-btn{
			margin-left: 0;
		}
	}
	@include bp(rev-xsmall){
		width: 100%;
		max-width: 100%;
		flex: 0 0 100%;
		margin-top: 1.5rem;
		justify-content: center;
		.quantity-group{
			justify-content: center;
		}
	}
}

.product-btn{
	margin-left: 1rem;
	padding: 0.7em 0.8rem;
	@include bp(xsmall){
		margin-left: 1rem;
		padding: 0.7em 0.8rem;
	}
	@include bp(medium){
		margin-left: 1rem;
		padding: 0.7em 1.2rem;
	}
	@include bp(xlarge){
		margin-left: 2rem;
	}
}

.product-price{
	margin-right: 1rem;
	&:last-child{
		margin-right: 0;
	}
	@include bp(small){
		margin-right: 1rem;
	}
	@include bp(medium){
		margin-right: 1rem;
	}
	@include bp(large){
		margin-right: 1.5rem;
	}
}

.product-price-old{
	margin-left: 0;
	color: $color-gray-normal;
	@include font-size(11px);
	font-weight: 600;
	line-height: 1.2;
	text-decoration: line-through;
	letter-spacing: 0.63px;
}

.product-price-new{
	color: $color-green-normal;
	@include font-size(20px);
	font-weight: 500;
	line-height: 1.2;
}

.product-price-discount{
	padding: 0.2rem 0.6rem;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	border-radius: 9px;
	background-color: $color-yellow-normal;
	color: #ffffff;
	@include font-size(11px);
	font-weight: 600;
	line-height: 1.2;
	text-transform: uppercase;
	letter-spacing: 0.63px;
	height: 20px;
}