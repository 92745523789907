.side-panel{
	background-color: $color-green-normal;
	@include bp(medium){
		width: 320px;
		max-width: 320px;
		flex: 0 0 320px;
	}
}

.side-content{
	padding: 2.5rem 1.2rem;
	@include bp(medium){
		padding: 3rem 1.5rem;
	}
}

.main-content{
	@include bp(medium){
		width: calc(100% - 320px);
		max-width: calc(100% - 320px);
		flex: 0 0 calc(100% - 320px);
	}
}

.search-group{
	position: relative;
	// padding: 0;
	@include bp(medium){
		padding: 0 10px 0 25px;
		&:before{
			content: '';
			position: absolute;
			top: 0;
			left: -40px;
			background-color: #6ab14f;
			display: block;
			width: 100%;
			height: 100%;
		}
	}
}