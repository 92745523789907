/* category block */
.category-item{
	margin-bottom: 1.5rem;
}

.category-item-main{
	.category-content{
		padding: 0.8rem 0;
		padding-right: 2.2rem;
		border-left-color: $color-green-normal;
		min-height: 120px;
		@include bp(xsmall){
			padding-right: 0.8rem;
			min-height: 170px;
		}
	}
	.category-icon {
		@include bp(xsmall){
			top: auto;
			bottom: 25px;
			transform: translateY(0);
		}
		svg{
			fill: $color-green-normal;
		}
	}
	.category-image-block{
		width: 90px;
		max-width: 90px;
		flex: 0 0 90px;
		@include bp(xsmall){
			width: 70px;
			max-width: 70px;
			flex: 0 0 70px;
		}
		@include bp(small){
			width: 90px;
			max-width: 90px;
			flex: 0 0 90px;
		}
		@include bp(large){
			width: 60px;
			max-width: 60px;
			flex: 0 0 60px;
		}
		@include bp(xlarge){
			width: 90px;
			max-width: 90px;
			flex: 0 0 90px;
		}
	}

	.category-text-block{
		width: calc(100% - 90px);
		max-width: calc(100% - 90px);
		flex: 0 0 calc(100% - 90px);
		@include bp(xsmall){
			width: calc(100% - 70px);
			max-width: calc(100% - 70px);
			flex: 0 0 calc(100% - 70px);
		}
		@include bp(small){
			width: calc(100% - 90px);
			max-width: calc(100% - 90px);
			flex: 0 0 calc(100% - 90px);
		}
		@include bp(large){
			width: calc(100% - 60px);
			max-width: calc(100% - 60px);
			flex: 0 0 calc(100% - 60px);
		}
		@include bp(xlarge){
			width: calc(100% - 90px);
			max-width: calc(100% - 90px);
			flex: 0 0 calc(100% - 90px);
		}
	}
}

.category-content{
	position: relative;
	display: flex;
	align-items: center;
	overflow: hidden;
	box-shadow: 0 3px 14px rgba(0, 24, 12, 0.07);
	border-radius: 10px;
	background-color: #ffffff;
	border-left: 6px solid #ced4d1;
	padding: 0.5rem 2.2rem 0.5rem 0.8rem;
	max-height: 80px;
	@include bp(xsmall){
		padding: 0.5rem 2.2rem 0.5rem 0.7rem;
	}
	@include bp(small){
		padding: 0.5rem 2.5rem 0.5rem 1.5rem;
	}

	&:hover{
		.category-title{
			color: $color-green-normal;
			// text-decoration: underline;
		}

		.category-icon {
			svg{
				fill: $color-green-normal;
			}
		}

		img{
			transform: scale(1.05, 1.05);
		}

	}

}

.category-image-block{
	display: block;
	overflow: hidden;
	position: relative;
	width: 70px;
	max-width: 70px;
	flex: 0 0 70px;
	@include bp(xsmall){
		width: 50px;
		max-width: 50px;
		flex: 0 0 50px;
	}
	@include bp(small){
		width: 70px;
		max-width: 70px;
		flex: 0 0 70px;
	}

	img{
		transition: transform 400ms ease-out;
	}

}

.category-text-block{
	padding-left: 1rem;
	// padding: 1rem 1rem;
	width: calc(100% - 70px);
	max-width: calc(100% - 70px);
	flex: 0 0 calc(100% - 70px);
	@include bp(xsmall){
		width: calc(100% - 50px);
		max-width: calc(100% - 50px);
		flex: 0 0 calc(100% - 50px);
	}
	@include bp(small){
		width: calc(100% - 70px);
		max-width: calc(100% - 70px);
		flex: 0 0 calc(100% - 70px);
	}
}

.category-icon {
	position: absolute;
	bottom: 20px;
	right: 20px;
	top: 50%;
	transform: translateY(-50%);
	height: 14px;
	svg{
		fill: #ced4d1;
		@include font-size(12px);
	}
}
