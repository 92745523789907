.ico-svg {
  display: inline-block;
  width: 1em;
  height: 1em;
  stroke-width: 0;
  stroke: currentColor;
  fill: currentColor;
}

/* Animate helpers */
@-webkit-keyframes rotate-forever {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes rotate-forever {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loading-spinner {
  display: inline-block;
  width: 50px;
  height: 50px;
  animation-name: rotate-forever;
  animation-duration: 0.75s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  border: 5px solid #81c666;
  border-right-color: transparent;
  border-radius: 50%;
}

/* Form helpers */
.form-input, .form-textarea {
  width: 100%;
  height: 55px;
  padding: 0.2rem 1.5rem;
  font-family: "Poppins", Arial, sans-serif;
  font-weight: 400;
  border: 2px solid #81c666;
  outline: none !important;
  background-color: #ffffff;
  color: #495750;
  font-size: 12px;
  font-size: 0.75rem;
  border-radius: 17px;
  -webkit-appearance: none;
  letter-spacing: 0.95px;
}

@media (min-width: 992px) {
  .form-input, .form-textarea {
    font-size: 12px;
    font-size: 0.75rem;
    letter-spacing: 0.95px;
  }
}

.form-input-small {
  height: 35px;
}

.form-input-center {
  text-align: center;
}

.form-input-uppercase {
  text-transform: uppercase;
}

.form-input-ext {
  border: 0;
  background-color: #6ab14f;
  padding: 0.5rem 0.5rem 0.5rem 3.2rem;
  color: #ffffff;
  letter-spacing: 1.5px;
  border-radius: 0;
  text-transform: uppercase;
}

@media (min-width: 992px) {
  .form-input-ext {
    border-radius: 10px;
    letter-spacing: 2.5px;
  }
}

@media (max-width: 991px) {
  .form-input-ext {
    padding: 0.5rem 0.5rem 0.5rem 3.5rem;
    box-shadow: inset 2px 3px 3px rgba(0, 0, 0, 0.1);
  }
}

.form-line-row {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.form-line-row .form-label {
  width: 80px;
  min-width: 80px;
  padding-right: 1rem;
}

.form-line-row .form-input {
  width: calc(100% - 80px);
}

.form-textarea {
  height: 120px;
  padding: 1rem 1.5rem;
  line-height: 1.5rem;
  resize: none;
}

.form-textarea-limit {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  resize: none;
  min-height: 120px;
  max-height: 250px;
}

.form-icon {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  width: 40px;
  height: 35px;
  font-size: 12px;
  font-size: 0.75rem;
  color: #495750;
  align-items: center;
  justify-content: center;
  pointer-events: none;
}

.form-icon-search {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  width: 52px;
  height: 55px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

@media (min-width: 992px) {
  .form-icon-search {
    width: 40px;
  }
}

.form-icon-search:hover {
  background-color: #519a35;
}

.form-icon-search svg {
  fill: #ffffff;
  font-size: 18px;
  font-size: 1.125rem;
}

@media (min-width: 992px) {
  .form-icon-search svg {
    font-size: 14px;
    font-size: 0.875rem;
  }
}

.form-label {
  display: block;
  font-family: "Poppins", Arial, sans-serif;
  font-weight: 300;
  color: #a1a1a1;
  font-size: 15px;
  font-size: 0.9375rem;
}

.form-line-small {
  position: relative;
  margin-bottom: 0.5rem;
}

.form-line-normal {
  position: relative;
  margin-bottom: 1rem;
}

.parsley-error.select2-container .select2-selection--single,
.parsley-error.select-classic .select2-container .select2-selection--multiple, .parsley-error.form-textarea, .parsley-error.form-input {
  border-color: #ea4335 !important;
}

.parsley-error + .select2-container .select2-selection--single,
.parsley-error + .select-classic .select2-container .select2-selection--multiple {
  border-color: #ea4335 !important;
}

.parsley-success.select2-container .select2-selection--single,
.parsley-success.select-classic .select2-container .select2-selection--multiple, .parsley-success.form-textarea, .parsley-success.form-input {
  border-color: #81c666 !important;
}

.parsley-success + .select2-container .select2-selection--single,
.parsley-success + .select-classic .select2-container .select2-selection--multiple {
  border-color: #81c666 !important;
}

.parsley-errors-list {
  width: 100%;
  max-width: 100%;
  flex: 0 0 100%;
  color: #ea4335;
  text-align: right;
  line-height: 1.4;
  font-size: 12px;
  font-size: 0.75rem;
}

.parsley-errors-list li {
  display: block;
  margin-top: 5px;
}

/* Placeholder helpers */
input::-webkit-input-placeholder,
select::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  color: #99a19d;
}

input::-moz-input-placeholder,
select::-moz-input-placeholder,
textarea::-moz-input-placeholder {
  color: #99a19d;
}

input:-ms-input-placeholder,
select:-ms-input-placeholder,
textarea:-ms-input-placeholder {
  color: #99a19d;
}

.input-uppercase input::-webkit-input-placeholder,
.input-uppercase select::-webkit-input-placeholder,
.input-uppercase textarea::-webkit-input-placeholder {
  text-transform: uppercase;
}

.input-uppercase input::-moz-input-placeholder,
.input-uppercase select::-moz-input-placeholder,
.input-uppercase textarea::-moz-input-placeholder {
  text-transform: uppercase;
}

.input-uppercase input:-ms-input-placeholder,
.input-uppercase select:-ms-input-placeholder,
.input-uppercase textarea:-ms-input-placeholder {
  text-transform: uppercase;
}

.input-white input::-webkit-input-placeholder,
.input-white select::-webkit-input-placeholder,
.input-white textarea::-webkit-input-placeholder {
  color: #ffffff;
}

.input-white input::-moz-input-placeholder,
.input-white select::-moz-input-placeholder,
.input-white textarea::-moz-input-placeholder {
  color: #ffffff;
}

.input-white input:-ms-input-placeholder,
.input-white select:-ms-input-placeholder,
.input-white textarea:-ms-input-placeholder {
  color: #ffffff;
}

.input-black input::-webkit-input-placeholder,
.input-black select::-webkit-input-placeholder,
.input-black textarea::-webkit-input-placeholder {
  color: #495750;
}

.input-black input::-moz-input-placeholder,
.input-black select::-moz-input-placeholder,
.input-black textarea::-moz-input-placeholder {
  color: #495750;
}

.input-black input:-ms-input-placeholder,
.input-black select:-ms-input-placeholder,
.input-black textarea:-ms-input-placeholder {
  color: #495750;
}

input:focus::-webkit-input-placeholder,
textarea:focus::-webkit-input-placeholder {
  color: transparent;
  opacity: 1;
}

input:focus::-moz-input-placeholder,
textarea:focus::-moz-input-placeholder {
  color: transparent;
  opacity: 1;
}

input:focus:-moz-input-placeholder,
textarea:focus:-moz-input-placeholder {
  color: transparent;
  opacity: 1;
}

/* Form helpers */
.quantity-group {
  position: relative;
  display: flex;
  align-items: center;
}

.quantity-input-block {
  position: relative;
  margin: 0 10px;
}

.quantity-input {
  display: inline-block;
  text-align: center;
  outline: none;
  text-align: center;
  width: 55px;
  height: 35px;
  border-radius: 17px;
  border: 2px solid #81c666;
  font-size: 12px;
  font-size: 0.75rem;
  font-weight: 400;
  letter-spacing: 0.63px;
  text-transform: uppercase;
  padding: 5px 28px 5px 3px;
  text-align: right;
}

.quantity-up,
.quantity-down {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 35px;
  height: 35px;
  background-color: #edf5f1;
  text-align: center;
  cursor: pointer;
  border-radius: 100%;
}

.quantity-up svg,
.quantity-down svg {
  fill: #81c666;
  font-size: 14px;
  font-size: 0.875rem;
}

.quantity-up:hover,
.quantity-down:hover {
  background-color: #81c666;
}

.quantity-up:hover svg,
.quantity-down:hover svg {
  fill: #ffffff;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

/* Border helpers */
.border-0 {
  border: 0 !important;
}

.border-0-left {
  border-left: 0 !important;
}

.border-0-right {
  border-right: 0 !important;
}

.border-0-bottom {
  border-bottom: 0 !important;
}

.border-0-top {
  border-top: 0 !important;
}

.border-radius-left {
  border-radius: 10px 0 0 10px;
}

@media (min-width: 992px) {
  .border-radius-md-left {
    border-radius: 10px 0 0 10px;
  }
}

@media (max-width: 991px) {
  .border-md-radius {
    border-radius: 10px;
  }
}

/* Buttons helper */
.btn {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  margin: 0;
  padding: 0.7em 1.2rem;
  font-weight: 600;
  line-height: 1.2;
  cursor: pointer;
  text-decoration: none;
  text-transform: uppercase;
  border: 0;
  outline: none !important;
  background: #495750;
  min-height: 35px;
  text-align: center;
  transition: background-color 250ms ease;
  border-radius: 17px;
  font-size: 12px;
  font-size: 0.75rem;
  -webkit-appearance: none;
}

.btn, .btn:visited, .btn:active, .btn:focus {
  color: white;
}

.btn:hover {
  transition: background-color 250ms ease;
}

@media (min-width: 992px) {
  .btn:hover {
    cursor: pointer;
    text-decoration: none;
    color: white;
  }
}

.btn-responsive {
  width: 100%;
  padding-right: 0;
  padding-left: 0;
  text-align: center;
}

.btn-flex {
  display: inline-flex;
  align-items: center;
}

.btn-icon {
  display: inline-block;
  margin-right: 0.8rem;
  height: 16px;
}

.btn-icon svg {
  fill: #ffffff;
  font-size: 16px;
  font-size: 1rem;
}

.btn-icon-left {
  margin-right: 1.5rem;
}

.btn-icon-left svg {
  font-size: 8px;
  font-size: 0.5rem;
}

.btn-icon-right {
  margin-right: 0;
  margin-left: 1.5rem;
}

.btn-icon-right svg {
  font-size: 8px;
  font-size: 0.5rem;
}

.btn-size-small {
  font-size: 12px;
  font-size: 0.75rem;
}

.btn-size-normal {
  font-size: 14px;
  font-size: 0.875rem;
}

.btn-size-big {
  font-size: 18px;
  font-size: 1.125rem;
}

.btn-size-large {
  font-size: 20px;
  font-size: 1.25rem;
}

.btn-small {
  padding: 0.6em 1rem;
}

.btn-normal {
  padding: 1.5em 1.5rem;
}

.btn-big {
  padding: 2em 2rem;
}

.btn-min-width-small {
  min-width: 120px;
}

.btn-min-width-normal {
  min-width: 250px;
}

.btn-min-width-big {
  min-width: 300px;
}

@media (max-width: 575px) {
  .btn-xs-min-width-normal {
    min-width: 250px;
  }
  .btn-xs-responsive {
    width: 100%;
    padding-right: 0;
    padding-left: 0;
    text-align: center;
    justify-content: center;
  }
}

.btn-black-fill {
  color: #ffffff;
  border: 2px solid transparent;
  background-color: #000000;
}

.btn-black-fill, .btn-black-fill:visited, .btn-black-fill:active, .btn-black-fill:focus {
  color: #ffffff;
}

.btn-black-fill:hover {
  color: #000000;
  background-color: #ffffff;
}

.btn-black-fill:hover .btn-icon {
  color: #000000;
}

.btn-black-fill .btn-icon {
  color: #ffffff;
}

.btn-white-fill {
  color: #81c666;
  background-color: #ffffff;
}

.btn-white-fill, .btn-white-fill:visited, .btn-white-fill:active, .btn-white-fill:focus {
  color: #81c666;
}

.btn-white-fill:hover {
  color: #ffffff;
  background-color: #81c666;
}

.btn-green-fill {
  color: #ffffff;
  background-color: #81c666;
}

.btn-green-fill, .btn-green-fill:visited, .btn-green-fill:active, .btn-green-fill:focus {
  color: #ffffff;
}

.btn-green-fill:hover {
  color: #ffffff;
  background-color: #64b544;
}

.btn-gray-fill {
  color: #81c666;
  background-color: #edf5f1;
}

.btn-gray-fill, .btn-gray-fill:visited, .btn-gray-fill:active, .btn-gray-fill:focus {
  color: #81c666;
}

.btn-gray-fill .btn-icon svg {
  fill: #81c666;
}

.btn-gray-fill:hover {
  color: #81c666;
  background-color: #ddece4;
}

.btn-black-outline {
  color: #000000;
  border: 2px solid #000000;
  background-color: transparent;
}

.btn-black-outline, .btn-black-outline:visited, .btn-black-outline:active, .btn-black-outline:focus {
  color: #000000;
}

.btn-black-outline:hover {
  color: #ffffff;
  border: 2px solid #000000;
  background-color: #000000;
}

.btn-black-outline:hover .btn-icon {
  color: #ffffff;
}

.btn-black-outline .btn-icon {
  color: #000000;
}

/* Color helpers */
.bg-black-normal {
  background-color: #000000;
}

.bg-gray-light {
  background-color: #edf5f1;
}

.txt-color-white {
  color: #ffffff;
}

.txt-color-black {
  color: #000000;
}

@media (min-width: 992px) {
  .bg-gray-md-light {
    background-color: #edf5f1;
  }
}

@media (max-width: 991px) {
  .bg-bp-gray-md-light {
    background-color: #edf5f1;
  }
}

.h-color-white h1, .h-color-white .alfa,
.h-color-white h2, .h-color-white .beta,
.h-color-white h3, .h-color-white .gamma,
.h-color-white h4, .h-color-white .delta,
.h-color-white h5, .h-color-white .epsilon,
.h-color-white h6, .h-color-white .zeta {
  color: #ffffff;
}

.h-color-green h1, .h-color-green .alfa,
.h-color-green h2, .h-color-green .beta,
.h-color-green h3, .h-color-green .gamma,
.h-color-green h4, .h-color-green .delta,
.h-color-green h5, .h-color-green .epsilon,
.h-color-green h6, .h-color-green .zeta {
  color: #81c666;
}

.p-color-white p {
  color: #ffffff;
}

.p-color-gray p {
  color: #a1a1a1;
}

.ul-color-white li {
  color: #ffffff !important;
}

.ul-color-white li:before {
  background-color: #ffffff !important;
}

.ol-color-white li {
  color: #ffffff !important;
}

.ol-color-white li:before {
  color: #ffffff !important;
}

/* Float helpers */
.float-0 {
  float: none;
}

.float-r {
  float: right;
}

.float-l {
  float: left;
}

@media (max-width: 1199px) {
  .float-lg-r {
    float: right;
  }
  .float-lg-l {
    float: left;
  }
}

@media (max-width: 991px) {
  .float-md-l {
    float: left;
  }
  .float-md-r {
    float: right;
  }
}

@media (max-width: 770px) {
  .float-sm-l {
    float: left;
  }
  .float-sm-r {
    float: right;
  }
}

@media (max-width: 575px) {
  .float-xs-l {
    float: left;
  }
  .float-xs-r {
    float: right;
  }
}

/* Gutter helpers */
.no-gutter {
  margin: 0;
}

.no-gutter > [class*="col-"] {
  padding-right: 0;
  padding-left: 0;
}

.gutter-10.row {
  margin-right: -5px;
  margin-left: -5px;
}

.gutter-10 > [class*="col-"],
.gutter-10 > [class*=" col-"] {
  padding-right: 5px;
  padding-left: 5px;
}

.gutter-20.row {
  margin-right: -10px;
  margin-left: -10px;
}

.gutter-20 > [class*="col-"],
.gutter-20 > [class*=" col-"] {
  padding-right: 10px;
  padding-left: 10px;
}

.gutter-24.row {
  margin-right: -12px;
  margin-left: -12px;
}

.gutter-24 > [class*="col-"],
.gutter-24 > [class*=" col-"] {
  padding-right: 12px;
  padding-left: 12px;
}

.gutter-30.row {
  margin-right: -15px;
  margin-left: -15px;
}

.gutter-30 > [class*="col-"],
.gutter-30 > [class*=" col-"] {
  padding-right: 15px;
  padding-left: 15px;
}

.gutter-40.row {
  margin-right: -20px;
  margin-left: -20px;
}

.gutter-40 > [class*="col-"],
.gutter-40 > [class*=" col-"] {
  padding-right: 20px;
  padding-left: 20px;
}

.gutter-50.row {
  margin-right: -25px;
  margin-left: -25px;
}

.gutter-50 > [class*="col-"],
.gutter-50 > [class*=" col-"] {
  padding-right: 25px;
  padding-left: 25px;
}

.gutter-60.row {
  margin-right: -30px;
  margin-left: -30px;
}

.gutter-60 > [class*="col-"],
.gutter-60 > [class*=" col-"] {
  padding-right: 30px;
  padding-left: 30px;
}

@media (min-width: 576px) {
  .gutter-xs-10.row {
    margin-right: -5px;
    margin-left: -5px;
  }
  .gutter-xs-10 > [class*="col-"],
  .gutter-xs-10 > [class*=" col-"] {
    padding-right: 5px;
    padding-left: 5px;
  }
  .gutter-xs-20.row {
    margin-right: -10px;
    margin-left: -10px;
  }
  .gutter-xs-20 > [class*="col-"],
  .gutter-xs-20 > [class*=" col-"] {
    padding-right: 10px;
    padding-left: 10px;
  }
  .gutter-xs-30.row {
    margin-right: -15px;
    margin-left: -15px;
  }
  .gutter-xs-30 > [class*="col-"],
  .gutter-xs-30 > [class*=" col-"] {
    padding-right: 15px;
    padding-left: 15px;
  }
  .gutter-xs-40.row {
    margin-right: -20px;
    margin-left: -20px;
  }
  .gutter-xs-40 > [class*="col-"],
  .gutter-xs-40 > [class*=" col-"] {
    padding-right: 20px;
    padding-left: 20px;
  }
  .gutter-xs-50.row {
    margin-right: -25px;
    margin-left: -25px;
  }
  .gutter-xs-50 > [class*="col-"],
  .gutter-xs-50 > [class*=" col-"] {
    padding-right: 25px;
    padding-left: 25px;
  }
  .gutter-xs-60.row {
    margin-right: -30px;
    margin-left: -30px;
  }
  .gutter-xs-60 > [class*="col-"],
  .gutter-xs-60 > [class*=" col-"] {
    padding-right: 30px;
    padding-left: 30px;
  }
  .gutter-xs-100.row {
    margin-right: -50px;
    margin-left: -50px;
  }
  .gutter-xs-100 > [class*="col-"],
  .gutter-xs-100 > [class*=" col-"] {
    padding-right: 50px;
    padding-left: 50px;
  }
}

@media (min-width: 771px) {
  .gutter-sm-10.row {
    margin-right: -5px;
    margin-left: -5px;
  }
  .gutter-sm-10 > [class*="col-"],
  .gutter-sm-10 > [class*=" col-"] {
    padding-right: 5px;
    padding-left: 5px;
  }
  .gutter-sm-20.row {
    margin-right: -10px;
    margin-left: -10px;
  }
  .gutter-sm-20 > [class*="col-"],
  .gutter-sm-20 > [class*=" col-"] {
    padding-right: 10px;
    padding-left: 10px;
  }
  .gutter-sm-30.row {
    margin-right: -15px;
    margin-left: -15px;
  }
  .gutter-sm-30 > [class*="col-"],
  .gutter-sm-30 > [class*=" col-"] {
    padding-right: 15px;
    padding-left: 15px;
  }
  .gutter-sm-40.row {
    margin-right: -20px;
    margin-left: -20px;
  }
  .gutter-sm-40 > [class*="col-"],
  .gutter-sm-40 > [class*=" col-"] {
    padding-right: 20px;
    padding-left: 20px;
  }
  .gutter-sm-50.row {
    margin-right: -25px;
    margin-left: -25px;
  }
  .gutter-sm-50 > [class*="col-"],
  .gutter-sm-50 > [class*=" col-"] {
    padding-right: 25px;
    padding-left: 25px;
  }
  .gutter-sm-60.row {
    margin-right: -30px;
    margin-left: -30px;
  }
  .gutter-sm-60 > [class*="col-"],
  .gutter-sm-60 > [class*=" col-"] {
    padding-right: 30px;
    padding-left: 30px;
  }
  .gutter-sm-100.row {
    margin-right: -50px;
    margin-left: -50px;
  }
  .gutter-sm-100 > [class*="col-"],
  .gutter-sm-100 > [class*=" col-"] {
    padding-right: 50px;
    padding-left: 50px;
  }
}

@media (min-width: 992px) {
  .gutter-md-10.row {
    margin-right: -5px;
    margin-left: -5px;
  }
  .gutter-md-10 > [class*="col-"],
  .gutter-md-10 > [class*=" col-"] {
    padding-right: 5px;
    padding-left: 5px;
  }
  .gutter-md-20.row {
    margin-right: -10px;
    margin-left: -10px;
  }
  .gutter-md-20 > [class*="col-"],
  .gutter-md-20 > [class*=" col-"] {
    padding-right: 10px;
    padding-left: 10px;
  }
  .gutter-md-24.row {
    margin-right: -12px;
    margin-left: -12px;
  }
  .gutter-md-24 > [class*="col-"],
  .gutter-md-24 > [class*=" col-"] {
    padding-right: 12px;
    padding-left: 12px;
  }
  .gutter-md-30.row {
    margin-right: -15px;
    margin-left: -15px;
  }
  .gutter-md-30 > [class*="col-"],
  .gutter-md-30 > [class*=" col-"] {
    padding-right: 15px;
    padding-left: 15px;
  }
  .gutter-md-40.row {
    margin-right: -20px;
    margin-left: -20px;
  }
  .gutter-md-40 > [class*="col-"],
  .gutter-md-40 > [class*=" col-"] {
    padding-right: 20px;
    padding-left: 20px;
  }
  .gutter-md-50.row {
    margin-right: -25px;
    margin-left: -25px;
  }
  .gutter-md-50 > [class*="col-"],
  .gutter-md-50 > [class*=" col-"] {
    padding-right: 25px;
    padding-left: 25px;
  }
  .gutter-md-60.row {
    margin-right: -30px;
    margin-left: -30px;
  }
  .gutter-md-60 > [class*="col-"],
  .gutter-md-60 > [class*=" col-"] {
    padding-right: 30px;
    padding-left: 30px;
  }
  .gutter-md-100.row {
    margin-right: -50px;
    margin-left: -50px;
  }
  .gutter-md-100 > [class*="col-"],
  .gutter-md-100 > [class*=" col-"] {
    padding-right: 50px;
    padding-left: 50px;
  }
}

@media (min-width: 1200px) {
  .gutter-lg-10.row {
    margin-right: -5px;
    margin-left: -5px;
  }
  .gutter-lg-10 > [class*="col-"],
  .gutter-lg-10 > [class*=" col-"] {
    padding-right: 5px;
    padding-left: 5px;
  }
  .gutter-lg-20.row {
    margin-right: -10px;
    margin-left: -10px;
  }
  .gutter-lg-20 > [class*="col-"],
  .gutter-lg-20 > [class*=" col-"] {
    padding-right: 10px;
    padding-left: 10px;
  }
  .gutter-lg-30.row {
    margin-right: -15px;
    margin-left: -15px;
  }
  .gutter-lg-30 > [class*="col-"],
  .gutter-lg-30 > [class*=" col-"] {
    padding-right: 15px;
    padding-left: 15px;
  }
  .gutter-lg-40.row {
    margin-right: -20px;
    margin-left: -20px;
  }
  .gutter-lg-40 > [class*="col-"],
  .gutter-lg-40 > [class*=" col-"] {
    padding-right: 20px;
    padding-left: 20px;
  }
  .gutter-lg-50.row {
    margin-right: -25px;
    margin-left: -25px;
  }
  .gutter-lg-50 > [class*="col-"],
  .gutter-lg-50 > [class*=" col-"] {
    padding-right: 25px;
    padding-left: 25px;
  }
  .gutter-lg-60.row {
    margin-right: -30px;
    margin-left: -30px;
  }
  .gutter-lg-60 > [class*="col-"],
  .gutter-lg-60 > [class*=" col-"] {
    padding-right: 30px;
    padding-left: 30px;
  }
  .gutter-lg-100.row {
    margin-right: -50px;
    margin-left: -50px;
  }
  .gutter-lg-100 > [class*="col-"],
  .gutter-lg-100 > [class*=" col-"] {
    padding-right: 50px;
    padding-left: 50px;
  }
}

@media (min-width: 1440px) {
  .gutter-xl-60.row {
    margin-right: -30px;
    margin-left: -30px;
  }
  .gutter-xl-60 > [class*="col-"],
  .gutter-xl-60 > [class*=" col-"] {
    padding-right: 30px;
    padding-left: 30px;
  }
  .gutter-xl-140.row {
    margin-right: -70px;
    margin-left: -70px;
  }
  .gutter-xl-140 > [class*="col-"],
  .gutter-xl-140 > [class*=" col-"] {
    padding-right: 70px;
    padding-left: 70px;
  }
}

/* Hidden content */
.hidden-content {
  height: 0;
  opacity: 0;
  visibility: hidden;
  overflow: hidden;
  transition: opacity 250ms ease;
}

.hidden-content.is-active {
  height: auto;
  opacity: 1;
  overflow: visible;
  visibility: visible;
  transition: opacity 250ms ease;
}

@media (max-width: 575px) {
  .hidden-xs-content {
    height: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    transition: opacity 250ms ease;
  }
  .hidden-xs-content.is-active {
    height: auto;
    opacity: 1;
    overflow: visible;
    visibility: visible;
    transition: opacity 250ms ease;
  }
}

@media (max-width: 770px) {
  .hidden-sm-content {
    height: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    transition: opacity 250ms ease;
  }
  .hidden-sm-content.is-active {
    height: auto;
    opacity: 1;
    overflow: visible;
    visibility: visible;
    transition: opacity 250ms ease;
  }
}

@media (max-width: 991px) {
  .hidden-md-content {
    height: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    transition: opacity 250ms ease;
  }
  .hidden-md-content.is-active {
    height: auto;
    opacity: 1;
    overflow: visible;
    visibility: visible;
    transition: opacity 250ms ease;
  }
}

@media (max-width: 1199px) {
  .hidden-lg-content {
    height: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    transition: opacity 250ms ease;
  }
  .hidden-lg-content.is-active {
    height: auto;
    opacity: 1;
    overflow: visible;
    visibility: visible;
    transition: opacity 250ms ease;
  }
}

/* Hidden class */
.invisible {
  visibility: hidden;
}

.visible {
  visibility: visible;
}

.hide {
  display: none;
}

.show {
  display: block;
}

.overflow {
  overflow: auto;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.overflow-visible {
  overflow: visible;
}

/* HR helper */
.hr-line {
  margin: 1rem 0;
  height: 2px;
  background-color: #edf5f1;
}

.hr-line-small {
  margin: 10px 0;
}

.hr-line-normal {
  margin: 20px 0;
}

.hr-line-big {
  margin: 20px 0;
}

.hr-line-width-small {
  width: 100px;
}

.hr-line-width-normal {
  width: 200px;
}

.hr-line-width-big {
  width: 300px;
}

.hr-line-center {
  margin: 0 auto;
}

.hr-line-black {
  background-color: #495750;
}

.hr-line-gray {
  background-color: #ddebe4;
}

/* Icon helpers */
.ico-size-10 svg {
  font-size: 10px;
  font-size: 0.625rem;
}

.ico-size-18 svg {
  font-size: 18px;
  font-size: 1.125rem;
}

.ico-size-20 svg {
  font-size: 20px;
  font-size: 1.25rem;
}

.ico-size-22 svg {
  font-size: 22px;
  font-size: 1.375rem;
}

.ico-size-24 svg {
  font-size: 24px;
  font-size: 1.5rem;
}

.ico-size-26 svg {
  font-size: 26px;
  font-size: 1.625rem;
}

.ico-size-60 svg {
  font-size: 60px;
  font-size: 3.75rem;
}

.ico-color-white svg {
  fill: #ffffff;
}

.ico-color-green svg {
  fill: #81c666;
}

/* Image helpers */
.img {
  display: block;
}

.img-full {
  width: 100%;
}

.img-responsive {
  display: block;
  max-width: 100%;
  height: auto;
}

.img-cover {
  height: 100%;
  object-fit: cover;
}

.img-contain {
  height: 100%;
  object-fit: contain;
}

.img-hover {
  display: block;
  overflow: hidden;
}

.img-hover:hover > img {
  transform: scale(1.05, 1.05);
}

.img-hover img {
  transition: transform 400ms ease-out;
}

.bg-image-hover {
  display: block;
  overflow: hidden;
}

.bg-image-hover:hover > .bg-image {
  transform: scale(1.05, 1.05);
}

.bg-image-hover .bg-image {
  transition: transform 400ms ease-out;
}

.img-flex-cover {
  display: flex;
}

.img-flex-cover img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.img-flex-contain {
  display: flex;
}

.img-flex-contain img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.img-flex-category-small {
  height: 60px;
}

.img-flex-category-big {
  height: 100px;
}

.img-flex-product-small {
  height: 75px;
}

.img-flex-product-normal-ext {
  height: 110px;
}

.img-flex-product-normal {
  height: 130px;
}

.img-flex-detail-gallery-main {
  height: 350px;
}

.img-flex-detail-gallery-thumbnail {
  height: 55px;
}

.bg-image {
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.bg-rectangle {
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.bg-image-logo-symbol {
  top: 1rem;
  left: 50%;
  transform: translateX(-50%);
  width: 311px;
  height: 217px;
}

@media (min-width: 576px) {
  .bg-image-logo-symbol {
    top: 1rem;
    width: 571px;
    height: 397px;
  }
}

@media (min-width: 771px) {
  .bg-image-logo-symbol {
    top: 3rem;
    width: 791px;
    height: 547px;
  }
}

@media (min-width: 992px) {
  .bg-image-logo-symbol {
    width: 981px;
    height: 677px;
  }
}

@media (min-width: 1200px) {
  .bg-image-logo-symbol {
    width: 1321px;
    height: 937px;
  }
}

[class*='aspect-ratio-'] {
  display: block;
  position: relative;
}

[class*='aspect-ratio-'] > img {
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.aspect-ratio-wide {
  padding-top: 56.25%;
}

.aspect-ratio-square {
  padding-top: 100%;
}

/* Margin helpers */
.margin-0 {
  margin: 0 !important;
}

@media (min-width: 576px) {
  .margin-xs-0 {
    margin: 0 !important;
  }
}

@media (min-width: 771px) {
  .margin-sm-0 {
    margin: 0 !important;
  }
}

@media (min-width: 992px) {
  .margin-md-0 {
    margin: 0 !important;
  }
}

@media (min-width: 1200px) {
  .margin-lg-0 {
    margin: 0 !important;
  }
}

@media (min-width: 1440px) {
  .margin-xl-0 {
    margin: 0 !important;
  }
}

.margin-bottom-01 {
  margin-bottom: 0.1rem;
}

.margin-bottom-02 {
  margin-bottom: 0.2rem;
}

.margin-bottom-03 {
  margin-bottom: 0.3rem;
}

.margin-bottom-04 {
  margin-bottom: 0.4rem;
}

.margin-bottom-05 {
  margin-bottom: 0.5rem;
}

.margin-bottom-06 {
  margin-bottom: 0.6rem;
}

.margin-bottom-07 {
  margin-bottom: 0.7rem;
}

.margin-bottom-08 {
  margin-bottom: 0.8rem;
}

.margin-bottom-1 {
  margin-bottom: 1rem;
}

.margin-bottom-1-2 {
  margin-bottom: 1.2rem;
}

.margin-bottom-1-5 {
  margin-bottom: 1.5rem;
}

.margin-bottom-2 {
  margin-bottom: 2rem;
}

.margin-bottom-2-5 {
  margin-bottom: 2.5rem;
}

.margin-bottom-3 {
  margin-bottom: 3rem;
}

.margin-bottom-4 {
  margin-bottom: 4rem;
}

.margin-bottom-5 {
  margin-bottom: 5rem;
}

.margin-bottom-6 {
  margin-bottom: 6rem;
}

.margin-bottom-7 {
  margin-bottom: 7rem;
}

.margin-bottom-8 {
  margin-bottom: 8rem;
}

.margin-bottom-9 {
  margin-bottom: 9rem;
}

.margin-bottom-10 {
  margin-bottom: 10rem;
}

@media (min-width: 576px) {
  .margin-bottom-xs-1 {
    margin-bottom: 1rem;
  }
  .margin-bottom-xs-2 {
    margin-bottom: 2rem;
  }
  .margin-bottom-xs-3 {
    margin-bottom: 3rem;
  }
  .margin-bottom-xs-4 {
    margin-bottom: 4rem;
  }
  .margin-bottom-xs-5 {
    margin-bottom: 5rem;
  }
  .margin-bottom-xs-6 {
    margin-bottom: 6rem;
  }
}

@media (min-width: 771px) {
  .margin-bottom-sm-1 {
    margin-bottom: 1rem;
  }
  .margin-bottom-sm-2 {
    margin-bottom: 2rem;
  }
  .margin-bottom-sm-3 {
    margin-bottom: 3rem;
  }
  .margin-bottom-sm-4 {
    margin-bottom: 4rem;
  }
  .margin-bottom-sm-5 {
    margin-bottom: 5rem;
  }
  .margin-bottom-sm-6 {
    margin-bottom: 6rem;
  }
}

@media (min-width: 992px) {
  .margin-bottom-md-1 {
    margin-bottom: 1rem;
  }
  .margin-bottom-md-2 {
    margin-bottom: 2rem;
  }
  .margin-bottom-md-3 {
    margin-bottom: 3rem;
  }
  .margin-bottom-md-4 {
    margin-bottom: 4rem;
  }
  .margin-bottom-md-5 {
    margin-bottom: 5rem;
  }
  .margin-bottom-md-6 {
    margin-bottom: 6rem;
  }
}

@media (min-width: 1200px) {
  .margin-bottom-lg-1 {
    margin-bottom: 1rem;
  }
  .margin-bottom-lg-2 {
    margin-bottom: 2rem;
  }
  .margin-bottom-lg-3 {
    margin-bottom: 3rem;
  }
  .margin-bottom-lg-4 {
    margin-bottom: 4rem;
  }
  .margin-bottom-lg-5 {
    margin-bottom: 5rem;
  }
  .margin-bottom-lg-6 {
    margin-bottom: 6rem;
  }
}

@media (min-width: 1440px) {
  .margin-bottom-xl-1 {
    margin-bottom: 1rem;
  }
  .margin-bottom-xl-2 {
    margin-bottom: 2rem;
  }
  .margin-bottom-xl-3 {
    margin-bottom: 3rem;
  }
  .margin-bottom-xl-4 {
    margin-bottom: 4rem;
  }
  .margin-bottom-xl-5 {
    margin-bottom: 5rem;
  }
  .margin-bottom-xl-6 {
    margin-bottom: 6rem;
  }
}

.margin-top-01 {
  margin-top: 0.1rem;
}

.margin-top-02 {
  margin-top: 0.2rem;
}

.margin-top-03 {
  margin-top: 0.3rem;
}

.margin-top-04 {
  margin-top: 0.4rem;
}

.margin-top-05 {
  margin-top: 0.5rem;
}

.margin-top-08 {
  margin-top: 0.8rem;
}

.margin-top-1 {
  margin-top: 1rem;
}

.margin-top-1-5 {
  margin-top: 1.5rem;
}

.margin-top-2 {
  margin-top: 2rem;
}

.margin-top-3 {
  margin-top: 3rem;
}

.margin-top-4 {
  margin-top: 4rem;
}

.margin-top-5 {
  margin-top: 5rem;
}

.margin-top-6 {
  margin-top: 6rem;
}

.margin-top-7 {
  margin-top: 7rem;
}

.margin-top-8 {
  margin-top: 8rem;
}

.margin-top-9 {
  margin-top: 9rem;
}

.margin-top-10 {
  margin-top: 10rem;
}

@media (min-width: 576px) {
  .margin-top-xs-1 {
    margin-top: 1rem;
  }
  .margin-top-xs-2 {
    margin-top: 2rem;
  }
  .margin-top-xs-3 {
    margin-top: 3rem;
  }
  .margin-top-xs-4 {
    margin-top: 4rem;
  }
  .margin-top-xs-5 {
    margin-top: 5rem;
  }
  .margin-top-xs-6 {
    margin-top: 6rem;
  }
}

@media (min-width: 771px) {
  .margin-top-sm-1 {
    margin-top: 1rem;
  }
  .margin-top-sm-2 {
    margin-top: 2rem;
  }
  .margin-top-sm-3 {
    margin-top: 3rem;
  }
  .margin-top-sm-4 {
    margin-top: 4rem;
  }
  .margin-top-sm-5 {
    margin-top: 5rem;
  }
  .margin-top-sm-6 {
    margin-top: 6rem;
  }
}

@media (min-width: 992px) {
  .margin-top-md-1 {
    margin-top: 1rem;
  }
  .margin-top-md-2 {
    margin-top: 2rem;
  }
  .margin-top-md-3 {
    margin-top: 3rem;
  }
  .margin-top-md-4 {
    margin-top: 4rem;
  }
  .margin-top-md-5 {
    margin-top: 5rem;
  }
  .margin-top-md-6 {
    margin-top: 6rem;
  }
}

@media (min-width: 1200px) {
  .margin-top-lg-1 {
    margin-top: 1rem;
  }
  .margin-top-lg-2 {
    margin-top: 2rem;
  }
  .margin-top-lg-3 {
    margin-top: 3rem;
  }
  .margin-top-lg-4 {
    margin-top: 4rem;
  }
  .margin-top-lg-5 {
    margin-top: 5rem;
  }
  .margin-top-lg-6 {
    margin-top: 6rem;
  }
}

@media (min-width: 1440px) {
  .margin-top-xl-1 {
    margin-top: 1rem;
  }
  .margin-top-xl-2 {
    margin-top: 2rem;
  }
  .margin-top-xl-3 {
    margin-top: 3rem;
  }
  .margin-top-xl-4 {
    margin-top: 4rem;
  }
  .margin-top-xl-5 {
    margin-top: 5rem;
  }
  .margin-top-xl-6 {
    margin-top: 6rem;
  }
}

.margin-left-01 {
  margin-left: 0.1rem;
}

.margin-left-02 {
  margin-left: 0.2rem;
}

.margin-left-03 {
  margin-left: 0.3rem;
}

.margin-left-04 {
  margin-left: 0.4rem;
}

.margin-left-05 {
  margin-left: 0.5rem;
}

.margin-left-08 {
  margin-left: 0.8rem;
}

.margin-left-1 {
  margin-left: 1rem;
}

.margin-left-1-2 {
  margin-left: 1.2rem;
}

.margin-left-1-5 {
  margin-left: 1.5rem;
}

.margin-left-2 {
  margin-left: 2rem;
}

.margin-left-3 {
  margin-left: 3rem;
}

.margin-left-4 {
  margin-left: 4rem;
}

.margin-left-5 {
  margin-left: 5rem;
}

.margin-left-6 {
  margin-left: 6rem;
}

.margin-left-7 {
  margin-left: 7rem;
}

.margin-left-8 {
  margin-left: 8rem;
}

.margin-left-9 {
  margin-left: 9rem;
}

.margin-left-10 {
  margin-left: 10rem;
}

@media (min-width: 576px) {
  .margin-left-xs-1 {
    margin-left: 1rem;
  }
  .margin-left-xs-2 {
    margin-left: 2rem;
  }
  .margin-left-xs-3 {
    margin-left: 3rem;
  }
  .margin-left-xs-4 {
    margin-left: 4rem;
  }
  .margin-left-xs-5 {
    margin-left: 5rem;
  }
  .margin-left-xs-6 {
    margin-left: 6rem;
  }
}

@media (min-width: 771px) {
  .margin-left-sm-1 {
    margin-left: 1rem;
  }
  .margin-left-sm-1-2 {
    margin-left: 1.2rem;
  }
  .margin-left-sm-2 {
    margin-left: 2rem;
  }
  .margin-left-sm-3 {
    margin-left: 3rem;
  }
  .margin-left-sm-4 {
    margin-left: 4rem;
  }
  .margin-left-sm-5 {
    margin-left: 5rem;
  }
  .margin-left-sm-6 {
    margin-left: 6rem;
  }
}

@media (min-width: 992px) {
  .margin-left-md-1 {
    margin-left: 1rem;
  }
  .margin-left-md-1-2 {
    margin-left: 1.2rem;
  }
  .margin-left-md-2 {
    margin-left: 2rem;
  }
  .margin-left-md-3 {
    margin-left: 3rem;
  }
  .margin-left-md-4 {
    margin-left: 4rem;
  }
  .margin-left-md-5 {
    margin-left: 5rem;
  }
  .margin-left-md-6 {
    margin-left: 6rem;
  }
}

@media (min-width: 1200px) {
  .margin-left-lg-1 {
    margin-left: 1rem;
  }
  .margin-left-lg-2 {
    margin-left: 2rem;
  }
  .margin-left-lg-3 {
    margin-left: 3rem;
  }
  .margin-left-lg-4 {
    margin-left: 4rem;
  }
  .margin-left-lg-5 {
    margin-left: 5rem;
  }
  .margin-left-lg-6 {
    margin-left: 6rem;
  }
}

@media (min-width: 1440px) {
  .margin-left-xl-1 {
    margin-left: 1rem;
  }
  .margin-left-xl-2 {
    margin-left: 2rem;
  }
  .margin-left-xl-3 {
    margin-left: 3rem;
  }
  .margin-left-xl-4 {
    margin-left: 4rem;
  }
  .margin-left-xl-5 {
    margin-left: 5rem;
  }
  .margin-left-xl-6 {
    margin-left: 6rem;
  }
}

.margin-right-01 {
  margin-right: 0.1rem;
}

.margin-right-02 {
  margin-right: 0.2rem;
}

.margin-right-03 {
  margin-right: 0.3rem;
}

.margin-right-04 {
  margin-right: 0.4rem;
}

.margin-right-05 {
  margin-right: 0.5rem;
}

.margin-right-08 {
  margin-right: 0.8rem;
}

.margin-right-1 {
  margin-right: 1rem;
}

.margin-right-1-5 {
  margin-right: 1.5rem;
}

.margin-right-2 {
  margin-right: 2rem;
}

.margin-right-3 {
  margin-right: 3rem;
}

.margin-right-4 {
  margin-right: 4rem;
}

.margin-right-5 {
  margin-right: 5rem;
}

.margin-right-6 {
  margin-right: 6rem;
}

.margin-right-7 {
  margin-right: 7rem;
}

.margin-right-8 {
  margin-right: 8rem;
}

.margin-right-9 {
  margin-right: 9rem;
}

.margin-right-10 {
  margin-right: 10rem;
}

@media (min-width: 576px) {
  .margin-right-xs-1 {
    margin-right: 1rem;
  }
  .margin-right-xs-2 {
    margin-right: 2rem;
  }
  .margin-right-xs-3 {
    margin-right: 3rem;
  }
  .margin-right-xs-4 {
    margin-right: 4rem;
  }
  .margin-right-xs-5 {
    margin-right: 5rem;
  }
  .margin-right-xs-6 {
    margin-right: 6rem;
  }
}

@media (min-width: 771px) {
  .margin-right-sm-1 {
    margin-right: 1rem;
  }
  .margin-right-sm-1-2 {
    margin-right: 1.2rem;
  }
  .margin-right-sm-2 {
    margin-right: 2rem;
  }
  .margin-right-sm-3 {
    margin-right: 3rem;
  }
  .margin-right-sm-4 {
    margin-right: 4rem;
  }
  .margin-right-sm-5 {
    margin-right: 5rem;
  }
  .margin-right-sm-6 {
    margin-right: 6rem;
  }
}

@media (min-width: 992px) {
  .margin-right-md-1 {
    margin-right: 1rem;
  }
  .margin-right-md-2 {
    margin-right: 2rem;
  }
  .margin-right-md-3 {
    margin-right: 3rem;
  }
  .margin-right-md-4 {
    margin-right: 4rem;
  }
  .margin-right-md-5 {
    margin-right: 5rem;
  }
  .margin-right-md-6 {
    margin-right: 6rem;
  }
}

@media (min-width: 1200px) {
  .margin-right-lg-1 {
    margin-right: 1rem;
  }
  .margin-right-lg-2 {
    margin-right: 2rem;
  }
  .margin-right-lg-3 {
    margin-right: 3rem;
  }
  .margin-right-lg-4 {
    margin-right: 4rem;
  }
  .margin-right-lg-5 {
    margin-right: 5rem;
  }
  .margin-right-lg-6 {
    margin-right: 6rem;
  }
}

@media (min-width: 1440px) {
  .margin-right-xl-1 {
    margin-right: 1rem;
  }
  .margin-right-xl-2 {
    margin-right: 2rem;
  }
  .margin-right-xl-3 {
    margin-right: 3rem;
  }
  .margin-right-xl-4 {
    margin-right: 4rem;
  }
  .margin-right-xl-5 {
    margin-right: 5rem;
  }
  .margin-right-xl-6 {
    margin-right: 6rem;
  }
}

.margin-center {
  margin: 0 auto;
}

.margin-left-auto {
  margin-left: auto;
}

.margin-right-auto {
  margin-right: auto;
}

@media (min-width: 576px) {
  .margin-xs-center {
    margin: 0 auto;
  }
  .margin-xs-left-auto {
    margin-left: auto;
  }
  .margin-xs-right-auto {
    margin-right: auto;
  }
}

@media (min-width: 771px) {
  .margin-sm-center {
    margin: 0 auto;
  }
  .margin-sm-left-auto {
    margin-left: auto;
  }
  .margin-sm-right-auto {
    margin-right: auto;
  }
}

@media (min-width: 992px) {
  .margin-md-center {
    margin: 0 auto;
  }
  .margin-md-left-auto {
    margin-left: auto;
  }
  .margin-md-right-auto {
    margin-right: auto;
  }
}

@media (min-width: 1200px) {
  .margin-lg-center {
    margin: 0 auto;
  }
  .margin-lg-left-auto {
    margin-left: auto;
  }
  .margin-lg-right-auto {
    margin-right: auto;
  }
}

.margin-top-0 {
  margin-top: 0 !important;
}

@media (min-width: 576px) {
  .margin-top-xs-0 {
    margin-top: 0 !important;
  }
}

@media (min-width: 771px) {
  .margin-top-sm-0 {
    margin-top: 0 !important;
  }
}

@media (min-width: 992px) {
  .margin-top-md-0 {
    margin-top: 0 !important;
  }
}

@media (min-width: 1200px) {
  .margin-top-lg-0 {
    margin-top: 0 !important;
  }
}

@media (max-width: 1199px) {
  .margin-bp-top-lg-0 {
    margin-top: 0 !important;
  }
}

@media (max-width: 991px) {
  .margin-bp-top-md-0 {
    margin-top: 0 !important;
  }
}

@media (max-width: 770px) {
  .margin-bp-top-sm-0 {
    margin-top: 0 !important;
  }
}

@media (max-width: 575px) {
  .margin-bp-top-xs-0 {
    margin-top: 0 !important;
  }
}

.margin-right-0 {
  margin-right: 0 !important;
}

@media (min-width: 576px) {
  .margin-right-xs-0 {
    margin-right: 0 !important;
  }
}

@media (min-width: 771px) {
  .margin-right-sm-0 {
    margin-right: 0 !important;
  }
}

@media (min-width: 992px) {
  .margin-right-md-0 {
    margin-right: 0 !important;
  }
}

@media (min-width: 1200px) {
  .margin-right-lg-0 {
    margin-right: 0 !important;
  }
}

@media (max-width: 1199px) {
  .margin-bp-right-lg-0 {
    margin-right: 0 !important;
  }
}

@media (max-width: 991px) {
  .margin-bp-right-md-0 {
    margin-right: 0 !important;
  }
}

@media (max-width: 770px) {
  .margin-bp-right-sm-0 {
    margin-right: 0 !important;
  }
}

@media (max-width: 575px) {
  .margin-bp-right-xs-0 {
    margin-right: 0 !important;
  }
}

.margin-bottom-0 {
  margin-bottom: 0 !important;
}

@media (min-width: 576px) {
  .margin-bottom-xs-0 {
    margin-bottom: 0 !important;
  }
}

@media (min-width: 771px) {
  .margin-bottom-sm-0 {
    margin-bottom: 0 !important;
  }
}

@media (min-width: 992px) {
  .margin-bottom-md-0 {
    margin-bottom: 0 !important;
  }
}

@media (min-width: 1200px) {
  .margin-bottom-lg-0 {
    margin-bottom: 0 !important;
  }
}

@media (max-width: 1199px) {
  .margin-bp-bottom-lg-0 {
    margin-bottom: 0 !important;
  }
}

@media (max-width: 991px) {
  .margin-bp-bottom-md-0 {
    margin-bottom: 0 !important;
  }
}

@media (max-width: 770px) {
  .margin-bp-bottom-sm-0 {
    margin-bottom: 0 !important;
  }
}

@media (max-width: 575px) {
  .margin-bp-bottom-xs-0 {
    margin-bottom: 0 !important;
  }
}

.margin-left-0 {
  margin-left: 0 !important;
}

@media (min-width: 576px) {
  .margin-left-xs-0 {
    margin-left: 0 !important;
  }
}

@media (min-width: 771px) {
  .margin-left-sm-0 {
    margin-left: 0 !important;
  }
}

@media (min-width: 992px) {
  .margin-left-md-0 {
    margin-left: 0 !important;
  }
}

@media (min-width: 1200px) {
  .margin-left-lg-0 {
    margin-left: 0 !important;
  }
}

@media (max-width: 1199px) {
  .margin-bp-left-lg-0 {
    margin-left: 0 !important;
  }
}

@media (max-width: 991px) {
  .margin-bp-left-md-0 {
    margin-left: 0 !important;
  }
}

@media (max-width: 770px) {
  .margin-bp-left-sm-0 {
    margin-left: 0 !important;
  }
}

@media (max-width: 575px) {
  .margin-bp-left-xs-0 {
    margin-left: 0 !important;
  }
}

/* Padding block */
.padding-0 {
  padding: 0 !important;
}

@media (min-width: 576px) {
  .padding-xs-0 {
    padding: 0 !important;
  }
}

@media (min-width: 771px) {
  .padding-sm-0 {
    padding: 0 !important;
  }
}

@media (min-width: 992px) {
  .padding-md-0 {
    padding: 0 !important;
  }
}

@media (min-width: 1440px) {
  .padding-xl-0 {
    padding: 0 !important;
  }
}

.padding-top-0 {
  padding-top: 0 !important;
}

.padding-top-xs-0 {
  padding-top: 0 !important;
}

@media (min-width: 771px) {
  .padding-top-sm-0 {
    padding-top: 0 !important;
  }
}

@media (min-width: 992px) {
  .padding-top-md-0 {
    padding-top: 0 !important;
  }
}

@media (min-width: 1200px) {
  .padding-top-lg-0 {
    padding-top: 0 !important;
  }
}

@media (max-width: 1199px) {
  .padding-bp-top-lg-0 {
    padding-top: 0 !important;
  }
}

@media (max-width: 991px) {
  .padding-bp-top-md-0 {
    padding-top: 0 !important;
  }
}

@media (max-width: 770px) {
  .padding-bp-top-sm-0 {
    padding-top: 0 !important;
  }
}

@media (max-width: 575px) {
  .padding-bp-top-xs-0 {
    padding-top: 0 !important;
  }
}

.padding-right-0 {
  padding-right: 0 !important;
}

.padding-right-xs-0 {
  padding-right: 0 !important;
}

@media (min-width: 771px) {
  .padding-right-sm-0 {
    padding-right: 0 !important;
  }
}

@media (min-width: 992px) {
  .padding-right-md-0 {
    padding-right: 0 !important;
  }
}

@media (min-width: 1200px) {
  .padding-right-lg-0 {
    padding-right: 0 !important;
  }
}

@media (max-width: 1199px) {
  .padding-bp-right-lg-0 {
    padding-right: 0 !important;
  }
}

@media (max-width: 991px) {
  .padding-bp-right-md-0 {
    padding-right: 0 !important;
  }
}

@media (max-width: 770px) {
  .padding-bp-right-sm-0 {
    padding-right: 0 !important;
  }
}

@media (max-width: 575px) {
  .padding-bp-right-xs-0 {
    padding-right: 0 !important;
  }
}

.padding-bottom-0 {
  padding-bottom: 0 !important;
}

.padding-bottom-xs-0 {
  padding-bottom: 0 !important;
}

@media (min-width: 771px) {
  .padding-bottom-sm-0 {
    padding-bottom: 0 !important;
  }
}

@media (min-width: 992px) {
  .padding-bottom-md-0 {
    padding-bottom: 0 !important;
  }
}

@media (min-width: 1200px) {
  .padding-bottom-lg-0 {
    padding-bottom: 0 !important;
  }
}

@media (max-width: 1199px) {
  .padding-bp-bottom-lg-0 {
    padding-bottom: 0 !important;
  }
}

@media (max-width: 991px) {
  .padding-bp-bottom-md-0 {
    padding-bottom: 0 !important;
  }
}

@media (max-width: 770px) {
  .padding-bp-bottom-sm-0 {
    padding-bottom: 0 !important;
  }
}

@media (max-width: 575px) {
  .padding-bp-bottom-xs-0 {
    padding-bottom: 0 !important;
  }
}

.padding-left-0 {
  padding-left: 0 !important;
}

.padding-left-xs-0 {
  padding-left: 0 !important;
}

@media (min-width: 771px) {
  .padding-left-sm-0 {
    padding-left: 0 !important;
  }
}

@media (min-width: 992px) {
  .padding-left-md-0 {
    padding-left: 0 !important;
  }
}

@media (min-width: 1200px) {
  .padding-left-lg-0 {
    padding-left: 0 !important;
  }
}

@media (max-width: 1199px) {
  .padding-bp-left-lg-0 {
    padding-left: 0 !important;
  }
}

@media (max-width: 991px) {
  .padding-bp-left-md-0 {
    padding-left: 0 !important;
  }
}

@media (max-width: 770px) {
  .padding-bp-left-sm-0 {
    padding-left: 0 !important;
  }
}

@media (max-width: 575px) {
  .padding-bp-left-xs-0 {
    padding-left: 0 !important;
  }
}

/* Position helpers */
.pos-r {
  position: relative;
}

.pos-f {
  position: fixed;
}

.pos-s {
  position: static;
}

.pos-sticky {
  position: sticky;
}

.pos-a {
  position: absolute;
}

.pos-auto {
  position: inherit;
}

/* Row helpers */
.row-40 {
  margin-right: -40px;
  margin-left: -40px;
}

.row-40 .row-item {
  padding-left: 40px;
  padding-right: 40px;
}

.row-30 {
  margin-right: -30px;
  margin-left: -30px;
}

.row-30 .row-item {
  padding-left: 30px;
  padding-right: 30px;
}

.row-20 {
  margin-right: -20px;
  margin-left: -20px;
}

.row-20 .row-item {
  padding-left: 20px;
  padding-right: 20px;
}

.row-10 {
  margin-right: -10px;
  margin-left: -10px;
}

.row-10 .row-item {
  padding-left: 10px;
  padding-right: 10px;
}

/* Size helpers */
.w-full {
  width: 100%;
}

.w-half {
  width: 50%;
}

.w-quarter {
  width: 25%;
}

.h-full-vh {
  height: 100vh;
}

.h-full {
  height: 100%;
}

.h-half {
  height: 50%;
}

.h-quarter {
  height: 25%;
}

/* Text helpers */
.txt-r {
  text-align: right;
}

.txt-c {
  text-align: center;
}

.txt-l {
  text-align: left;
}

.txt-upper {
  text-transform: uppercase;
}

.txt-lower {
  text-transform: lowercase;
}

.txt-italic {
  font-style: italic;
}

.txt-light {
  font-weight: 300;
}

.txt-regular {
  font-weight: 400;
}

.txt-bold {
  font-weight: 700;
}

.txt-line {
  text-decoration: line-through;
}

.txt-underline {
  text-decoration: underline;
}

.p-size-20 p {
  font-size: 20px;
  font-size: 1.25rem;
}

.p-size-16 p {
  font-size: 16px;
  font-size: 1rem;
}

.p-size-14 p {
  font-size: 14px;
  font-size: 0.875rem;
}

.p-size-12 p {
  font-size: 12px;
  font-size: 0.75rem;
}

.p-line-2 p {
  line-height: 2;
}

.p-line-1-8 p {
  line-height: 1.8;
}

.p-line-1-6 p {
  line-height: 1.6;
}

.p-line-1-4 p {
  line-height: 1.4;
}

.p-line-1-2 p {
  line-height: 1.2;
}

.p-line-1 p {
  line-height: 1;
}

.p-spacing-08 p {
  letter-spacing: 0.79px;
}

.h-light h1, .h-light .alfa,
.h-light h2, .h-light .beta,
.h-light h3, .h-light .gamma,
.h-light h4, .h-light .delta,
.h-light h5, .h-light .epsilon,
.h-light h6, .h-light .zeta {
  font-family: "Poppins", Arial, sans-serif;
  font-weight: 300;
}

.h-medium h1, .h-medium .alfa,
.h-medium h2, .h-medium .beta,
.h-medium h3, .h-medium .gamma,
.h-medium h4, .h-medium .delta,
.h-medium h5, .h-medium .epsilon,
.h-medium h6, .h-medium .zeta {
  font-family: "Poppins", Arial, sans-serif;
  font-weight: 500;
}

.h-semibold h1, .h-semibold .alfa,
.h-semibold h2, .h-semibold .beta,
.h-semibold h3, .h-semibold .gamma,
.h-semibold h4, .h-semibold .delta,
.h-semibold h5, .h-semibold .epsilon,
.h-semibold h6, .h-semibold .zeta {
  font-family: "Poppins", Arial, sans-serif;
  font-weight: 600;
}

.h-bold h1, .h-bold .alfa,
.h-bold h2, .h-bold .beta,
.h-bold h3, .h-bold .gamma,
.h-bold h4, .h-bold .delta,
.h-bold h5, .h-bold .epsilon,
.h-bold h6, .h-bold .zeta {
  font-family: "Poppins", Arial, sans-serif;
  font-weight: 700;
}

.h-black h1, .h-black .alfa,
.h-black h2, .h-black .beta,
.h-black h3, .h-black .gamma,
.h-black h4, .h-black .delta,
.h-black h5, .h-black .epsilon,
.h-black h6, .h-black .zeta {
  font-family: "Poppins", Arial, sans-serif;
  font-weight: 900;
}

.h-upper h1, .h-upper .alfa,
.h-upper h2, .h-upper .beta,
.h-upper h3, .h-upper .gamma,
.h-upper h4, .h-upper .delta,
.h-upper h5, .h-upper .epsilon,
.h-upper h6, .h-upper .zeta {
  text-transform: uppercase;
}

.h-line-1-2 h1, .h-line-1-2 .alfa,
.h-line-1-2 h2, .h-line-1-2 .beta,
.h-line-1-2 h3, .h-line-1-2 .gamma,
.h-line-1-2 h4, .h-line-1-2 .delta,
.h-line-1-2 h5, .h-line-1-2 .epsilon,
.h-line-1-2 h6, .h-line-1-2 .zeta {
  line-height: 1.2;
}

.h-line-1-4 h1, .h-line-1-4 .alfa,
.h-line-1-4 h2, .h-line-1-4 .beta,
.h-line-1-4 h3, .h-line-1-4 .gamma,
.h-line-1-4 h4, .h-line-1-4 .delta,
.h-line-1-4 h5, .h-line-1-4 .epsilon,
.h-line-1-4 h6, .h-line-1-4 .zeta {
  line-height: 1.4;
}

.h-line-1-6 h1, .h-line-1-6 .alfa,
.h-line-1-6 h2, .h-line-1-6 .beta,
.h-line-1-6 h3, .h-line-1-6 .gamma,
.h-line-1-6 h4, .h-line-1-6 .delta,
.h-line-1-6 h5, .h-line-1-6 .epsilon,
.h-line-1-6 h6, .h-line-1-6 .zeta {
  line-height: 1.6;
}

.h-line-1-8 h1, .h-line-1-8 .alfa,
.h-line-1-8 h2, .h-line-1-8 .beta,
.h-line-1-8 h3, .h-line-1-8 .gamma,
.h-line-1-8 h4, .h-line-1-8 .delta,
.h-line-1-8 h5, .h-line-1-8 .epsilon,
.h-line-1-8 h6, .h-line-1-8 .zeta {
  line-height: 1.8;
}

.h-line-2 h1, .h-line-2 .alfa,
.h-line-2 h2, .h-line-2 .beta,
.h-line-2 h3, .h-line-2 .gamma,
.h-line-2 h4, .h-line-2 .delta,
.h-line-2 h5, .h-line-2 .epsilon,
.h-line-2 h6, .h-line-2 .zeta {
  line-height: 2;
}

@media (min-width: 576px) {
  .h-line-xs-1-2 h1, .h-line-xs-1-2 .alfa,
  .h-line-xs-1-2 h2, .h-line-xs-1-2 .beta,
  .h-line-xs-1-2 h3, .h-line-xs-1-2 .gamma,
  .h-line-xs-1-2 h4, .h-line-xs-1-2 .delta,
  .h-line-xs-1-2 h5, .h-line-xs-1-2 .epsilon,
  .h-line-xs-1-2 h6, .h-line-xs-1-2 .zeta {
    line-height: 1.2;
  }
}

@media (min-width: 771px) {
  .h-line-sm-1-2 h1, .h-line-sm-1-2 .alfa,
  .h-line-sm-1-2 h2, .h-line-sm-1-2 .beta,
  .h-line-sm-1-2 h3, .h-line-sm-1-2 .gamma,
  .h-line-sm-1-2 h4, .h-line-sm-1-2 .delta,
  .h-line-sm-1-2 h5, .h-line-sm-1-2 .epsilon,
  .h-line-sm-1-2 h6, .h-line-sm-1-2 .zeta {
    line-height: 1.2;
  }
}

@media (min-width: 370px) {
  .txt-xss-c {
    text-align: center;
  }
  .txt-xss-l {
    text-align: left;
  }
  .txt-xss-r {
    text-align: right;
  }
}

@media (min-width: 576px) {
  .txt-xs-c {
    text-align: center;
  }
  .txt-xs-l {
    text-align: left;
  }
  .txt-xs-r {
    text-align: right;
  }
}

@media (min-width: 771px) {
  .txt-sm-l {
    text-align: left;
  }
  .txt-sm-r {
    text-align: right;
  }
  .txt-sm-c {
    text-align: center;
  }
}

@media (min-width: 992px) {
  .txt-md-l {
    text-align: left;
  }
  .txt-md-r {
    text-align: right;
  }
  .txt-md-c {
    text-align: center;
  }
}

@media (min-width: 1200px) {
  .txt-lg-r {
    text-align: right;
  }
  .txt-lg-l {
    text-align: left;
  }
  .txt-lg-c {
    text-align: center;
  }
}

.h-margin-0 .alfa, .h-margin-0 .beta, .h-margin-0 .gamma, .h-margin-0 .delta, .h-margin-0 .epsilon, .h-margin-0 .zeta,
.h-margin-0 h1, .h-margin-0 h2, .h-margin-0 h3, .h-margin-0 h4, .h-margin-0 h5, .h-margin-0 h6 {
  margin-bottom: 0;
}

.h-margin-01 .alfa, .h-margin-01 .beta, .h-margin-01 .gamma, .h-margin-01 .delta, .h-margin-01 .epsilon, .h-margin-01 .zeta,
.h-margin-01 h1, .h-margin-01 h2, .h-margin-01 h3, .h-margin-01 h4, .h-margin-01 h5, .h-margin-01 h6 {
  margin-bottom: 0.1rem;
}

.h-margin-02 .alfa, .h-margin-02 .beta, .h-margin-02 .gamma, .h-margin-02 .delta, .h-margin-02 .epsilon, .h-margin-02 .zeta,
.h-margin-02 h1, .h-margin-02 h2, .h-margin-02 h3, .h-margin-02 h4, .h-margin-02 h5, .h-margin-02 h6 {
  margin-bottom: 0.2rem;
}

.h-margin-03 .alfa, .h-margin-03 .beta, .h-margin-03 .gamma, .h-margin-03 .delta, .h-margin-03 .epsilon, .h-margin-03 .zeta,
.h-margin-03 h1, .h-margin-03 h2, .h-margin-03 h3, .h-margin-03 h4, .h-margin-03 h5, .h-margin-03 h6 {
  margin-bottom: 0.3rem;
}

.h-margin-04 .alfa, .h-margin-04 .beta, .h-margin-04 .gamma, .h-margin-04 .delta, .h-margin-04 .epsilon, .h-margin-04 .zeta,
.h-margin-04 h1, .h-margin-04 h2, .h-margin-04 h3, .h-margin-04 h4, .h-margin-04 h5, .h-margin-04 h6 {
  margin-bottom: 0.4rem;
}

.h-margin-05 .alfa, .h-margin-05 .beta, .h-margin-05 .gamma, .h-margin-05 .delta, .h-margin-05 .epsilon, .h-margin-05 .zeta,
.h-margin-05 h1, .h-margin-05 h2, .h-margin-05 h3, .h-margin-05 h4, .h-margin-05 h5, .h-margin-05 h6 {
  margin-bottom: 0.5rem;
}

.h-margin-06 .alfa, .h-margin-06 .beta, .h-margin-06 .gamma, .h-margin-06 .delta, .h-margin-06 .epsilon, .h-margin-06 .zeta,
.h-margin-06 h1, .h-margin-06 h2, .h-margin-06 h3, .h-margin-06 h4, .h-margin-06 h5, .h-margin-06 h6 {
  margin-bottom: 0.6rem;
}

.h-margin-08 .alfa, .h-margin-08 .beta, .h-margin-08 .gamma, .h-margin-08 .delta, .h-margin-08 .epsilon, .h-margin-08 .zeta,
.h-margin-08 h1, .h-margin-08 h2, .h-margin-08 h3, .h-margin-08 h4, .h-margin-08 h5, .h-margin-08 h6 {
  margin-bottom: 0.8rem;
}

.h-margin-1 .alfa, .h-margin-1 .beta, .h-margin-1 .gamma, .h-margin-1 .delta, .h-margin-1 .epsilon, .h-margin-1 .zeta,
.h-margin-1 h1, .h-margin-1 h2, .h-margin-1 h3, .h-margin-1 h4, .h-margin-1 h5, .h-margin-1 h6 {
  margin-bottom: 1rem;
}

.h-margin-1-5 .alfa, .h-margin-1-5 .beta, .h-margin-1-5 .gamma, .h-margin-1-5 .delta, .h-margin-1-5 .epsilon, .h-margin-1-5 .zeta,
.h-margin-1-5 h1, .h-margin-1-5 h2, .h-margin-1-5 h3, .h-margin-1-5 h4, .h-margin-1-5 h5, .h-margin-1-5 h6 {
  margin-bottom: 1.5rem;
}

.h-margin-2 .alfa, .h-margin-2 .beta, .h-margin-2 .gamma, .h-margin-2 .delta, .h-margin-2 .epsilon, .h-margin-2 .zeta,
.h-margin-2 h1, .h-margin-2 h2, .h-margin-2 h3, .h-margin-2 h4, .h-margin-2 h5, .h-margin-2 h6 {
  margin-bottom: 2rem;
}

.p-medium p {
  font-weight: 500;
}

.p-margin-0 p {
  margin-bottom: 0;
}

.p-margin-01 p {
  margin-bottom: 0.1rem;
}

.p-margin-02 p {
  margin-bottom: 0.2rem;
}

.p-margin-03 p {
  margin-bottom: 0.3rem;
}

.p-margin-04 p {
  margin-bottom: 0.4rem;
}

.p-margin-05 p {
  margin-bottom: 0.5rem;
}

.p-margin-08 p {
  margin-bottom: 0.8rem;
}

.p-margin-1 p {
  margin-bottom: 1rem;
}

.p-margin-1-5 p {
  margin-bottom: 1.5rem;
}

.p-margin-2 p {
  margin-bottom: 2rem;
}

/* Z-index helpers */
.z-index--1 {
  z-index: -1;
}

.z-index-1 {
  z-index: 1;
}

.z-index-2 {
  z-index: 2;
}

.z-index-3 {
  z-index: 3;
}

.z-index-4 {
  z-index: 4;
}

.z-index-5 {
  z-index: 5;
}

.z-index-6 {
  z-index: 6;
}

.z-index-7 {
  z-index: 7;
}

.z-index-8 {
  z-index: 8;
}

.z-index-9 {
  z-index: 9;
}

.z-index-10 {
  z-index: 10;
}

.z-index-100 {
  z-index: 100;
}

.z-index-1000 {
  z-index: 1000;
}

.z-index-10000 {
  z-index: 10000;
}

.opacity-0 {
  opacity: 0;
}

.opacity-01 {
  opacity: 0.1;
}

.opacity-02 {
  opacity: 0.2;
}

.opacity-03 {
  opacity: 0.3;
}

.opacity-04 {
  opacity: 0.4;
}

.opacity-05 {
  opacity: 0.5;
}

.opacity-06 {
  opacity: 0.6;
}

.opacity-07 {
  opacity: 0.7;
}

.opacity-08 {
  opacity: 0.8;
}

.opacity-09 {
  opacity: 0.9;
}

.opacity-1 {
  opacity: 1;
}

.dots {
  overflow: hidden;
}

.no-object-fit .img-flex-cover img {
  height: auto;
  width: auto;
  vertical-align: middle;
  margin: 0 auto;
}

.no-object-fit .img-flex-contain {
  display: block;
  height: auto;
}

.no-object-fit .img-flex-contain img {
  height: 100%;
  width: auto;
  vertical-align: middle;
  margin: 0 auto;
}

/* Base settings */
*, *::before, *::after {
  box-sizing: border-box;
}

* {
  transition: color .15s, background .15s, border .15s, opacity .15s;
}

*:after, *:before {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  outline: none;
}

::-moz-selection {
  color: #fff;
  background-color: #81c666;
}

::selection {
  color: #fff;
  background-color: #81c666;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-track {
  border-radius: 0;
  background: #edf5f1;
}

::-webkit-scrollbar-thumb {
  border-radius: 0;
  background: #81c666;
}

::-webkit-scrollbar-thumb:hover {
  background: #72bf53;
}

@-ms-viewport {
  width: device-width;
}

article, aside, details, figcaption, figure, footer, header, hgroup, main, menu, nav, section, summary {
  display: block;
}

html {
  position: relative;
  box-sizing: border-box;
  height: 100%;
  font-size: 100%;
}

body {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 310px;
  height: 100%;
  margin: 0;
  color: #000;
  background-color: #81c666;
  font-family: "Poppins", Arial, sans-serif;
  font-weight: 400;
  line-height: 1;
}

.body-gray {
  background-color: #edf5f1;
}

a {
  cursor: pointer;
  text-decoration: none;
  color: inherit;
  outline: none;
  font-family: "Poppins", Arial, sans-serif;
}

a:focus, a:active, a:visited {
  outline: none;
}

.a-hover:hover, .a-hover:focus {
  text-decoration: underline;
}

.a-link {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  padding: 0.6rem 0;
}

.a-link-hover:hover .a-link-text {
  text-decoration: underline;
}

.a-link-center {
  justify-content: center;
}

.a-link-text {
  color: #81c666;
  font-family: "Poppins", Arial, sans-serif;
  font-weight: 400;
  letter-spacing: 0.79px;
  text-transform: uppercase;
  font-size: 12px;
  font-size: 0.75rem;
}

.a-link-icon {
  display: inline-block;
  margin-left: 5px;
  vertical-align: middle;
  color: #81c666;
  font-size: 24px;
  font-size: 1.5rem;
}

.a-link-underline {
  text-decoration: underline;
}

p {
  display: block;
  margin-top: 0;
  margin-bottom: 2.5em;
  color: #495750;
  font-family: "Poppins", Arial, sans-serif;
  font-weight: 400;
  line-height: 1.6;
  letter-spacing: 0.55px;
  font-size: 14px;
  font-size: 0.875rem;
}

ol, ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

ul.list-style, ol.list-style {
  margin-bottom: 2em;
}

ul.list-style li, ol.list-style li {
  position: relative;
  display: block;
  margin-bottom: 15px;
  padding-left: 32px;
  color: #495750;
  font-family: "Poppins", Arial, sans-serif;
  font-weight: 400;
  line-height: 1.6;
  letter-spacing: 0.55px;
  font-size: 14px;
  font-size: 0.875rem;
}

ul.list-style a, ol.list-style a {
  color: #81c666;
}

ul.list-style a:hover, ol.list-style a:hover {
  text-decoration: underline;
}

ul.list-style li:before {
  content: "";
  position: absolute;
  display: block;
}

ul.list-style-check li:before {
  top: 7px;
  left: 3px;
  width: 16px;
  height: 12px;
  background-position: center;
  background-size: cover;
  background-image: url("../images/icons/check.svg");
}

ul.list-style-chevron li:before {
  top: 7px;
  left: 3px;
  width: 4px;
  height: 7px;
  background-position: center;
  background-size: cover;
  background-image: url("../images/icons/chevron-right.svg");
}

ol.list-style {
  list-style-position: inside;
  counter-reset: item;
}

ol.list-style li:before {
  position: absolute;
  top: 0;
  left: 0;
  display: inline-block;
  content: counter(item) ".";
  counter-increment: item;
  color: #495750;
  line-height: 1.4;
  font-size: 16px;
  font-size: 1rem;
}

em, i, .italic {
  font-style: italic;
}

strong, .strong {
  font-weight: 700;
}

small, .small {
  font-size: 12px;
  font-size: 0.75rem;
}

h1, .alfa, h2, .beta, h3, .gamma, h4, .delta, h5, .epsilon, h6, .zeta {
  margin: 0;
  margin-bottom: 1.5rem;
  color: #495750;
  font-family: "Poppins", Arial, sans-serif;
  font-weight: 500;
  line-height: 1.2;
}

h1 a, .alfa a, h2 a, .beta a, h3 a, .gamma a, h4 a, .delta a, h5 a, .epsilon a, h6 a, .zeta a {
  color: inherit;
}

h1, .alfa {
  font-size: 28px;
  font-size: 1.75rem;
}

@media (min-width: 576px) {
  h1, .alfa {
    font-size: 30px;
    font-size: 1.875rem;
  }
}

@media (min-width: 771px) {
  h1, .alfa {
    font-size: 30px;
    font-size: 1.875rem;
  }
}

.alfa-ext {
  font-size: 22px;
  font-size: 1.375rem;
}

@media (min-width: 370px) {
  .alfa-ext {
    font-size: 24px;
    font-size: 1.5rem;
  }
}

@media (min-width: 576px) {
  .alfa-ext {
    font-size: 27px;
    font-size: 1.6875rem;
  }
}

h2, .beta {
  font-size: 20px;
  font-size: 1.25rem;
}

@media (min-width: 576px) {
  h2, .beta {
    font-size: 20px;
    font-size: 1.25rem;
  }
}

@media (min-width: 771px) {
  h2, .beta {
    font-size: 20px;
    font-size: 1.25rem;
  }
}

.beta-ext {
  color: #a1a1a1;
  font-weight: 300;
  text-transform: uppercase;
  letter-spacing: 1.43px;
}

h3, .gamma {
  font-size: 15px;
  font-size: 0.9375rem;
}

@media (min-width: 576px) {
  h3, .gamma {
    font-size: 15px;
    font-size: 0.9375rem;
  }
}

@media (min-width: 771px) {
  h3, .gamma {
    font-size: 15px;
    font-size: 0.9375rem;
  }
}

h4, .delta {
  font-size: 26px;
  font-size: 1.625rem;
}

@media (min-width: 576px) {
  h4, .delta {
    font-size: 28px;
    font-size: 1.75rem;
  }
}

@media (min-width: 771px) {
  h4, .delta {
    font-size: 30px;
    font-size: 1.875rem;
  }
}

h5, .epsilon {
  font-size: 24px;
  font-size: 1.5rem;
}

@media (min-width: 576px) {
  h5, .epsilon {
    font-size: 26px;
    font-size: 1.625rem;
  }
}

@media (min-width: 771px) {
  h5, .epsilon {
    font-size: 28px;
    font-size: 1.75rem;
  }
}

h6, .zeta {
  font-size: 22px;
  font-size: 1.375rem;
}

@media (min-width: 576px) {
  h6, .zeta {
    font-size: 24px;
    font-size: 1.5rem;
  }
}

@media (min-width: 771px) {
  h6, .zeta {
    font-size: 26px;
    font-size: 1.625rem;
  }
}

.h-divider h1, .h-divider .alfa,
.h-divider h2, .h-divider .beta,
.h-divider h3, .h-divider .gamma,
.h-divider h4, .h-divider .delta,
.h-divider h5, .h-divider .epsilon,
.h-divider h6, .h-divider .zeta {
  position: relative;
}

.h-divider h1:after, .h-divider .alfa:after,
.h-divider h2:after, .h-divider .beta:after,
.h-divider h3:after, .h-divider .gamma:after,
.h-divider h4:after, .h-divider .delta:after,
.h-divider h5:after, .h-divider .epsilon:after,
.h-divider h6:after, .h-divider .zeta:after {
  content: '';
  display: block;
  width: 30px;
  height: 2px;
  background-color: #ddebe4;
  margin-top: 15px;
}

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
}

.slick-track:before,
.slick-track:after {
  display: table;
  content: '';
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
}

[dir='rtl'] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

.js-slider {
  display: none;
}

.js-slider.slick-initialized {
  display: block;
}

.js-slider .slick-slide {
  outline: none;
}

.js-slider .js-slide-item {
  outline: none;
}

.slick-margin-negative-10 {
  margin: 0 -10px;
}

.slick-margin-negative-10 .js-slide-item {
  padding-right: 10px;
  padding-left: 10px;
}

.slick-margin-negative-20 {
  margin: 0 -20px;
}

.slick-margin-negative-20 .js-slide-item {
  padding-right: 20px;
  padding-left: 20px;
}

.slick-margin-negative-30 {
  margin: 0 -30px;
}

.slick-margin-negative-30 .js-slide-item {
  padding-right: 30px;
  padding-left: 30px;
}

.slick-margin-negative-40 {
  margin: 0 -40px;
}

.slick-margin-negative-40 .js-slide-item {
  padding-right: 40px;
  padding-left: 40px;
}

.slick-margin-negative-50 {
  margin: 0 -50px;
}

.slick-margin-negative-50 .js-slide-item {
  padding-right: 50px;
  padding-left: 50px;
}

@media (min-width: 576px) {
  .slick-margin-negative-xs-5 {
    margin: 0 -5px;
  }
  .slick-margin-negative-xs-5 .js-slide-item {
    padding-right: 5px;
    padding-left: 5px;
  }
  .slick-margin-negative-xs-10 {
    margin: 0 -10px;
  }
  .slick-margin-negative-xs-10 .js-slide-item {
    padding-right: 10px;
    padding-left: 10px;
  }
  .slick-margin-negative-xs-20 {
    margin: 0 -20px;
  }
  .slick-margin-negative-xs-20 .js-slide-item {
    padding-right: 20px;
    padding-left: 20px;
  }
  .slick-margin-negative-xs-30 {
    margin: 0 -30px;
  }
  .slick-margin-negative-xs-30 .js-slide-item {
    padding-right: 30px;
    padding-left: 30px;
  }
  .slick-margin-negative-xs-40 {
    margin: 0 -40px;
  }
  .slick-margin-negative-xs-40 .js-slide-item {
    padding-right: 40px;
    padding-left: 40px;
  }
  .slick-margin-negative-xs-50 {
    margin: 0 -50px;
  }
  .slick-margin-negative-xs-50 .js-slide-item {
    padding-right: 50px;
    padding-left: 50px;
  }
}

@media (min-width: 771px) {
  .slick-margin-negative-sm-5 {
    margin: 0 -5px;
  }
  .slick-margin-negative-sm-5 .js-slide-item {
    padding-right: 5px;
    padding-left: 5px;
  }
  .slick-margin-negative-sm-10 {
    margin: 0 -10px;
  }
  .slick-margin-negative-sm-10 .js-slide-item {
    padding-right: 10px;
    padding-left: 10px;
  }
  .slick-margin-negative-sm-20 {
    margin: 0 -20px;
  }
  .slick-margin-negative-sm-20 .js-slide-item {
    padding-right: 20px;
    padding-left: 20px;
  }
  .slick-margin-negative-sm-30 {
    margin: 0 -30px;
  }
  .slick-margin-negative-sm-30 .js-slide-item {
    padding-right: 30px;
    padding-left: 30px;
  }
  .slick-margin-negative-sm-40 {
    margin: 0 -40px;
  }
  .slick-margin-negative-sm-40 .js-slide-item {
    padding-right: 40px;
    padding-left: 40px;
  }
  .slick-margin-negative-sm-50 {
    margin: 0 -50px;
  }
  .slick-margin-negative-sm-50 .js-slide-item {
    padding-right: 50px;
    padding-left: 50px;
  }
}

@media (min-width: 992px) {
  .slick-margin-negative-md-5 {
    margin: 0 -5px;
  }
  .slick-margin-negative-md-5 .js-slide-item {
    padding-right: 5px;
    padding-left: 5px;
  }
  .slick-margin-negative-md-10 {
    margin: 0 -10px;
  }
  .slick-margin-negative-md-10 .js-slide-item {
    padding-right: 10px;
    padding-left: 10px;
  }
  .slick-margin-negative-md-20 {
    margin: 0 -20px;
  }
  .slick-margin-negative-md-20 .js-slide-item {
    padding-right: 20px;
    padding-left: 20px;
  }
  .slick-margin-negative-md-30 {
    margin: 0 -30px;
  }
  .slick-margin-negative-md-30 .js-slide-item {
    padding-right: 30px;
    padding-left: 30px;
  }
  .slick-margin-negative-md-40 {
    margin: 0 -40px;
  }
  .slick-margin-negative-md-40 .js-slide-item {
    padding-right: 40px;
    padding-left: 40px;
  }
  .slick-margin-negative-md-50 {
    margin: 0 -50px;
  }
  .slick-margin-negative-md-50 .js-slide-item {
    padding-right: 50px;
    padding-left: 50px;
  }
}

.slick-margin-10 {
  margin: 0 10px;
}

.slick-margin-20 {
  margin: 0 20px;
}

.slick-margin-30 {
  margin: 0 30px;
}

.slick-margin-40 {
  margin: 0 40px;
}

.slick-margin-50 {
  margin: 0 50px;
}

.slick-dots {
  position: absolute;
  bottom: -50px;
  left: 0;
  width: 100%;
  margin: 0 !important;
  text-align: center;
}

.slick-dots li {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 40px;
  padding: 0 !important;
  cursor: pointer;
  vertical-align: middle;
  transition: all 250ms ease;
}

.slick-dots li:after {
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  width: 15px;
  height: 15px;
  content: '';
  transform: translate(-50%, -50%);
  border: 2px solid #81c666;
  background-color: #ffffff;
  border-radius: 100%;
}

.slick-dots li.slick-active:after, .slick-dots li:hover:after {
  border-color: #81c666;
  background-color: #81c666;
}

.slick-dots li button:before {
  content: none;
}

.slick-dots li button {
  display: none;
}

.slick-arrow-classic .slick-arrows.slick-prev {
  left: 0;
}

.slick-arrow-classic .slick-arrows.slick-next {
  right: 0;
}

.slick-arrows {
  position: absolute;
  z-index: 4;
  top: 50%;
  display: block;
  margin-top: -25px;
  cursor: pointer;
}

.slick-arrows .icon {
  display: flex;
  width: 50px;
  height: 50px;
  text-align: center;
  color: #495750;
  align-items: center;
  justify-content: center;
  font-size: 36px;
  font-size: 2.25rem;
}

.slick-arrows .icon:hover {
  color: #81c666;
}

.slick-arrows.slick-disabled {
  opacity: 0.2;
}

.slick-arrows.slick-prev {
  left: -50px;
}

.slick-arrows.slick-next {
  right: -50px;
}

.box {
  position: relative;
  background-color: #ffffff;
}

@media (min-width: 992px) {
  .box-mh-85 {
    height: 85px;
    height: 100%;
  }
}

.box-shadow {
  box-shadow: 0 3px 14px rgba(0, 24, 12, 0.07);
}

.box-gray {
  background-color: #edf5f1;
}

.box-border-green {
  border-left: 6px solid #81c666;
}

.box-radius {
  border-radius: 10px;
}

.box-radius-left {
  border-radius: 10px;
}

@media (min-width: 992px) {
  .box-radius-left {
    border-radius: 10px 0 0 10px;
  }
}

.box-radius-right {
  border-radius: 10px;
}

@media (min-width: 992px) {
  .box-radius-right {
    border-radius: 0 10px 10px 0;
  }
}

@media (min-width: 576px) {
  .box-xs-radius-right-full {
    border-radius: 0 10px 10px 0;
  }
}

.box-2-3 {
  padding: 1.5rem 1.5rem 1.5rem;
}

@media (min-width: 992px) {
  .box-2-3 {
    padding: 2rem 3rem 2rem;
  }
}

@media (min-width: 993px) and (max-width: 1200px) {
  .box-2-3 {
    padding: 2rem 1.5rem 2rem;
  }
}

.box-2-5-3 {
  padding: 1.5rem 1.5rem 1.5rem;
}

@media (min-width: 992px) {
  .box-2-5-3 {
    padding: 2.5rem 3rem 3.2rem;
  }
}

@media (min-width: 993px) and (max-width: 1200px) {
  .box-2-5-3 {
    padding: 2.5rem 1.5rem 3.2rem;
  }
}

.box-1-1 {
  padding: 1rem 1rem;
}

.box-1-2 {
  padding: 0.5rem 1.5rem;
}

.box-1-2-ext {
  padding: 1.2rem 1.2rem;
}

@media (min-width: 771px) {
  .box-1-2-ext {
    padding: 1.2rem 1.4rem;
  }
}

/* Content */
section {
  position: relative;
  padding: 3rem 0 3rem;
}

@media (min-width: 771px) {
  section {
    padding: 3rem 0 3rem;
  }
}

.no-padding {
  padding: 0;
}

@media (min-width: 576px) {
  .no-xs-padding {
    padding: 0;
  }
}

@media (min-width: 771px) {
  .no-sm-padding {
    padding: 0;
  }
}

@media (min-width: 992px) {
  .no-md-padding {
    padding: 0;
  }
}

@media (min-width: 1200px) {
  .no-lg-padding {
    padding: 0;
  }
}

.padding-style-1 {
  padding: 1rem 0.5rem 1rem;
}

@media (min-width: 771px) {
  .padding-style-1 {
    padding: 1rem 0 1rem;
  }
}

.padding-style-2 {
  padding: 2rem 0.5rem 2rem;
}

@media (min-width: 771px) {
  .padding-style-2 {
    padding: 2rem 0 2rem;
  }
}

.padding-style-3 {
  padding: 2rem 0.5rem 2rem;
}

@media (min-width: 771px) {
  .padding-style-3 {
    padding: 3rem 0 3rem;
  }
}

.padding-style-4 {
  padding: 3rem 0.5rem 3rem;
}

@media (min-width: 771px) {
  .padding-style-4 {
    padding: 4rem 0 4rem;
  }
}

.padding-style-5 {
  padding: 3rem 0.5rem 3rem;
}

@media (min-width: 771px) {
  .padding-style-5 {
    padding: 5rem 0 5rem;
  }
}

.padding-style-14-6 {
  padding: 5rem 0.5rem 5rem;
}

@media (min-width: 771px) {
  .padding-style-14-6 {
    padding: 14rem 0 6rem;
  }
}

.padding-content-1 {
  padding: 1rem 1rem;
}

.padding-content-2 {
  padding: 2rem 2rem;
}

.padding-content-3 {
  padding: 3rem 3rem;
}

.padding-content-4 {
  padding: 4rem 4rem;
}

.padding-content-5-5-1 {
  padding: 3rem 0 3rem;
}

@media (min-width: 576px) {
  .padding-content-5-5-1 {
    padding: 3rem 0;
  }
}

@media (min-width: 992px) {
  .padding-content-5-5-1 {
    padding: 5.5rem 1rem;
  }
}

.content-width-200 {
  max-width: 200px;
}

.content-width-300 {
  max-width: 300px;
}

.content-width-400 {
  max-width: 400px;
}

.content-width-570 {
  max-width: 570px;
}

.content-width-1070 {
  max-width: 1070px;
}

@media (min-width: 576px) {
  .content-width-280 {
    margin: 0 auto;
    max-width: 280px;
  }
}

main {
  position: relative;
  display: block;
  padding-top: 60px;
  -webkit-box-flex: 1;
  -webkit-flex: 1 0 auto;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
}

@media (min-width: 992px) {
  main {
    padding-top: 0;
  }
}

.container-full {
  padding: 0 15px;
}

.container-small,
.container-normal,
.container-large {
  margin: 0 auto;
  padding: 0 15px;
}

@media (min-width: 771px) {
  .container-small {
    width: 730px;
  }
}

@media (min-width: 992px) {
  .container-small {
    width: 800px;
  }
}

@media (min-width: 1200px) {
  .container-small {
    width: 800px;
  }
}

@media (min-width: 1440px) {
  .container-small {
    width: 800px;
  }
}

@media (max-width: 770px) {
  .container-small {
    width: 100%;
  }
}

@media (min-width: 771px) {
  .container-normal {
    width: 730px;
  }
}

@media (min-width: 992px) {
  .container-normal {
    width: 950px;
  }
}

@media (min-width: 1200px) {
  .container-normal {
    width: 1000px;
  }
}

@media (min-width: 1440px) {
  .container-normal {
    width: 1000px;
  }
}

@media (max-width: 770px) {
  .container-normal {
    width: 100%;
  }
}

@media (min-width: 771px) {
  .container-large {
    width: 730px;
  }
}

@media (min-width: 992px) {
  .container-large {
    width: 950px;
  }
}

@media (min-width: 1200px) {
  .container-large {
    width: 1140px;
  }
}

@media (min-width: 1440px) {
  .container-large {
    width: 1360px;
  }
}

@media (max-width: 770px) {
  .container-large {
    width: 100%;
  }
}

/* Footer */
.footer {
  position: relative;
  z-index: 2;
  background-color: #81c666;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  overflow: hidden;
  padding: 0.9375rem 0.9375rem 0.9375rem;
}

@media (min-width: 992px) {
  .footer {
    padding: 1.25rem 0 1.25rem;
  }
}

.footer-copy,
.footer-author {
  display: block;
  color: #a6a6a6;
  font-size: 11px;
  font-size: 0.6875rem;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1.82px;
  line-height: 1.8;
}

.footer-link {
  color: #81c666;
}

.footer-link:hover {
  text-decoration: underline;
}

@media (min-width: 992px) {
  .footer-panel-left {
    width: 320px;
    max-width: 320px;
    flex: 0 0 320px;
  }
}

@media (min-width: 992px) {
  .footer-panel-right {
    width: calc(100% - 320px);
    max-width: calc(100% - 320px);
    flex: 0 0 calc(100% - 320px);
  }
}

/* Header */
.navigation .navigation-trigger {
  display: none;
}

@media (max-width: 991px) {
  .navigation .container-normal, .navigation .container-large {
    width: 100%;
  }
  .navigation .nav-collapse {
    position: fixed;
    z-index: 98;
    top: 60px;
    left: 0;
    overflow: auto;
    width: 100%;
    height: 100%;
    padding: 0 0 60px;
    transform: translateX(-100%);
    background-color: #ffffff;
    transition: none 0.4s ease-out;
  }
  .navigation .navigation-trigger {
    position: fixed;
    z-index: 200;
    top: 0;
    right: 0;
    display: block;
    width: 60px;
    height: 60px;
  }
  .navigation .navigation-trigger-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    display: inline-block;
    width: 30px;
    height: 3px;
    transition-duration: 250ms;
    transition-property: background-color, transform;
    transform: translate(-50%, -50%);
    background-color: #000000;
    font-weight: 300;
  }
  .navigation .navigation-trigger-icon:before, .navigation .navigation-trigger-icon:after {
    position: absolute;
    display: block;
    width: 30px;
    height: 3px;
    content: '';
    transition-duration: 250ms;
    transition-property: margin, transform;
    background: #000000;
  }
  .navigation .navigation-trigger-icon:before {
    margin-top: -10px;
  }
  .navigation .navigation-trigger-icon:after {
    margin-top: 10px;
  }
  .navigation.is-collapse .nav-collapse {
    transform: translateX(0);
    transition: transform 250ms ease;
  }
  .navigation.is-collapse .navigation-trigger-icon {
    transition: none;
    background: rgba(0, 0, 0, 0);
  }
  .navigation.is-collapse .navigation-trigger-icon:before {
    margin-top: 0;
    transform: rotate(45deg);
  }
  .navigation.is-collapse .navigation-trigger-icon:after {
    margin-top: 0;
    transform: rotate(-45deg);
  }
}

.navigation {
  position: relative;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100%;
  padding: 25px 0;
  transition: box-shadow 350ms ease;
}

@media (min-width: 992px) {
  .navigation {
    overflow: hidden;
  }
}

@media (max-width: 991px) {
  .navigation {
    position: fixed;
    background-color: #ffffff;
    box-shadow: 0 5px 10px rgba(1, 1, 1, 0.1);
    padding: 0 10px;
  }
}

@media (min-width: 992px) {
  .header-flex {
    display: flex;
    justify-content: space-between;
  }
}

.header-logo {
  text-align: center;
  height: 100%;
  display: flex;
  align-items: center;
  padding-left: 20px;
}

@media (max-width: 991px) {
  .header-logo {
    float: left;
    position: relative;
    padding: 15px 0 16px;
    z-index: 99;
  }
}

.header-logo-link {
  display: inline-block;
  vertical-align: middle;
}

.header-image {
  margin: 0 auto;
}

@media (max-width: 991px) {
  .header-image {
    width: 110px;
    height: auto;
  }
}

.menu-list {
  text-align: center;
}

@media (max-width: 991px) {
  .menu-list {
    display: flex;
    flex-wrap: wrap;
  }
}

@media (max-width: 991px) {
  .menu-list-item {
    display: inline-block;
    text-align: left;
    width: 33.33%;
    padding-right: 1.5rem;
  }
}

@media (max-width: 770px) {
  .menu-list-item {
    width: 50%;
  }
}

@media (max-width: 460px) {
  .menu-list-item {
    padding-right: 0;
    width: 100%;
  }
}

.menu-list-item:hover .menu-list-link-text, .menu-list-item.is-active .menu-list-link-text {
  color: #519a35;
}

@media (min-width: 992px) {
  .menu-list-item:hover .menu-list-link-text, .menu-list-item.is-active .menu-list-link-text {
    color: #edf5f1;
  }
}

.menu-list-item:hover .menu-list-link-icon svg, .menu-list-item.is-active .menu-list-link-icon svg {
  fill: #519a35;
}

@media (min-width: 992px) {
  .menu-list-item:hover .menu-list-link-icon svg, .menu-list-item.is-active .menu-list-link-icon svg {
    fill: #edf5f1;
  }
}

.menu-list-link {
  position: relative;
  display: flex;
  padding: 0.5rem 0;
  text-decoration: none;
  margin-bottom: 0;
}

@media (min-width: 992px) {
  .menu-list-link {
    margin-bottom: 0.1rem;
  }
}

.menu-list-link-icon {
  display: inline-block;
  vertical-align: middle;
  padding-right: 1rem;
}

.menu-list-link-icon svg {
  font-size: 18px;
  font-size: 1.125rem;
  fill: #519a35;
}

@media (min-width: 992px) {
  .menu-list-link-icon svg {
    font-size: 20px;
    font-size: 1.25rem;
  }
}

.menu-list-link-text {
  display: inline-block;
  vertical-align: middle;
  color: #81c666;
  font-size: 14px;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.4;
}

@media (min-width: 992px) {
  .menu-list-link-text {
    font-size: 15px;
    font-size: 0.9375rem;
    color: #ffffff;
  }
}

.menu-title {
  display: block;
  margin-bottom: 0.8rem;
  color: #495750;
  font-size: 11px;
  font-size: 0.6875rem;
  font-weight: 600;
  line-height: 1.2;
  text-transform: uppercase;
  letter-spacing: 1.82px;
}

@media (min-width: 992px) {
  .menu-title {
    margin-bottom: 1.2rem;
  }
}

@media (min-width: 992px) {
  .header-panel-left {
    width: 320px;
    max-width: 320px;
    flex: 0 0 320px;
  }
}

@media (max-width: 991px) {
  .header-panel-left .box-1-1 {
    padding: 0;
  }
}

@media (min-width: 992px) {
  .header-panel-right {
    width: calc(100% - 320px);
    max-width: calc(100% - 320px);
    flex: 0 0 calc(100% - 320px);
  }
}

.header-contact-group {
  width: 100%;
  max-width: 100%;
  flex: 0 0 100%;
}

@media (min-width: 992px) {
  .header-contact-group {
    margin-bottom: 12px;
  }
}

@media (min-width: 1440px) {
  .header-contact-group {
    margin-bottom: 0;
    width: 55%;
    max-width: 55%;
    flex: 0 0 55%;
  }
}

@media (min-width: 1800px) {
  .header-contact-group {
    width: 60%;
    max-width: 60%;
    flex: 0 0 60%;
  }
}

@media (max-width: 991px) {
  .header-search-group {
    order: -2;
  }
}

.header-promo-group {
  width: 100%;
  max-width: 100%;
  flex: 0 0 100%;
}

@media (max-width: 991px) {
  .header-promo-group {
    order: -1;
  }
}

@media (min-width: 992px) {
  .header-promo-group {
    width: 40%;
    max-width: 40%;
    flex: 0 0 40%;
  }
}

@media (min-width: 1440px) {
  .header-promo-group {
    width: 20%;
    max-width: 20%;
    flex: 0 0 20%;
  }
}

@media (min-width: 1800px) {
  .header-promo-group {
    width: 16%;
    max-width: 16%;
    flex: 0 0 16%;
  }
}

.header-action-group {
  width: 60%;
  max-width: 60%;
  flex: 0 0 60%;
}

@media (min-width: 1440px) {
  .header-action-group {
    width: 25%;
    max-width: 25%;
    flex: 0 0 25%;
  }
}

@media (min-width: 1800px) {
  .header-action-group {
    width: 24%;
    max-width: 24%;
    flex: 0 0 24%;
  }
}

@media (min-width: 576px) {
  .header-contact-box {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}

@media (max-width: 991px) {
  .header-contact-box {
    padding: 0.5rem 0.6rem;
    border-radius: 0;
  }
}

@media (max-width: 575px) {
  .header-contact-box {
    padding: 1rem 0.6rem;
  }
}

@media (min-width: 993px) and (max-width: 1200px) {
  .header-contact-box {
    border-radius: 10px 0 0 10px;
  }
}

@media (min-width: 1201px) and (max-width: 1440px) {
  .header-contact-box {
    border-radius: 10px 0 0 10px;
  }
}

.header-action-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media (max-width: 991px) {
  .header-promo-box {
    border-radius: 0;
  }
}

@media (max-width: 575px) {
  .box-contact-left-panel {
    max-width: 240px;
    margin: 0 auto;
  }
}

@media (min-width: 576px) {
  .box-contact-left-panel {
    width: 280px;
    max-width: 280px;
    flex: 0 0 280px;
  }
}

@media (min-width: 1440px) {
  .box-contact-left-panel {
    width: 240px;
    max-width: 240px;
    flex: 0 0 240px;
  }
}

@media (min-width: 1600px) {
  .box-contact-left-panel {
    width: 280px;
    max-width: 280px;
    flex: 0 0 280px;
  }
}

.box-contact-right-panel {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap;
}

@media (max-width: 575px) {
  .box-contact-right-panel {
    justify-content: center;
    text-align: center;
    margin-top: 20px;
  }
}

@media (min-width: 576px) {
  .box-contact-right-panel {
    padding-left: 0.5rem;
    width: calc(100% - 280px);
    max-width: calc(100% - 280px);
    flex: 0 0 calc(100% - 280px);
  }
}

@media (min-width: 1440px) {
  .box-contact-right-panel {
    width: calc(100% - 240px);
    max-width: calc(100% - 240px);
    flex: 0 0 calc(100% - 240px);
  }
}

@media (min-width: 1600px) {
  .box-contact-right-panel {
    width: calc(100% - 280px);
    max-width: calc(100% - 280px);
    flex: 0 0 calc(100% - 280px);
  }
}

.box-action-left-panel {
  display: flex;
  align-items: center;
  width: calc(100% - 40px);
  max-width: calc(100% - 40px);
  flex: 0 0 calc(100% - 40px);
}

.box-action-right-panel {
  width: 40px;
  max-width: 40px;
  flex: 0 0 40px;
  padding-left: 0.5rem;
}

.header-link {
  display: inline-flex;
  align-items: center;
  justify-content: flex-end;
  color: #495750;
  padding: 0.5rem 0;
  font-size: 15px;
  font-size: 0.9375rem;
  font-weight: 500;
  line-height: 1.2;
  text-transform: uppercase;
  letter-spacing: 1.07px;
  width: 100%;
}

@media (max-width: 575px) {
  .header-link {
    justify-content: center;
  }
}

@media (min-width: 1800px) {
  .header-link {
    width: auto;
  }
}

.header-link:hover {
  color: #81c666;
}

.header-link:hover svg {
  fill: #81c666;
}

.header-link svg {
  position: relative;
  top: 1px;
}

.doctor-block {
  display: flex;
  align-items: center;
}

.doctor-block:hover .p-medium p {
  color: #81c666;
}

.doctor-image-block {
  width: 50px;
  height: 50px;
  max-width: 50px;
  border-radius: 100%;
  overflow: hidden;
  background-color: #495750;
}

@media (min-width: 992px) {
  .doctor-image-block {
    width: 65px;
    height: 65px;
    max-width: 65px;
  }
}

.doctor-text-block {
  padding-left: 1rem;
  width: calc(100% - 50px);
  height: calc(100% - 50px);
  max-width: calc(100% - 50px);
}

@media (min-width: 992px) {
  .doctor-text-block {
    width: calc(100% - 65px);
    height: calc(100% - 65px);
    max-width: calc(100% - 65px);
  }
}

@media (min-width: 1600px) {
  .doctor-text-block {
    padding-left: 2rem;
  }
}

.header-promo-code {
  position: relative;
  height: 100%;
}

.header-promo-code:hover .header-promo-icon svg {
  fill: #519a35;
}

.form-input-promo-code {
  height: 100%;
  border: 0;
  text-transform: uppercase;
  background-color: transparent;
  font-weight: 500;
  padding: 2.2rem 3rem 1rem 4.5rem;
  color: #495750;
  font-size: 15px;
  font-size: 0.9375rem;
  letter-spacing: 0;
}

@media (max-width: 991px) {
  .form-input-promo-code {
    font-size: 14px;
    font-size: 0.875rem;
    height: 60px;
    padding: 1.5rem 3rem 0.5rem 3.5rem;
  }
}

.header-promo-icon {
  position: absolute;
  top: 50%;
  left: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  transform: translateY(-50%);
  pointer-events: none;
}

@media (min-width: 992px) {
  .header-promo-icon {
    left: 20px;
  }
}

.header-promo-icon svg {
  fill: #81c666;
  font-size: 28px;
  font-size: 1.75rem;
}

@media (min-width: 992px) {
  .header-promo-icon svg {
    font-size: 32px;
    font-size: 2rem;
  }
}

.header-promo-label {
  position: absolute;
  top: 50%;
  left: 3.5rem;
  font-weight: 300;
  color: #495750;
  pointer-events: none;
  text-transform: uppercase;
  font-size: 14px;
  font-size: 0.875rem;
  transform: translateY(-18px);
}

@media (min-width: 992px) {
  .header-promo-label {
    font-size: 15px;
    font-size: 0.9375rem;
    left: 4.5rem;
  }
}

.header-promo-btn {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background-color: #81c666;
  cursor: pointer;
  z-index: 4;
  padding: 0;
  border-radius: 0;
}

@media (min-width: 992px) {
  .header-promo-btn {
    right: 5px;
    border-radius: 10px;
    background-color: transparent;
  }
}

.header-promo-btn:hover {
  background-color: #81c666;
}

.header-promo-btn:hover svg {
  fill: #ffffff;
}

.header-promo-btn svg {
  fill: #ffffff;
  font-size: 10px;
  font-size: 0.625rem;
}

@media (min-width: 992px) {
  .header-promo-btn svg {
    fill: #81c666;
  }
}

.basket-icon {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  cursor: pointer;
}

@media (max-width: 991px) {
  .basket-icon {
    position: absolute;
    top: 0;
    right: 150px;
    height: 60px;
    width: 45px;
    z-index: 3;
  }
}

.basket-icon:hover svg {
  fill: #519a35;
}

.basket-icon svg {
  fill: #81c666;
  font-size: 26px;
  font-size: 1.625rem;
}

.basket-count {
  position: absolute;
  top: -5px;
  right: -5px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 20px;
  height: 20px;
  color: #ffffff;
  border: 2px solid #ffffff;
  background-color: #c6666f;
  font-size: 10px;
  font-size: 0.625rem;
  font-weight: 500;
  border-radius: 100%;
}

@media (max-width: 991px) {
  .basket-count {
    top: 10px;
    right: 0;
  }
}

.user-block {
  display: flex;
  align-items: center;
}

@media (max-width: 991px) {
  .user-block {
    justify-content: center;
    position: absolute;
    top: 0;
    right: 60px;
    height: 60px;
    z-index: 3;
    width: 45px;
  }
}

.user-block:hover .p-medium p {
  color: #81c666;
}

.user-image-block {
  border-radius: 100%;
  background-color: #495750;
  overflow: hidden;
  padding-top: 5px;
  width: 35px;
  height: 35px;
  max-width: 35px;
}

@media (min-width: 992px) {
  .user-image-block {
    padding-top: 8px;
    width: 45px;
    height: 45px;
    max-width: 45px;
  }
}

.user-image-block img {
  position: relative;
  bottom: -2px;
}

.user-text-block {
  padding-left: 1.2rem;
  width: calc(100% - 45px);
  height: calc(100% - 45px);
  max-width: calc(100% - 45px);
}

.logout-icon {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  cursor: pointer;
}

@media (max-width: 991px) {
  .logout-icon {
    position: absolute;
    top: 0;
    right: 105px;
    height: 60px;
    width: 45px;
    z-index: 3;
  }
}

.logout-icon:hover svg {
  fill: #519a35;
}

.logout-icon svg {
  fill: #81c666;
  font-size: 26px;
  font-size: 1.625rem;
}

/* Social block */
@media (min-width: 1600px) {
  .social-list {
    margin-left: 2rem;
  }
}

.social-list-item {
  display: inline-block;
  vertical-align: middle;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 8px;
}

@media (min-width: 992px) {
  .social-list-item {
    margin-left: 15px;
  }
}

.social-list-item:first-child {
  margin-left: 0;
}

.social-list-link {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 35px;
  height: 35px;
  text-align: center;
  vertical-align: middle;
  border-radius: 100%;
}

.social-list-link svg {
  fill: #ffffff;
  font-size: 18px;
  font-size: 1.125rem;
}

.social-list-link-mail {
  background-color: #81c666;
}

.social-list-link-mail:hover {
  background-color: #519a35;
}

.social-list-link-facebook {
  background-color: #5279bf;
}

.social-list-link-facebook:hover {
  background-color: #3c60a2;
}

.social-list-link-twitter {
  background-color: #74ccf3;
}

.social-list-link-twitter:hover {
  background-color: #45bbef;
}

/* Modal block */
.modal-overlay {
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  display: none;
  width: 100%;
  height: 100%;
  background-color: rgba(129, 198, 102, 0.5);
}

.modal-overlay.is-active {
  display: block;
}

.modal-visible .modal-block {
  position: relative;
  overflow: visible;
  padding: 200px 0 200px;
  height: auto;
  width: 100%;
  opacity: 1;
  visibility: visible;
}

.modal-block {
  position: fixed;
  z-index: 999999;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  visibility: hidden;
  overflow: hidden;
  width: auto;
  height: 0;
  padding: 3rem 0 2rem;
  opacity: 0;
  -webkit-overflow-scrolling: touch;
  display: flex;
  justify-content: center;
  transition: opacity 400ms ease;
}

@media (min-width: 771px) {
  .modal-block {
    padding: 6rem 0 4rem;
  }
}

.modal-block.is-active {
  visibility: visible;
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;
  height: 100%;
  opacity: 1;
  transition: opacity 400ms ease;
}

.modal-block.is-active .modal-box {
  position: relative;
  top: auto;
  left: auto;
  transform: translate(0, 0);
}

.modal-block.modal-hidden .modal-close,
.modal-block.modal-hidden .modal-scroll {
  display: none;
}

.modal-scroll {
  width: 100%;
  margin: auto 1rem;
}

.modal-box-840 {
  margin: 0 auto;
  padding: 0;
  width: 100%;
}

@media (min-width: 992px) {
  .modal-box-840 {
    width: 840px;
  }
}

.modal-close {
  position: absolute;
  z-index: 99;
  top: 0;
  right: 0;
  display: flex;
  width: 50px;
  height: 50px;
  line-height: 50px;
  cursor: pointer;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.modal-close svg {
  fill: #81c666;
  font-size: 20px;
  font-size: 1.25rem;
}

.modal-close:hover svg {
  fill: #519a35;
}

.modal-body {
  box-shadow: 0 3px 14px rgba(0, 24, 12, 0.07);
  border-radius: 10px;
  background-color: #ffffff;
  overflow: hidden;
}

.modal-header {
  padding: 3.5rem 1.2rem 2.5rem;
}

@media (min-width: 992px) {
  .modal-header {
    padding: 3.5rem 3.6rem 2.5rem;
  }
}

.modal-footer {
  background-color: #edf5f1;
  padding: 2rem 1.2rem 3rem;
}

@media (min-width: 992px) {
  .modal-footer {
    padding: 2rem 3.6rem 3rem;
  }
}

/* Table block */
@media (max-width: 770px) {
  .table-responsives {
    overflow-x: auto;
    overflow-y: hidden;
    width: 100%;
    min-height: .01%;
    border: 0 !important;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
}

table {
  width: 100%;
  border-collapse: collapse;
}

@media (max-width: 770px) {
  table {
    display: block;
  }
}

@media (max-width: 770px) {
  table thead {
    display: none;
  }
}

table thead tr {
  border-bottom: 2px solid #ddebe4;
}

table th {
  padding: 10px 10px 10px 10px;
  font-family: "Poppins", Arial, sans-serif;
  font-weight: 300;
  line-height: 1.4;
  color: #a1a1a1;
  text-align: center;
  font-size: 14px;
  font-size: 0.875rem;
}

@media (min-width: 771px) {
  table th {
    font-size: 15px;
    font-size: 0.9375rem;
  }
}

table th:first-child {
  padding: 10px 0;
  text-align: left;
}

table th:last-child {
  padding: 10px 0;
  text-align: right;
}

@media (max-width: 770px) {
  table tbody {
    display: block;
  }
}

table tbody tr {
  border-top: 2px solid #ddebe4;
}

@media (max-width: 770px) {
  table tbody tr {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-top: 1rem;
    padding-top: 1rem;
  }
  table tbody tr:first-child {
    margin-top: 0;
  }
  table tbody tr:last-child {
    padding-bottom: 1rem;
  }
}

@media (max-width: 770px) {
  table [data-header]:before {
    content: attr(data-header);
    display: block;
    margin-bottom: 0.2rem;
    font-family: "Poppins", Arial, sans-serif;
    font-weight: 300;
    line-height: 1.4;
    color: #a1a1a1;
    font-size: 11px;
    font-size: 0.6875rem;
  }
}

table td {
  padding: 15px 10px 15px 10px;
  font-family: "Poppins", Arial, sans-serif;
  line-height: 1.4;
  font-weight: 300;
  color: #495750;
}

@media (min-width: 771px) {
  table td {
    text-align: center;
    padding: 22px 10px 22px 10px;
  }
}

@media (max-width: 770px) {
  table td {
    flex: 1;
  }
}

table td:first-child {
  padding: 15px 10px 5px;
  text-align: left;
}

@media (max-width: 770px) {
  table td:first-child {
    width: 100%;
    max-width: 100%;
    flex: 0 0 100%;
  }
}

@media (min-width: 771px) {
  table td:first-child {
    padding: 22px 0;
  }
}

table td:last-child {
  padding: 15px 10px;
}

@media (min-width: 771px) {
  table td:last-child {
    padding: 22px 0;
    text-align: right;
  }
}

@media (min-width: 771px) {
  table .td-product {
    max-width: 300px;
  }
}

@media (min-width: 992px) {
  table .td-product {
    max-width: 280px;
  }
}

@media (min-width: 1200px) {
  table .td-product {
    max-width: 360px;
  }
}

@media (max-width: 770px) {
  table .td-quantity {
    display: none;
  }
}

table .table-text {
  color: #a1a1a1;
}

table .table-text-black {
  color: #495750;
}

table .table-text-bold {
  font-weight: 500;
}

table .table-text-green {
  color: #81c666;
}

table .table-text-big {
  font-size: 18px;
  font-size: 1.125rem;
}

@media (min-width: 771px) {
  table .table-text-big {
    font-size: 20px;
    font-size: 1.25rem;
  }
}

table .table-image {
  width: 50px;
  max-width: 50px;
  flex: 0 0 50px;
}

@media (min-width: 771px) {
  table .table-image {
    width: 110px;
    max-width: 110px;
    flex: 0 0 110px;
  }
}

table .table-count {
  min-width: 35px;
  flex: 0 0 35px;
  color: #495750;
  font-weight: 500;
  font-size: 18px;
  font-size: 1.125rem;
}

@media (min-width: 771px) {
  table .table-count {
    font-size: 20px;
    font-size: 1.25rem;
  }
}

.table-group {
  margin-bottom: 50px;
  border: 1px solid #f2f2f2;
}

.table-header {
  background-color: #f7f7f7;
}

.table-header-row {
  position: relative;
  display: table;
  width: 100%;
  height: 50px;
}

.table-header-cell {
  display: table-cell;
  float: none;
  padding: 10px 20px;
  vertical-align: middle;
}

@media (max-width: 770px) {
  .table-header-cell {
    padding: 10px 10px;
  }
}

@media (max-width: 369px) {
  .table-header-cell {
    padding: 10px 5px;
  }
}

.table-header-text {
  font-size: 10px;
  line-height: 1.2;
  text-transform: uppercase;
  color: #000000;
}

.table-body-cell {
  display: table-cell;
  float: none;
  padding: 10px 20px;
  vertical-align: middle;
}

@media (max-width: 770px) {
  .table-body-cell {
    padding: 10px 10px;
  }
}

@media (max-width: 369px) {
  .table-body-cell {
    padding: 10px 5px;
  }
}

.table-body-text {
  display: inline-block;
  margin-bottom: 0;
  padding: 10px 0;
  font-size: 16px;
  line-height: 1.2;
  vertical-align: middle;
  text-transform: uppercase;
  color: #000000;
}

.table-body-row {
  position: relative;
  display: table;
  width: 100%;
  height: 100px;
}

.table-body-row:nth-child(even) {
  background-color: #f7f7f7;
}

.side-panel {
  background-color: #81c666;
}

@media (min-width: 992px) {
  .side-panel {
    width: 320px;
    max-width: 320px;
    flex: 0 0 320px;
  }
}

.side-content {
  padding: 2.5rem 1.2rem;
}

@media (min-width: 992px) {
  .side-content {
    padding: 3rem 1.5rem;
  }
}

@media (min-width: 992px) {
  .main-content {
    width: calc(100% - 320px);
    max-width: calc(100% - 320px);
    flex: 0 0 calc(100% - 320px);
  }
}

.search-group {
  position: relative;
}

@media (min-width: 992px) {
  .search-group {
    padding: 0 10px 0 25px;
  }
  .search-group:before {
    content: '';
    position: absolute;
    top: 0;
    left: -40px;
    background-color: #6ab14f;
    display: block;
    width: 100%;
    height: 100%;
  }
}

/* category block */
.category-item {
  margin-bottom: 1.5rem;
}

.category-item-main .category-content {
  padding: 0.8rem 0;
  padding-right: 2.2rem;
  border-left-color: #81c666;
  min-height: 120px;
}

@media (min-width: 576px) {
  .category-item-main .category-content {
    padding-right: 0.8rem;
    min-height: 170px;
  }
}

@media (min-width: 576px) {
  .category-item-main .category-icon {
    top: auto;
    bottom: 25px;
    transform: translateY(0);
  }
}

.category-item-main .category-icon svg {
  fill: #81c666;
}

.category-item-main .category-image-block {
  width: 90px;
  max-width: 90px;
  flex: 0 0 90px;
}

@media (min-width: 576px) {
  .category-item-main .category-image-block {
    width: 70px;
    max-width: 70px;
    flex: 0 0 70px;
  }
}

@media (min-width: 771px) {
  .category-item-main .category-image-block {
    width: 90px;
    max-width: 90px;
    flex: 0 0 90px;
  }
}

@media (min-width: 1200px) {
  .category-item-main .category-image-block {
    width: 60px;
    max-width: 60px;
    flex: 0 0 60px;
  }
}

@media (min-width: 1440px) {
  .category-item-main .category-image-block {
    width: 90px;
    max-width: 90px;
    flex: 0 0 90px;
  }
}

.category-item-main .category-text-block {
  width: calc(100% - 90px);
  max-width: calc(100% - 90px);
  flex: 0 0 calc(100% - 90px);
}

@media (min-width: 576px) {
  .category-item-main .category-text-block {
    width: calc(100% - 70px);
    max-width: calc(100% - 70px);
    flex: 0 0 calc(100% - 70px);
  }
}

@media (min-width: 771px) {
  .category-item-main .category-text-block {
    width: calc(100% - 90px);
    max-width: calc(100% - 90px);
    flex: 0 0 calc(100% - 90px);
  }
}

@media (min-width: 1200px) {
  .category-item-main .category-text-block {
    width: calc(100% - 60px);
    max-width: calc(100% - 60px);
    flex: 0 0 calc(100% - 60px);
  }
}

@media (min-width: 1440px) {
  .category-item-main .category-text-block {
    width: calc(100% - 90px);
    max-width: calc(100% - 90px);
    flex: 0 0 calc(100% - 90px);
  }
}

.category-content {
  position: relative;
  display: flex;
  align-items: center;
  overflow: hidden;
  box-shadow: 0 3px 14px rgba(0, 24, 12, 0.07);
  border-radius: 10px;
  background-color: #ffffff;
  border-left: 6px solid #ced4d1;
  padding: 0.5rem 2.2rem 0.5rem 0.8rem;
  max-height: 80px;
}

@media (min-width: 576px) {
  .category-content {
    padding: 0.5rem 2.2rem 0.5rem 0.7rem;
  }
}

@media (min-width: 771px) {
  .category-content {
    padding: 0.5rem 2.5rem 0.5rem 1.5rem;
  }
}

.category-content:hover .category-title {
  color: #81c666;
}

.category-content:hover .category-icon svg {
  fill: #81c666;
}

.category-content:hover img {
  transform: scale(1.05, 1.05);
}

.category-image-block {
  display: block;
  overflow: hidden;
  position: relative;
  width: 70px;
  max-width: 70px;
  flex: 0 0 70px;
}

@media (min-width: 576px) {
  .category-image-block {
    width: 50px;
    max-width: 50px;
    flex: 0 0 50px;
  }
}

@media (min-width: 771px) {
  .category-image-block {
    width: 70px;
    max-width: 70px;
    flex: 0 0 70px;
  }
}

.category-image-block img {
  transition: transform 400ms ease-out;
}

.category-text-block {
  padding-left: 1rem;
  width: calc(100% - 70px);
  max-width: calc(100% - 70px);
  flex: 0 0 calc(100% - 70px);
}

@media (min-width: 576px) {
  .category-text-block {
    width: calc(100% - 50px);
    max-width: calc(100% - 50px);
    flex: 0 0 calc(100% - 50px);
  }
}

@media (min-width: 771px) {
  .category-text-block {
    width: calc(100% - 70px);
    max-width: calc(100% - 70px);
    flex: 0 0 calc(100% - 70px);
  }
}

.category-icon {
  position: absolute;
  bottom: 20px;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  height: 14px;
}

.category-icon svg {
  fill: #ced4d1;
  font-size: 12px;
  font-size: 0.75rem;
}

/* product block */
.product-item {
  margin-bottom: 1.25rem;
}

.product-item:last-child {
  margin-bottom: 0;
}

.product-item-row {
  margin-bottom: 0;
}

@media (max-width: 575px) {
  .product-item-row {
    margin: 0 -23px;
  }
}

@media (max-width: 575px) {
  .product-item-row.product-item-last .product-content {
    border-bottom: 0;
  }
}

.product-item-row .product-content {
  display: flex;
  border: 0;
  border-radius: 0;
  padding: 1.2rem 1rem;
  border-bottom: 2px solid #ddebe4;
}

@media (min-width: 576px) {
  .product-item-row .product-content {
    padding: 1.2rem 0;
  }
}

.product-item-row .product-btn {
  width: 35px;
  height: 35px;
  margin-left: 0.6rem;
  padding: 0;
  flex: 0 0 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 1px;
}

.product-item-row .product-btn .btn-icon {
  margin-right: 0;
}

.product-item-row .product-image-block {
  width: 50px;
  max-width: 50px;
  flex: 0 0 50px;
  float: none;
}

@media (min-width: 576px) {
  .product-item-row .product-image-block {
    width: 75px;
    max-width: 75px;
    flex: 0 0 75px;
  }
}

.product-item-row .product-text-block {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding-left: 1rem;
  width: calc(100% - 50px);
  max-width: calc(100% - 50px);
  flex: 0 0 calc(100% - 50px);
}

@media (min-width: 576px) {
  .product-item-row .product-text-block {
    width: calc(100% - 75px);
    max-width: calc(100% - 75px);
    flex: 0 0 calc(100% - 75px);
  }
}

.product-item-row .product-left-panel {
  min-height: auto;
  margin-bottom: 0;
}

@media (min-width: 576px) {
  .product-item-row .product-left-panel {
    width: 40%;
    max-width: 40%;
    flex: 0 0 40%;
    margin-top: 5px;
  }
}

@media (min-width: 1440px) {
  .product-item-row .product-left-panel {
    width: 40%;
    max-width: 40%;
    flex: 0 0 40%;
    margin-top: 5px;
  }
}

.product-item-row .product-right-panel {
  width: 100%;
  max-width: 100%;
  flex: 0 0 100%;
}

@media (min-width: 576px) {
  .product-item-row .product-right-panel {
    width: 60%;
    max-width: 60%;
    flex: 0 0 60%;
  }
}

@media (min-width: 1440px) {
  .product-item-row .product-right-panel {
    width: 60%;
    max-width: 60%;
    flex: 0 0 60%;
  }
}

@media (min-width: 1201px) and (max-width: 1440px) {
  .product-item-row .product-right-panel {
    margin-top: 15px;
  }
}

@media (min-width: 1201px) and (max-width: 1440px) {
  .product-item-row .product-right-panel,
  .product-item-row .product-left-panel {
    width: 100%;
    max-width: 100%;
    flex: 0 0 100%;
  }
}

.product-item-row .product-btn-block {
  flex-wrap: wrap;
}

@media (max-width: 991px) {
  .product-item-row .product-btn-block {
    width: 100%;
    max-width: 100%;
    flex: 0 0 100%;
    justify-content: flex-end;
  }
}

@media (max-width: 575px) {
  .product-item-row .product-btn-block {
    margin-top: 0;
  }
}

.product-item-row .product-btn-block .quantity-group {
  width: auto;
  margin-bottom: 0;
}

.product-item-row .product-btn-block .product-btn {
  margin-left: 0.6rem;
}

.product-item-row-ext {
  margin-bottom: 0;
}

@media (max-width: 991px) {
  .product-item-row-ext:first-child {
    margin-bottom: 2rem;
  }
}

@media (min-width: 992px) {
  .product-item-row-ext:first-child:after {
    content: '';
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    width: 2px;
    height: 55px;
    display: block;
    background-color: #ddebe4;
  }
}

.product-item-row-ext .product-content {
  display: flex;
  border: 0;
  border-radius: 0;
  padding: 0;
  background-color: transparent;
}

.product-item-row-ext .product-btn {
  width: 35px;
  height: 35px;
  margin-left: 0.6rem;
  padding: 0;
  flex: 0 0 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 1px;
}

.product-item-row-ext .product-btn .btn-icon {
  margin-right: 0;
}

.product-item-row-ext .product-image-block {
  border-radius: 10px;
  background-color: #ffffff;
  float: none;
  padding: 10px 5px;
  width: 60px;
  max-width: 60px;
  flex: 0 0 60px;
  height: 120px;
}

@media (min-width: 576px) {
  .product-item-row-ext .product-image-block {
    padding: 10px;
    width: 120px;
    max-width: 120px;
    flex: 0 0 120px;
    height: 120px;
  }
}

.product-item-row-ext .product-text-block {
  display: block;
  padding-left: 0.8rem;
  width: calc(100% - 60px);
  max-width: calc(100% - 60px);
  flex: 0 0 calc(100% - 60px);
}

@media (min-width: 576px) {
  .product-item-row-ext .product-text-block {
    padding-left: 1.2rem;
    width: calc(100% - 120px);
    max-width: calc(100% - 120px);
    flex: 0 0 calc(100% - 120px);
  }
}

.product-item-row-ext .product-title-block {
  height: 45px;
  margin-bottom: 0.2rem;
}

.product-item-row-ext .product-price-block {
  justify-content: flex-start;
  margin-bottom: 0.8rem;
}

@media (max-width: 575px) {
  .product-item-row-ext .product-price-new {
    font-size: 18px;
    font-size: 1.125rem;
  }
}

@media (max-width: 575px) {
  .product-item-row-ext .product-title {
    font-size: 14px;
    font-size: 0.875rem;
  }
}

.product-item-row-ext .product-btn-block {
  flex-wrap: wrap;
  justify-content: flex-start;
}

@media (max-width: 991px) {
  .product-item-row-ext .product-btn-block {
    width: 100%;
    max-width: 100%;
    flex: 0 0 100%;
    justify-content: flex-end;
  }
}

@media (max-width: 575px) {
  .product-item-row-ext .product-btn-block {
    margin-top: 0;
  }
}

.product-item-row-ext .product-btn-block .quantity-group {
  width: auto;
  margin-bottom: 0;
}

.product-item-row-ext .product-btn-block .product-btn {
  margin-left: 0.6rem;
}

.product-item-row-ext .quantity-up,
.product-item-row-ext .quantity-down {
  background-color: #d8ebe1;
}

.product-item-row-ext .quantity-input {
  background-color: transparent;
}

.product-content {
  display: block;
  overflow: hidden;
  border-radius: 10px;
  border: 2px solid #eff6f2;
  background-color: #ffffff;
  padding: 2rem 0.8rem 1.5rem 0.8rem;
}

@media (min-width: 771px) {
  .product-content {
    display: flex;
    padding: 1rem 1.5rem 1rem 1rem;
  }
}

@media (min-width: 1200px) {
  .product-content {
    padding: 1rem 2rem 1rem 1rem;
  }
}

.product-content:hover .product-title {
  color: #81c666;
}

.product-content:hover .product-btn {
  background-color: #64b544;
}

.product-content:hover img {
  transform: scale(1.05, 1.05);
}

.product-image-block {
  overflow: hidden;
  position: relative;
  width: 80px;
  max-width: 80px;
  flex: 0 0 80px;
  float: left;
}

@media (min-width: 576px) {
  .product-image-block {
    width: 100px;
    max-width: 100px;
    flex: 0 0 100px;
  }
}

@media (min-width: 771px) {
  .product-image-block {
    float: none;
    width: 100px;
    max-width: 100px;
    flex: 0 0 100px;
  }
}

@media (min-width: 1200px) {
  .product-image-block {
    width: 130px;
    max-width: 130px;
    flex: 0 0 130px;
  }
}

.product-image-block img {
  transition: transform 400ms ease-out;
}

@media (min-width: 771px) {
  .product-text-block {
    display: flex;
    align-items: center;
    padding-left: 1rem;
    width: calc(100% - 100px);
    max-width: calc(100% - 100px);
    flex: 0 0 calc(100% - 100px);
  }
}

@media (min-width: 1200px) {
  .product-text-block {
    width: calc(100% - 130px);
    max-width: calc(100% - 130px);
    flex: 0 0 calc(100% - 130px);
  }
}

@media (min-width: 1440px) {
  .product-text-block {
    padding-left: 3rem;
  }
}

@media (max-width: 770px) {
  .product-left-panel {
    min-height: 130px;
    margin-bottom: 2rem;
  }
}

@media (min-width: 771px) {
  .product-left-panel {
    width: 40%;
    max-width: 40%;
    flex: 0 0 40%;
  }
}

@media (min-width: 992px) {
  .product-left-panel {
    width: 50%;
    max-width: 50%;
    flex: 0 0 50%;
  }
}

@media (min-width: 1200px) {
  .product-left-panel {
    width: 40%;
    max-width: 40%;
    flex: 0 0 40%;
  }
}

@media (min-width: 1440px) {
  .product-left-panel {
    width: 50%;
    max-width: 50%;
    flex: 0 0 50%;
  }
}

.product-right-panel {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
}

@media (min-width: 771px) {
  .product-right-panel {
    display: block;
    width: 60%;
    max-width: 60%;
    flex: 0 0 60%;
  }
}

@media (min-width: 992px) {
  .product-right-panel {
    width: 50%;
    max-width: 50%;
    flex: 0 0 50%;
  }
}

@media (min-width: 1200px) {
  .product-right-panel {
    width: 60%;
    max-width: 60%;
    flex: 0 0 60%;
  }
}

@media (min-width: 1440px) {
  .product-right-panel {
    width: 50%;
    max-width: 50%;
    flex: 0 0 50%;
  }
}

@media (max-width: 575px) {
  .product-title-block {
    margin-bottom: 1.2rem;
  }
}

@media (min-width: 576px) {
  .product-title-block {
    display: flex;
    align-items: center;
  }
}

.product-title {
  margin-bottom: 0;
}

@media (min-width: 576px) {
  .product-title {
    margin-bottom: 0.3rem;
  }
}

.product-category {
  color: #a1a1a1;
  font-family: Poppins;
  font-size: 15px;
  font-size: 0.9375rem;
  font-weight: 300;
  line-height: 1.4;
  text-transform: uppercase;
  letter-spacing: 1.07px;
}

@media (max-width: 575px) {
  .product-category {
    font-size: 14px;
    font-size: 0.875rem;
    margin-top: 10px;
  }
}

@media (min-width: 576px) {
  .product-category {
    border-left: 2px solid #eff6f2;
    margin-left: 20px;
    padding-left: 20px;
  }
}

.product-price-block {
  display: flex;
  align-items: center;
}

@media (min-width: 771px) {
  .product-price-block {
    justify-content: flex-end;
    margin-bottom: 1.5rem;
  }
}

@media (max-width: 770px) {
  .product-price-block {
    width: calc(100% - 180px);
    max-width: calc(100% - 180px);
    flex: 0 0 calc(100% - 180px);
    margin-top: 0.5rem;
  }
}

@media (max-width: 575px) {
  .product-price-block {
    width: 100%;
    max-width: 100%;
    flex: 0 0 100%;
    margin-top: 0.5rem;
    justify-content: center;
  }
}

.product-btn-block {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

@media (min-width: 993px) and (max-width: 1200px) {
  .product-btn-block {
    flex-wrap: wrap;
  }
  .product-btn-block .quantity-group {
    width: 100%;
    justify-content: flex-end;
    margin-bottom: 0.8rem;
  }
  .product-btn-block .product-btn {
    margin-left: 0;
  }
}

@media (max-width: 770px) {
  .product-btn-block {
    width: 180px;
    max-width: 180px;
    flex: 0 0 180px;
    flex-wrap: wrap;
  }
  .product-btn-block .quantity-group {
    width: 100%;
    justify-content: flex-end;
    margin-bottom: 1rem;
  }
  .product-btn-block .product-btn {
    margin-left: 0;
  }
}

@media (max-width: 575px) {
  .product-btn-block {
    width: 100%;
    max-width: 100%;
    flex: 0 0 100%;
    margin-top: 1.5rem;
    justify-content: center;
  }
  .product-btn-block .quantity-group {
    justify-content: center;
  }
}

.product-btn {
  margin-left: 1rem;
  padding: 0.7em 0.8rem;
}

@media (min-width: 576px) {
  .product-btn {
    margin-left: 1rem;
    padding: 0.7em 0.8rem;
  }
}

@media (min-width: 992px) {
  .product-btn {
    margin-left: 1rem;
    padding: 0.7em 1.2rem;
  }
}

@media (min-width: 1440px) {
  .product-btn {
    margin-left: 2rem;
  }
}

.product-price {
  margin-right: 1rem;
}

.product-price:last-child {
  margin-right: 0;
}

@media (min-width: 771px) {
  .product-price {
    margin-right: 1rem;
  }
}

@media (min-width: 992px) {
  .product-price {
    margin-right: 1rem;
  }
}

@media (min-width: 1200px) {
  .product-price {
    margin-right: 1.5rem;
  }
}

.product-price-old {
  margin-left: 0;
  color: #a1a1a1;
  font-size: 11px;
  font-size: 0.6875rem;
  font-weight: 600;
  line-height: 1.2;
  text-decoration: line-through;
  letter-spacing: 0.63px;
}

.product-price-new {
  color: #81c666;
  font-size: 20px;
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 1.2;
}

.product-price-discount {
  padding: 0.2rem 0.6rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 9px;
  background-color: #f5c360;
  color: #ffffff;
  font-size: 11px;
  font-size: 0.6875rem;
  font-weight: 600;
  line-height: 1.2;
  text-transform: uppercase;
  letter-spacing: 0.63px;
  height: 20px;
}

.detail-slider {
  margin-bottom: 2rem;
}

.detail-slider-thumbnail .detail-slider-image {
  border: 2px solid #ddebe4;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 0.5rem;
}

.detail-slider-thumbnail .slick-current .detail-slider-image {
  border-color: #81c666;
}

.offer-item:nth-child(odd) .offer-content {
  background-color: #edf5f1;
}

.offer-item:nth-child(odd) .quantity-down:not(:hover),
.offer-item:nth-child(odd) .quantity-up:not(:hover) {
  background-color: #d8ebe1;
}

.offer-content {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  border-radius: 6px;
  padding: 1.2rem 1rem;
  min-height: 60px;
}

@media (min-width: 576px) {
  .offer-content {
    padding: 0.8rem 1.5rem;
  }
}

@media (min-width: 993px) and (max-width: 1200px) {
  .offer-content {
    padding: 0.8rem 1.5rem;
  }
}

.offer-content .quantity-group {
  justify-content: center;
  width: 100%;
  max-width: 100%;
  flex: 0 0 100%;
}

@media (min-width: 576px) {
  .offer-content .quantity-group {
    justify-content: flex-end;
    width: 50%;
    max-width: 50%;
    flex: 0 0 50%;
  }
}

@media (min-width: 992px) {
  .offer-content .quantity-group {
    width: 100%;
    max-width: 100%;
    flex: 0 0 100%;
  }
}

@media (min-width: 1200px) {
  .offer-content .quantity-group {
    width: 50%;
    max-width: 50%;
    flex: 0 0 50%;
  }
}

@media (min-width: 993px) and (max-width: 1200px) {
  .offer-content .quantity-group {
    justify-content: center;
  }
}

.offer-title {
  margin-bottom: 0;
  width: 100%;
  max-width: 100%;
  flex: 0 0 100%;
  margin-bottom: 1rem;
  text-align: center;
}

@media (min-width: 576px) {
  .offer-title {
    text-align: left;
    padding-right: 1.5rem;
    width: 50%;
    max-width: 50%;
    flex: 0 0 50%;
    margin-bottom: 0;
  }
}

@media (min-width: 992px) {
  .offer-title {
    width: 100%;
    max-width: 100%;
    flex: 0 0 100%;
    margin-bottom: 1rem;
  }
}

@media (min-width: 1200px) {
  .offer-title {
    width: 50%;
    max-width: 50%;
    flex: 0 0 50%;
    margin-bottom: 0;
  }
}

@media (min-width: 993px) and (max-width: 1200px) {
  .offer-title {
    padding-right: 0;
    text-align: center;
  }
}

@media (min-width: 577px) and (max-width: 771px) {
  .detail-panel-left,
  .detail-panel-right {
    width: 50%;
    max-width: 50%;
    flex: 0 0 50%;
  }
}

@media (min-width: 1440px) {
  .detail-panel-left,
  .detail-panel-right {
    width: 50%;
    max-width: 50%;
    flex: 0 0 50%;
  }
}

.section-login {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  height: 100%;
}

.login-header {
  background-color: #81c666;
  padding-bottom: 1.5rem;
}

@media (min-width: 576px) {
  .login-header {
    padding: 1.7rem 3rem 1.7rem 0;
  }
}

.login-content {
  background-color: #ffffff;
  padding: 3rem 1.2rem 2rem;
}

@media (min-width: 576px) {
  .login-content {
    padding: 4rem 2rem 3rem;
  }
}
