/* Footer */

.footer{
	position: relative;
	z-index: 2;

	background-color: $color-green-normal;

	-webkit-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    overflow: hidden;
	padding: 0.9375rem 0.9375rem 0.9375rem;
	// padding-top: 0.9375rem;
	@include bp(medium){
		padding: 1.25rem 0 1.25rem;
	}
}

.footer-copy,
.footer-author{
	display: block;
	color: $color-gray-normal-1;
	@include font-size(11px);
	font-weight: 600;
	text-transform: uppercase;
	letter-spacing: 1.82px;
	line-height: 1.8;
}

.footer-link{
	color: $color-green-normal;
	&:hover{
		text-decoration: underline;
	}
}

.footer-panel-left{
	@include bp(medium){
		width: 320px;
		max-width: 320px;
		flex: 0 0 320px;
	}
}

.footer-panel-right{
	@include bp(medium){
		width: calc(100% - 320px);
		max-width: calc(100% - 320px);
		flex: 0 0 calc(100% - 320px);
	}
}