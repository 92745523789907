.detail-slider{
	margin-bottom: 2rem;
	.js-slide-item{

	}
}

.detail-slider-thumbnail{
	.detail-slider-image{
		border: 2px solid #ddebe4;
		height: 80px;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 0.5rem 0.5rem;
	}
	.slick-current{
		.detail-slider-image{
			border-color: $color-green-normal;
		}
	}
}

.offer-item{
	&:nth-child(odd){
		.offer-content{
			background-color: $color-gray-light;
		}
		.quantity-down:not(:hover),
		.quantity-up:not(:hover){
			background-color: #d8ebe1;
		}
	}
}

.offer-content{
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: space-between;
	border-radius: 6px;
	padding: 1.2rem 1rem;
	min-height: 60px;
	@include bp(xsmall){
		padding: 0.8rem 1.5rem;
	}
	@include bp(excl-large){
		padding: 0.8rem 1.5rem;
	}
	.quantity-group{
		justify-content: center;
		width: 100%;
		max-width: 100%;
		flex: 0 0 100%;
		@include bp(xsmall){
			justify-content: flex-end;
			width: 50%;
			max-width: 50%;
			flex: 0 0 50%;
		}
		@include bp(medium){
			width: 100%;
			max-width: 100%;
			flex: 0 0 100%;
		}
		@include bp(large){
			width: 50%;
			max-width: 50%;
			flex: 0 0 50%;
		}
		@include bp(excl-large){
			justify-content: center;
		}
	}
}

.offer-title{
	margin-bottom: 0;
	width: 100%;
	max-width: 100%;
	flex: 0 0 100%;
	margin-bottom: 1rem;
	text-align: center;
	@include bp(xsmall){
		text-align: left;
		padding-right: 1.5rem;
		width: 50%;
		max-width: 50%;
		flex: 0 0 50%;
		margin-bottom: 0;
	}
	@include bp(medium){
		width: 100%;
		max-width: 100%;
		flex: 0 0 100%;
		margin-bottom: 1rem;
	}
	@include bp(large){
		width: 50%;
		max-width: 50%;
		flex: 0 0 50%;
		margin-bottom: 0;
	}
	@include bp(excl-large){
		padding-right: 0;
		text-align: center;
	}
}

.detail-panel-left,
.detail-panel-right{
	@include bp(excl-small){
		width: 50%;
		max-width: 50%;
		flex: 0 0 50%;
	}
	@include bp(xlarge){
		width: 50%;
		max-width: 50%;
		flex: 0 0 50%;
	}
}

// .detail-panel-left{
// 	margin-bottom: 2rem;
// 	@include bp(xlarge){
// 		margin-bottom: 0;
// 	}
// }