/* Image helpers */

.img {
    display: block;   
}

.img-full{
    width: 100%;
}

.img-responsive{
	display: block;
	max-width: 100%;
	height: auto;
}

.img-cover{
	height: 100%;
	object-fit: cover;
}

.img-contain{
	height: 100%;
	object-fit: contain;
}

.img-hover{
	display: block;
	overflow: hidden;
	&:hover{
		> img{
			transform: scale(1.05, 1.05);
		}
	}
	img{
		transition: transform 400ms ease-out;
	}
}

.bg-image-hover{
	display: block;
	overflow: hidden;
	&:hover{
		> .bg-image{
			transform: scale(1.05, 1.05);
		}
	}
	.bg-image{
		transition: transform 400ms ease-out;
	}
}

.img-flex-cover{
	display: flex;
	img{
		display: block;
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
}

.img-flex-contain{
	display: flex;
	img{
		display: block;
		width: 100%;
		height: 100%;
		object-fit: contain;
	}
}

.img-flex-category-small{
	height: 60px;
}

.img-flex-category-big{
	height: 100px;
}

.img-flex-product-small{
	height: 75px;
}

.img-flex-product-normal-ext{
	height: 110px;
}

.img-flex-product-normal{
	height: 130px;
}

.img-flex-detail-gallery-main{
	height: 350px;
}

.img-flex-detail-gallery-thumbnail{
	height: 55px;
}

.bg-image{
	display: block;
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
}

.bg-rectangle{
	display: block;
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
}

.bg-image-logo-symbol{
	top: 1rem;
	left: 50%;
	transform: translateX(-50%);
    width: 311px;
    height: 217px;
	@include bp(xsmall){
		top: 1rem;
		width: 571px;
		height: 397px;
	}
	@include bp(small){
		top: 3rem;
		width: 791px;
		height: 547px;
	}
	@include bp(medium){
		width: 981px;
		height: 677px;
	}
	@include bp(large){
		width: 1321px;
		height: 937px;
	}
}