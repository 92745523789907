/* Social block */

.social-list{
	// margin-left: 2rem;
	@include bp(xxlarge){
		margin-left: 2rem;
	}
}

.social-list-item{
	display: inline-block;
	vertical-align: middle;
	margin-top: 10px;
	margin-bottom: 10px;
	margin-left: 8px;
	@include bp(medium){
		margin-left: 15px;
	}
	&:first-child{
		margin-left: 0;
	}
}

.social-list-link{
	display: inline-flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	width: 35px;
	height: 35px;
	text-align: center;
	vertical-align: middle;
	border-radius: 100%;
	// color: #ffffff;
	// @include font-size(18px);
	svg{
		fill: #ffffff;
		@include font-size(18px);
	}
}

.social-list-link-mail{
	background-color: $color-green-normal;
	&:hover{
		background-color: $color-green-dark;
		// svg{
		// }
	}
}

.social-list-link-facebook{
	background-color: #5279bf;
	&:hover{
		background-color: darken(#5279bf,10%);
		// svg{
		// }
	}
}

.social-list-link-twitter{
	background-color: #74ccf3;
	&:hover{
		background-color: darken(#74ccf3,10%);
		// svg{
		// }
	}
}