/* Placeholder helpers */

input::-webkit-input-placeholder,
select::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
    color: #99a19d;
}

input::-moz-input-placeholder,
select::-moz-input-placeholder,
textarea::-moz-input-placeholder {
    color: #99a19d;
}

input:-ms-input-placeholder,
select:-ms-input-placeholder,
textarea:-ms-input-placeholder {
    color: #99a19d;
}

.input-uppercase input::-webkit-input-placeholder,
.input-uppercase select::-webkit-input-placeholder,
.input-uppercase textarea::-webkit-input-placeholder {
    text-transform: uppercase;
}

.input-uppercase input::-moz-input-placeholder,
.input-uppercase select::-moz-input-placeholder,
.input-uppercase textarea::-moz-input-placeholder {
    text-transform: uppercase;
}

.input-uppercase input:-ms-input-placeholder,
.input-uppercase select:-ms-input-placeholder,
.input-uppercase textarea:-ms-input-placeholder {
    text-transform: uppercase;
}

.input-white input::-webkit-input-placeholder,
.input-white select::-webkit-input-placeholder,
.input-white textarea::-webkit-input-placeholder {
    color: #ffffff;
}

.input-white input::-moz-input-placeholder,
.input-white select::-moz-input-placeholder,
.input-white textarea::-moz-input-placeholder {
    color: #ffffff;
}

.input-white input:-ms-input-placeholder,
.input-white select:-ms-input-placeholder,
.input-white textarea:-ms-input-placeholder {
    color: #ffffff;
}


.input-black input::-webkit-input-placeholder,
.input-black select::-webkit-input-placeholder,
.input-black textarea::-webkit-input-placeholder {
    color: $color-black-normal;
}

.input-black input::-moz-input-placeholder,
.input-black select::-moz-input-placeholder,
.input-black textarea::-moz-input-placeholder {
    color: $color-black-normal;
}

.input-black input:-ms-input-placeholder,
.input-black select:-ms-input-placeholder,
.input-black textarea:-ms-input-placeholder {
    color: $color-black-normal;
}


input:focus::-webkit-input-placeholder,
textarea:focus::-webkit-input-placeholder{
    color: transparent;
    opacity: 1;
}

input:focus::-moz-input-placeholder,
textarea:focus::-moz-input-placeholder{
    color: transparent;
    opacity: 1;
}

input:focus:-moz-input-placeholder,
textarea:focus:-moz-input-placeholder{
    color: transparent;
    opacity: 1;
}