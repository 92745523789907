/* Buttons helper */

.btn {
	display: inline-block;
	vertical-align: middle;
	position: relative;
	margin: 0;
	padding: 0.7em 1.2rem;
	font-weight: 600;
	line-height: 1.2;
	cursor: pointer;
	text-decoration: none;
	text-transform: uppercase;
	border: 0;
	outline: none !important;
	background: $color-black-normal;
	min-height: 35px;
	text-align: center;

	@include transition(background-color, 250ms, ease);
	@include border-radius(17px);
	@include font-size(12px);
	-webkit-appearance: none;
	&,
	&:visited,
	&:active,
	&:focus {
		color: white;
	}

	&:hover {
		@include transition(background-color, 250ms, ease);
		@include bp(medium) {
			cursor: pointer;
			text-decoration: none;
			color: white;
		}
	}
}

.btn-responsive {
	width: 100%;
	padding-right: 0;
	padding-left: 0;
	text-align: center;
}

.btn-flex{
	display: inline-flex;
	align-items: center;
}

.btn-icon{
	display: inline-block;
	margin-right: 0.8rem;
	height: 16px;
	svg{
		fill: #ffffff;
		@include font-size(16px);
	}
}

.btn-icon-left{
	margin-right: 1.5rem;
	svg{
		@include font-size(8px);
	}
}

.btn-icon-right{
	margin-right: 0;
	margin-left: 1.5rem;
	svg{
		@include font-size(8px);
	}
}

.btn-size-small {
	@include font-size(12px);
}

.btn-size-normal {
	@include font-size(14px);
}

.btn-size-big {
	@include font-size(18px);
}

.btn-size-large {
	@include font-size(20px);
}

.btn-small {
	padding: 0.6em 1rem;
}

.btn-normal {
	padding: 1.5em 1.5rem;
}

.btn-big {
	padding: 2em 2rem;
}

.btn-min-width-small {
	min-width: 120px;
}

.btn-min-width-normal {
	min-width: 250px;
}

.btn-min-width-big {
	min-width: 300px;
}

@include bp(rev-xsmall){
	.btn-xs-min-width-normal {
		min-width: 250px;
	}

	.btn-xs-responsive {
		width: 100%;
		padding-right: 0;
		padding-left: 0;
		text-align: center;
		justify-content: center;
	}
}

.btn-black-fill {
	color: #ffffff;
	border: 2px solid transparent;
	background-color: #000000;
	&,
	&:visited,
	&:active,
	&:focus {
		color: #ffffff;
	}
	&:hover {
		color: #000000;
		background-color: #ffffff;
		.btn-icon {
			color: #000000;
		}
	}
	.btn-icon {
		color: #ffffff;
	}
}

.btn-white-fill {
	color: $color-green-normal;
	// border: 2px solid #ffffff;
	background-color: #ffffff;
	&,
	&:visited,
	&:active,
	&:focus {
		color: $color-green-normal;
	}
	&:hover {
		color: #ffffff;
		// border: 2px solid $color-green-normal;
		background-color: $color-green-normal;
	}
	
}

.btn-green-fill {
	color: #ffffff;
	// border: 2px solid transparent;
	background-color: $color-green-normal;
	&,
	&:visited,
	&:active,
	&:focus {
		color: #ffffff;
	}
	&:hover {
		color: #ffffff;
		background-color: darken($color-green-normal, 10%);
	}
}

.btn-gray-fill {
	color: $color-green-normal;
	// border: 2px solid $color-gray-light;
	background-color: $color-gray-light;
	&,
	&:visited,
	&:active,
	&:focus {
		color: $color-green-normal;
	}
	.btn-icon{
		svg{
			fill: $color-green-normal;
		}
	}
	&:hover {
		color: $color-green-normal;
		// border: 2px solid darken($color-gray-light, 5%);
		background-color: darken($color-gray-light, 5%);
	}
}

.btn-black-outline {
	color: #000000;
	border: 2px solid #000000;
	background-color: transparent;
	&,
	&:visited,
	&:active,
	&:focus {
		color: #000000;
	}
	&:hover {
		color: #ffffff;
		border: 2px solid #000000;
		background-color: #000000;
		.btn-icon {
			color: #ffffff;
		}
	}
	.btn-icon {
		color: #000000;
	}
}
