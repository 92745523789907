/* Base settings */
*, *::before, *::after{
	box-sizing: border-box;
}

*{
	transition: color .15s, background .15s, border .15s, opacity .15s;
}

*:after, *:before{
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	outline: none;
}

::-moz-selection{
	color: #fff;
	background-color: $color-green-normal;
}

::selection{
	color: #fff;
	background-color: $color-green-normal;
}

::-webkit-scrollbar {
	width: 6px;
	height: 6px;
}

::-webkit-scrollbar-track {
	border-radius: 0;
	background: $color-gray-light; 
}
 
::-webkit-scrollbar-thumb {
	border-radius: 0;
	background: $color-green-normal;
}

::-webkit-scrollbar-thumb:hover {
	background: darken($color-green-normal,5%);
}

@-ms-viewport{
	width: device-width;
}

article, aside, details, figcaption, figure, footer, header, hgroup, main, menu, nav, section, summary{
	display: block;
}

html{
	position: relative;
	box-sizing: border-box;
	height: 100%;
	font-size: 100%;
}

body{
	position: relative;
	display: flex;
	flex-direction: column;
	min-width: 310px;
	height: 100%;
	margin: 0;
	color: #000;
	background-color: $color-green-normal;
	font-family: $font-primary;
	font-weight: 400;
	line-height: 1;
}

.body-gray{
	background-color: $color-gray-light;
}

a{
	cursor: pointer;
	text-decoration: none;
	color: inherit;
	outline: none;
	font-family: $font-primary;
}

a:focus, a:active, a:visited{
	outline: none;
}

.a-hover{
	&:hover, &:focus{
		text-decoration: underline;
	}
}


.a-link{
	display: inline-flex;
	align-items: center;
	cursor: pointer;
	padding: 0.6rem 0;
}

.a-link-hover{
	&:hover{
		.a-link-text{
			text-decoration: underline;
		}
	}
}

.a-link-center{
	justify-content: center;
}

.a-link-text{
	color: $color-green-normal;
	font-family: $font-primary;
	font-weight: 400;
	letter-spacing: 0.79px;
	text-transform: uppercase;
	@include font-size(12px);
}

.a-link-icon{
	display: inline-block;
	margin-left: 5px;
	vertical-align: middle;
	color: $color-green-normal;
	@include font-size(24px);
}

.a-link-underline{
	text-decoration: underline;
}

p{
	display: block;
	margin-top: 0;
	margin-bottom: 2.5em;
	color: $color-black-normal;
	font-family: $font-primary;
	font-weight: 400;
	line-height: 1.6;
	letter-spacing: 0.55px;

	@include font-size(14px);
}

ol, ul{
	margin: 0;
	padding: 0;
	list-style: none;
}

ul.list-style, ol.list-style{
	margin-bottom: 2em;

	li{
		position: relative;
		display: block;
		margin-bottom: 15px;
		padding-left: 32px;
		color: $color-black-normal;
		font-family: $font-primary;
		font-weight: 400;
		line-height: 1.6;
		letter-spacing: 0.55px;
		@include font-size(14px);
	}
	a{
		color: $color-green-normal;

		&:hover{
			text-decoration: underline;
		}
	}
}

ul.list-style{
	li{
		&:before{
			content: "";
			position: absolute;
			display: block;
		}
	}
}

ul.list-style-check{
	li{
		&:before{
			top: 7px;
			left: 3px;
			width: 16px;
			height: 12px;
			background-position: center;
			background-size: cover;
			background-image: url('../images/icons/check.svg');
		}
	}
}

ul.list-style-chevron{
	li{
		&:before{
			top: 7px;
			left: 3px;
			width: 4px;
			height: 7px;
			background-position: center;
			background-size: cover;
			background-image: url('../images/icons/chevron-right.svg');
		}
	}
}

ol.list-style{
	list-style-position: inside;
	counter-reset: item;
	li{
		&:before{
			position: absolute;
			top: 0;
			left: 0;
			display: inline-block;
			content: counter(item)'.';
			counter-increment: item;
			color: $color-black-normal;
			line-height: 1.4;
			@include font-size(16px);
		}
	}
}

em, i, .italic{
	font-style: italic;
}

strong, .strong{
	font-weight: 700;
}

small, .small{
	@include font-size(12px);
}

h1, .alfa, h2, .beta, h3, .gamma, h4, .delta, h5, .epsilon, h6, .zeta{
	margin: 0;
	margin-bottom: 1.5rem;
	color: $color-black-normal;
	font-family: $font-primary;
	font-weight: 500;
	line-height: 1.2;
	a{
		color: inherit;
	}

}

h1, .alfa{
	@include font-size(28px);
	@include bp(xsmall){
		@include font-size(30px);
	}
	@include bp(small){
		@include font-size(30px);
	}

}

.alfa-ext{
	@include font-size(22px);
	@include bp(xssmall){
		@include font-size(24px);
	}
	@include bp(xsmall){
		@include font-size(27px);
	}
}

h2, .beta{
	@include font-size(20px);
	@include bp(xsmall){
		@include font-size(20px);
	}
	@include bp(small){
		@include font-size(20px);
	}

}

.beta-ext{
	color: $color-gray-normal;
	font-weight: 300;
	text-transform: uppercase;
	letter-spacing: 1.43px;
}

h3, .gamma{
	@include font-size(15px);
	@include bp(xsmall){
		@include font-size(15px);
	}
	@include bp(small){
		@include font-size(15px);
	}

}

h4, .delta{
	@include font-size(26px);
	@include bp(xsmall){
		@include font-size(28px);
	}
	@include bp(small){
		@include font-size(30px);
	}

}

h5, .epsilon{
	@include font-size(24px);
	@include bp(xsmall){
		@include font-size(26px);
	}
	@include bp(small){
		@include font-size(28px);
	}

}

h6, .zeta{
	@include font-size(22px);
	@include bp(xsmall){
		@include font-size(24px);
	}
	@include bp(small){
		@include font-size(26px);
	}

}

.h-divider{
	h1, .alfa,
	h2, .beta,
	h3, .gamma,
	h4, .delta,
	h5, .epsilon,
	h6, .zeta{
		position: relative;
		&:after{
			content: '';
			// position: absolute;
			// bottom: 0;
			// left: 0;
			display: block;
			width: 30px;
			height: 2px;
			background-color: #ddebe4;
			margin-top: 15px;
		}
	}
}