/* Gutter helpers */

.no-gutter{
	margin: 0;
}

.no-gutter > [class*="col-"]{
	padding-right: 0;
	padding-left: 0;
}

.gutter-10.row{
	margin-right: -5px;
	margin-left: -5px;
}
.gutter-10 > [class*="col-"],
.gutter-10 > [class*=" col-"]{
	padding-right: 5px;
	padding-left: 5px;
}


.gutter-20.row{
	margin-right: -10px;
	margin-left: -10px;
}
.gutter-20 > [class*="col-"],
.gutter-20 > [class*=" col-"]{
	padding-right: 10px;
	padding-left: 10px;
}

.gutter-24.row{
	margin-right: -12px;
	margin-left: -12px;
}
.gutter-24 > [class*="col-"],
.gutter-24 > [class*=" col-"]{
	padding-right: 12px;
	padding-left: 12px;
}


.gutter-30.row{
	margin-right: -15px;
	margin-left: -15px;
}
.gutter-30 > [class*="col-"],
.gutter-30 > [class*=" col-"]{
	padding-right: 15px;
	padding-left: 15px;
}


.gutter-40.row{
	margin-right: -20px;
	margin-left: -20px;
}
.gutter-40 > [class*="col-"],
.gutter-40 > [class*=" col-"]{
	padding-right: 20px;
	padding-left: 20px;
}

.gutter-50.row{
	margin-right: -25px;
	margin-left: -25px;
}
.gutter-50 > [class*="col-"],
.gutter-50 > [class*=" col-"]{
	padding-right: 25px;
	padding-left: 25px;
}

.gutter-60.row{
		margin-right: -30px;
		margin-left: -30px;
	}
	.gutter-60 > [class*="col-"],
	.gutter-60 > [class*=" col-"]{
		padding-right: 30px;
		padding-left: 30px;
	}


@include bp(xsmall){
	.gutter-xs-10.row{
		margin-right: -5px;
		margin-left: -5px;
	}
	.gutter-xs-10 > [class*="col-"],
	.gutter-xs-10 > [class*=" col-"]{
		padding-right: 5px;
		padding-left: 5px;
	}


	.gutter-xs-20.row{
		margin-right: -10px;
		margin-left: -10px;
	}
	.gutter-xs-20 > [class*="col-"],
	.gutter-xs-20 > [class*=" col-"]{
		padding-right: 10px;
		padding-left: 10px;
	}


	.gutter-xs-30.row{
		margin-right: -15px;
		margin-left: -15px;
	}
	.gutter-xs-30 > [class*="col-"],
	.gutter-xs-30 > [class*=" col-"]{
		padding-right: 15px;
		padding-left: 15px;
	}


	.gutter-xs-40.row{
		margin-right: -20px;
		margin-left: -20px;
	}
	.gutter-xs-40 > [class*="col-"],
	.gutter-xs-40 > [class*=" col-"]{
		padding-right: 20px;
		padding-left: 20px;
	}

	.gutter-xs-50.row{
		margin-right: -25px;
		margin-left: -25px;
	}
	.gutter-xs-50 > [class*="col-"],
	.gutter-xs-50 > [class*=" col-"]{
		padding-right: 25px;
		padding-left: 25px;
	}

	.gutter-xs-60.row{
		margin-right: -30px;
		margin-left: -30px;
	}
	.gutter-xs-60 > [class*="col-"],
	.gutter-xs-60 > [class*=" col-"]{
		padding-right: 30px;
		padding-left: 30px;
	}

	.gutter-xs-100.row{
		margin-right: -50px;
		margin-left: -50px;
	}
	.gutter-xs-100 > [class*="col-"],
	.gutter-xs-100 > [class*=" col-"]{
		padding-right: 50px;
		padding-left: 50px;
	}
}

@include bp(small){
	.gutter-sm-10.row{
		margin-right: -5px;
		margin-left: -5px;
	}
	.gutter-sm-10 > [class*="col-"],
	.gutter-sm-10 > [class*=" col-"]{
		padding-right: 5px;
		padding-left: 5px;
	}


	.gutter-sm-20.row{
		margin-right: -10px;
		margin-left: -10px;
	}
	.gutter-sm-20 > [class*="col-"],
	.gutter-sm-20 > [class*=" col-"]{
		padding-right: 10px;
		padding-left: 10px;
	}


	.gutter-sm-30.row{
		margin-right: -15px;
		margin-left: -15px;
	}
	.gutter-sm-30 > [class*="col-"],
	.gutter-sm-30 > [class*=" col-"]{
		padding-right: 15px;
		padding-left: 15px;
	}


	.gutter-sm-40.row{
		margin-right: -20px;
		margin-left: -20px;
	}
	.gutter-sm-40 > [class*="col-"],
	.gutter-sm-40 > [class*=" col-"]{
		padding-right: 20px;
		padding-left: 20px;
	}

	.gutter-sm-50.row{
		margin-right: -25px;
		margin-left: -25px;
	}
	.gutter-sm-50 > [class*="col-"],
	.gutter-sm-50 > [class*=" col-"]{
		padding-right: 25px;
		padding-left: 25px;
	}

	.gutter-sm-60.row{
		margin-right: -30px;
		margin-left: -30px;
	}
	.gutter-sm-60 > [class*="col-"],
	.gutter-sm-60 > [class*=" col-"]{
		padding-right: 30px;
		padding-left: 30px;
	}

	.gutter-sm-100.row{
		margin-right: -50px;
		margin-left: -50px;
	}
	.gutter-sm-100 > [class*="col-"],
	.gutter-sm-100 > [class*=" col-"]{
		padding-right: 50px;
		padding-left: 50px;
	}
}


@include bp(medium){
	.gutter-md-10.row{
		margin-right: -5px;
		margin-left: -5px;
	}
	.gutter-md-10 > [class*="col-"],
	.gutter-md-10 > [class*=" col-"]{
		padding-right: 5px;
		padding-left: 5px;
	}


	.gutter-md-20.row{
		margin-right: -10px;
		margin-left: -10px;
	}
	.gutter-md-20 > [class*="col-"],
	.gutter-md-20 > [class*=" col-"]{
		padding-right: 10px;
		padding-left: 10px;
	}

	.gutter-md-24.row{
		margin-right: -12px;
		margin-left: -12px;
	}
	.gutter-md-24 > [class*="col-"],
	.gutter-md-24 > [class*=" col-"]{
		padding-right: 12px;
		padding-left: 12px;
	}


	.gutter-md-30.row{
		margin-right: -15px;
		margin-left: -15px;
	}
	.gutter-md-30 > [class*="col-"],
	.gutter-md-30 > [class*=" col-"]{
		padding-right: 15px;
		padding-left: 15px;
	}


	.gutter-md-40.row{
		margin-right: -20px;
		margin-left: -20px;
	}
	.gutter-md-40 > [class*="col-"],
	.gutter-md-40 > [class*=" col-"]{
		padding-right: 20px;
		padding-left: 20px;
	}

	.gutter-md-50.row{
		margin-right: -25px;
		margin-left: -25px;
	}
	.gutter-md-50 > [class*="col-"],
	.gutter-md-50 > [class*=" col-"]{
		padding-right: 25px;
		padding-left: 25px;
	}

	.gutter-md-60.row{
		margin-right: -30px;
		margin-left: -30px;
	}
	.gutter-md-60 > [class*="col-"],
	.gutter-md-60 > [class*=" col-"]{
		padding-right: 30px;
		padding-left: 30px;
	}

	.gutter-md-100.row{
		margin-right: -50px;
		margin-left: -50px;
	}
	.gutter-md-100 > [class*="col-"],
	.gutter-md-100 > [class*=" col-"]{
		padding-right: 50px;
		padding-left: 50px;
	}
}

@include bp(large){
	.gutter-lg-10.row{
		margin-right: -5px;
		margin-left: -5px;
	}
	.gutter-lg-10 > [class*="col-"],
	.gutter-lg-10 > [class*=" col-"]{
		padding-right: 5px;
		padding-left: 5px;
	}


	.gutter-lg-20.row{
		margin-right: -10px;
		margin-left: -10px;
	}
	.gutter-lg-20 > [class*="col-"],
	.gutter-lg-20 > [class*=" col-"]{
		padding-right: 10px;
		padding-left: 10px;
	}


	.gutter-lg-30.row{
		margin-right: -15px;
		margin-left: -15px;
	}
	.gutter-lg-30 > [class*="col-"],
	.gutter-lg-30 > [class*=" col-"]{
		padding-right: 15px;
		padding-left: 15px;
	}


	.gutter-lg-40.row{
		margin-right: -20px;
		margin-left: -20px;
	}
	.gutter-lg-40 > [class*="col-"],
	.gutter-lg-40 > [class*=" col-"]{
		padding-right: 20px;
		padding-left: 20px;
	}

	.gutter-lg-50.row{
		margin-right: -25px;
		margin-left: -25px;
	}
	.gutter-lg-50 > [class*="col-"],
	.gutter-lg-50 > [class*=" col-"]{
		padding-right: 25px;
		padding-left: 25px;
	}

	.gutter-lg-60.row{
		margin-right: -30px;
		margin-left: -30px;
	}
	.gutter-lg-60 > [class*="col-"],
	.gutter-lg-60 > [class*=" col-"]{
		padding-right: 30px;
		padding-left: 30px;
	}

	.gutter-lg-100.row{
		margin-right: -50px;
		margin-left: -50px;
	}
	.gutter-lg-100 > [class*="col-"],
	.gutter-lg-100 > [class*=" col-"]{
		padding-right: 50px;
		padding-left: 50px;
	}
}

@include bp(xlarge){
	

	.gutter-xl-60.row{
		margin-right: -30px;
		margin-left: -30px;
	}
	.gutter-xl-60 > [class*="col-"],
	.gutter-xl-60 > [class*=" col-"]{
		padding-right: 30px;
		padding-left: 30px;
	}

	.gutter-xl-140.row{
		margin-right: -70px;
		margin-left: -70px;
	}
	.gutter-xl-140 > [class*="col-"],
	.gutter-xl-140 > [class*=" col-"]{
		padding-right: 70px;
		padding-left: 70px;
	}


}