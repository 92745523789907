/* Border helpers */

.border-0 {
    border: 0!important;
}

.border-0-left {
    border-left: 0!important;
}

.border-0-right {
    border-right: 0!important;
}

.border-0-bottom {
    border-bottom: 0!important;
}

.border-0-top {
    border-top: 0!important;
}

.border-radius-left{
	border-radius: 10px 0 0 10px;
}

@include bp(medium){
	.border-radius-md-left{
		border-radius: 10px 0 0 10px;
	}
}

@include bp(rev-medium){
	.border-md-radius{
		border-radius: 10px;
	}
}