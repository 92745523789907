/* Modal block */

.modal-overlay{
	position: fixed;
	z-index: 9999;
	top: 0;
	left: 0;
	display: none;
	width: 100%;
	height: 100%;
	background-color: rgba(129, 198, 102, 0.5);
	&.is-active{
		display: block;
	}
}

.modal-visible{
    .modal-block{
        position: relative;
        overflow: visible;
        padding: 200px 0 200px;

        height: auto;
        width: 100%;
        opacity: 1;
        visibility: visible;
    }
    .modal-box .modal-close{
        // right: 0;
        // display: none;
    }
}

.modal-block{
	position: fixed;
	z-index: 999999;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	visibility: hidden;
	overflow: hidden;
	width: auto;
	height: 0;
	padding: 3rem 0 2rem;
	opacity: 0;
	-webkit-overflow-scrolling: touch;
	display: flex;
    justify-content: center;
	@include transition(opacity, 400ms, ease);
	@include bp(small){
		padding: 6rem 0 4rem;
	}
	&.is-active{
		visibility: visible;
		overflow-x: hidden;
		overflow-y: auto;
		width: 100%;
		height: 100%;
		opacity: 1;

		@include transition(opacity, 400ms, ease);
		.modal-box{
			position: relative;
			top: auto;
			left: auto;

			@include transform(translate(0, 0));
		}
	}
	&.modal-hidden{
		.modal-close,
		.modal-scroll{
			display: none;
		}
	}
}

.modal-scroll{
	width: 100%;
	margin: auto 1rem;
}

.modal-box-840{
	margin: 0 auto;
	padding: 0;
	width: 100%;

	@include bp(medium){
		width: 840px;
		// width: 1070px;
	}
}

.modal-close{
	position: absolute;
	z-index: 99;
	top: 0;
	right: 0;
	display: flex;
	width: 50px;
	height: 50px;
	line-height: 50px;
	cursor: pointer;
	text-align: center;
	// color: $color-black-normal;
	// background: white;
	align-items: center;
	justify-content: center;
	svg{
		fill: $color-green-normal;
		@include font-size(20px);
	}
	&:hover{
		svg{
			fill: $color-green-dark;
		}
	}
}

.modal-body{
	box-shadow: 0 3px 14px rgba(0, 24, 12, 0.07);
	border-radius: 10px;
	background-color: #ffffff;
	overflow: hidden;
}

.modal-header{
	padding: 3.5rem 1.2rem 2.5rem;
	@include bp(medium){
		padding: 3.5rem 3.6rem 2.5rem;
	}
}

.modal-footer{
	background-color: $color-gray-light;
	padding: 2rem 1.2rem 3rem;
	@include bp(medium){
		padding: 2rem 3.6rem 3rem;
	}
}

