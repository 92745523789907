/* Table block */

.table-responsives{
	@include bp(rev-small){
		overflow-x: auto;
		overflow-y: hidden;
		width: 100%;
		min-height: .01%;
		border: 0!important;

		-ms-overflow-style: -ms-autohiding-scrollbar;

		// overflow: auto;
		// max-width: 100%;
		// background: linear-gradient(to right, white 30%, rgba(255, 255, 255, 0)), linear-gradient(to right, rgba(255, 255, 255, 0), white 70%) 0 100%, radial-gradient(farthest-side at 0% 50%, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)), radial-gradient(farthest-side at 100% 50%, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)) 0 100%;
		// background-repeat: no-repeat;
		// background-color: white;
		// background-size: 40px 100%, 40px 100%, 14px 100%, 14px 100%;
		// background-position: 0 0, 100%, 0 0, 100%;
		// background-attachment: local, local, scroll, scroll;
	}
}
table{
	width: 100%;
	border-collapse: collapse;
	@include bp(rev-small){
		display: block;
	}
	thead{
		@include bp(rev-small){
			display: none;
		}
		tr{
			border-bottom: 2px solid #ddebe4;
		}
	}
	th{
		padding: 10px 10px 10px 10px;
		font-family: $font-primary;
		font-weight: 300;
		line-height: 1.4;
		color: $color-gray-normal;
		text-align: center;

		@include font-size(14px);
		@include bp(small){
			@include font-size(15px);
		}
		&:first-child{
			padding: 10px 0;
			text-align: left;
		}
		&:last-child{
			padding: 10px 0;
			text-align: right;
		}
	}
	tbody{
		@include bp(rev-small){
			display: block;
		}
		tr{
			border-top: 2px solid #ddebe4;
			@include bp(rev-small){
				display: flex;
				flex-wrap: wrap;
				align-items: center;
				margin-top: 1rem;
				padding-top: 1rem;
				&:first-child{
					margin-top: 0;
				}
				&:last-child{
					padding-bottom: 1rem;
				}
			}
		}
	}
	[data-header]{
		@include bp(rev-small){
			&:before{
				content: attr(data-header);
				display: block;
				margin-bottom: 0.2rem;
				font-family: $font-primary;
				font-weight: 300;
				line-height: 1.4;
				color: $color-gray-normal;
				@include font-size(11px);
			}
		}
	}
	td{
		padding: 15px 10px 15px 10px;
		font-family: $font-primary;
		line-height: 1.4;
		font-weight: 300;
		color: $color-black-normal;
		@include bp(small){
			text-align: center;
			padding: 22px 10px 22px 10px;
		}
		@include bp(rev-small){
			// width: 33.33%;
			// max-width: 33.33%;
			// flex: 0 0 33.33%;
			flex: 1;
		}
		&:first-child{
			padding: 15px 10px 5px;
			text-align: left;
			@include bp(rev-small){
				width: 100%;
				max-width: 100%;
				flex: 0 0 100%;
			}
			@include bp(small){
				padding: 22px 0;
			}
		}
		&:last-child{
			padding: 15px 10px;
			@include bp(small){
				padding: 22px 0;
				text-align: right;
			}
		}
	}

	.td-product{
		@include bp(small){
			max-width: 300px;
		}
		@include bp(medium){
			max-width: 280px;
		}
		@include bp(large){
			max-width: 360px;
		}
	}
	.td-quantity{
		@include bp(rev-small){
			// flex: 2;
			display: none;
		}
	}
	.table-text{
		color: $color-gray-normal;
	}
	.table-text-black{
		color: $color-black-normal;
	}
	.table-text-bold{
		font-weight: 500;
	}
	.table-text-green{
		color: $color-green-normal;
	}
	.table-text-big{
		@include font-size(18px);
		@include bp(small){
			@include font-size(20px);
		}
	}
	.table-image{
		width: 50px;
		max-width: 50px;
		flex: 0 0 50px;
		@include bp(small){
			width: 110px;
			max-width: 110px;
			flex: 0 0 110px;
		}
	}
	.table-count{
		min-width: 35px;
		flex: 0 0 35px;
		color: $color-black-normal;
		font-weight: 500;
		@include font-size(18px);
		@include bp(small){
			@include font-size(20px);
		}
	}
}


.table-group{
	margin-bottom: 50px;
	border: 1px solid #f2f2f2;
}

.table-header{
	background-color: #f7f7f7;
}

.table-header-row{
	position: relative;
	display: table;
	width: 100%;
	height: 50px;
}

.table-header-cell{
	display: table-cell;
	float: none;
	padding: 10px 20px;
	vertical-align: middle;

	@include bp(rev-small){
		padding: 10px 10px;
	}
	@include bp(rev-xssmall){
		padding: 10px 5px;
	}
}

.table-header-text{
	font-size: 10px;
	line-height: 1.2;
	text-transform: uppercase;
	color: #000000;
}

.table-body{

}

.table-body-cell{
	display: table-cell;
	float: none;
	padding: 10px 20px;
	vertical-align: middle;

	@include bp(rev-small){
		padding: 10px 10px;
	}
	@include bp(rev-xssmall){
		padding: 10px 5px;
	}
}

.table-body-text{
	display: inline-block;
	margin-bottom: 0;
	padding: 10px 0;
	font-size: 16px;
	line-height: 1.2;
	vertical-align: middle;
	text-transform: uppercase;
	color: #000000;
}

.table-body-row{
	position: relative;
	display: table;
	width: 100%;
	height: 100px;
	&:nth-child(even){
		background-color: #f7f7f7;
	}
}

